import { Group, Image, LoadingOverlay, MantineProvider, Title } from '@mantine/core';
import 'keen-slider/keen-slider.min.css';

// Note: if importing from local paths, use import './ratpack-server/...';
import { OperatorList } from 'ratpack-server/src/types';

// Note: if importing from local paths, use import './ratpack-ui/...';
import 'ratpack-ui/src/app/AppImports';
import { UIProvider } from 'ratpack-ui/src/app/UIProvider';
import { LoginLoadingPage } from 'ratpack-ui/src/pages/LoginLoadingPage';
import { LoginPageLayout } from 'ratpack-ui/src/pages/LoginPageLayout';
import { PublicPageLayout } from 'ratpack-ui/src/pages/PublicPageLayout';
import 'ratpack-ui/src/styles/Font.css';
import { theme } from 'ratpack-ui/src/styles/Theme';
import * as React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { RecoilRoot } from 'recoil';
import { logout } from './api';
import { activeColorBind } from './pages/components';
import CreateQuotePage from './pages/createQuote';
import CalendarPage from './pages/calendar';
import AvailabilityPage from './pages/availability.tsx';
import DevPage from './pages/dev';
import { LoginPage } from './pages/login';
import QuotePage from './pages/quote';
import {FinalisePage} from './pages/finalise';
import QuoteListPage from './pages/quoteList';
import { MustLogIn, MustNotLogIn, useGo } from './session';

const Logout = () => {
    // try logout on frontend
    document.cookie = "ratpack=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict"; // Should match auth = authmw({}) in index.ts
    // try logout on backend
    logout().then(() => window.location = '/' as unknown as Location)
    setTimeout(() => window.location = '/' as unknown as Location, 5000)
    return <LoadingOverlay visible={true} loader={(<div>{`Logging out...`}</div>)} />
}

const App = () => {
    const location = useLocation()
    return <UIProvider>
        <Routes>
            {/* <Route path="/" element={<DevPage />} /> */}
            <Route path="/" element={<MustLogIn ><QuoteListPage /></MustLogIn>} />
            <Route path="/dev" element={<MustLogIn ><Dev /></MustLogIn>} />
            <Route path="/admin/settings" element={<MustLogIn ><CalendarPage /></MustLogIn>} />
            <Route path="/admin/availability" element={<MustLogIn ><AvailabilityPage /></MustLogIn>} />
            <Route path="/admin/quotes" element={<MustLogIn ><QuoteListPage /></MustLogIn>} />
            <Route path="/admin/create-quote" element={<MustLogIn ><CreateQuotePage /></MustLogIn>} />
            <Route path="/quote/:key" element={<QuotePage />} />
            <Route path="/q/:key" element={<QuotePage />} />
            <Route path="/qt/:key" element={<QuotePage />} />
            <Route path="/qt.php/:key" element={<QuotePage />} />
            <Route path="/finalise/:zid" element={<FinalisePage />} />
            <Route path="/finalize/:zid" element={<FinalisePage />} />
            <Route path="/loading" element={<LoginPageLayout page={
                <LoginLoadingPage
                    loader={null}
                />
            } />} />
            <Route path="/login" element={<><MustNotLogIn /><LoginPage /></>} />
            <Route path="/logout" element={<><MustLogIn /><Logout /></>} />
            {/* <Route path="/thanks" element={<PublicPageLayout page={
                <p>

                // HomePage.tsx

                //// AdminPageLayout.tsx
                // AdminCreateQuotePage.tsx
                // AdminListQuotesPage.tsx

                //// LoginPageLayout.tsx
                // LoginForgotPasswordPage.tsx
                // LoginLoadingPage.tsx
                // LoginLoginPage.tsx
                // LoginSignUpPage.tsx

                //// PublicPageLayout.tsx
                // PublicQuotePage.tsx

                </p>
            } />}>
            </Route> */}
        </Routes>
    </UIProvider >
}


export default () => {
    return (
        <MantineProvider theme={theme ?? {
            colors: {
                'white': ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF'],
            },
        }}>
            <RecoilRoot>
                <React.Suspense fallback={<LoadingOverlay visible={true}></LoadingOverlay>}>
                    <BrowserRouter>
                        {/* <Provider> */}
                        <App />
                        {/* <RequireUserAtom/> */}
                        {/* <UserSessionEffect/> */}
                        {/* </Provider> */}
                    </BrowserRouter>
                </React.Suspense>
            </RecoilRoot>
        </MantineProvider>
    )
}

export const Dev = () => {
    const x = 1
    console.log({ x, OperatorList })
    const providers = Object.entries(OperatorList).map(([slug]) => slug)
    return providers.map(provider_key => {

        const [region, provider] = provider_key.split('.')
        return (<Group key={provider_key} direction="row">
            <Title order={4}>{region}.</Title>
            <Title order={2}>{provider}</Title>
            <Image style={{ right: 0 }} height={50} width={175} src={`/images/${region}.${provider}.png`} />
        </Group>)
    })
}
