import React from 'react'
import { createStyles } from '@mantine/core'
import { WithChildren, WithExtras } from '../../util/ComponentUtil'

const useStyles = createStyles((theme, _params) => {
  return {
    component: {},

    label: {
      color: theme.colors.brandDarkGray[4],
    },

    value: {},
  }
})

export type LabelledValueProps = WithChildren<
  WithExtras<
    {
      label: React.ReactNode
    },
    {
      component: 'div'
      label: 'span'
      value: 'span'
    }
  >
>

export function LabelledValue({ label, children, extras }: LabelledValueProps) {
  const { classes } = useStyles()

  return (
    <div className={classes.component} {...extras?.component}>
      <span className={classes.label} {...extras?.label}>
        {label}:{' '}
      </span>
      <span className={classes.value} {...extras?.value}>
        {children}
      </span>
    </div>
  )
}
