import React from 'react'
import {
  Anchor as MAnchor,
  AnchorProps as MAnchorProps,
  createStyles,
} from '@mantine/core'

const useStyles = createStyles((theme, _params) => {
  return {
    custom: {},

    basic: {
      color: theme.colors.gray[9],
      textDecoration: 'underline',
    },

    black: {
      color: theme.colors.brandBlack[6],
      textDecoration: 'none',
    },

    gray: {
      color: theme.colors.gray[6],
      textDecoration: 'none',
    },

    white: {
      color: theme.white,
      textDecoration: 'none',

      '&:hover, &:focus': {
        color: theme.white,
        textDecoration: 'underline',
      },
    },

    bold: {
      color: theme.black,
      textDecoration: 'underline',
      fontWeight: 700,
    },

    light: {
      color: theme.colors.brandDarkGray[4],
      textDecoration: 'none',
    },

    'light-small': {
      color: theme.colors.brandDarkGray[4],
      textDecoration: 'none',
      fontSize: '14px',
      lineHeight: '17px',
    },

    'table-header': {
      color: theme.colors.brandDarkGray[9],
      textDecoration: 'underline',
      fontSize: '14px',
      lineHeight: '13px',
      fontWeight: 400,
    },

    'black-no-underline': {
      color: theme.colors.brandBlack[6],
      textDecoration: 'none',

      '&:hover, &:focus': {
        textDecoration: 'none',
      },
    },
  }
})

export type AnchorProps = MAnchorProps<any> & {
  styleType?:
    | 'custom'
    | 'basic'
    | 'black'
    | 'gray'
    | 'white'
    | 'bold'
    | 'light'
    | 'light-small'
    | 'table-header'
    | 'black-no-underline'
}

export function Anchor({ styleType = 'basic', ...props }: AnchorProps) {
  const { classes } = useStyles()

  return (
    <MAnchor className={classes[styleType]} {...props}>
      {props.children}
    </MAnchor>
  )
}
