import React from 'react'
import { createStyles } from '@mantine/core'

import iconStar from '../../assets/images/icon-star.svg'
import { WithExtras } from '../../util/ComponentUtil'

const useStyles = createStyles((theme, _params) => {
  return {
    component: {
      display: 'inline-flex',
      alignItems: 'center',
    },

    text: {
      display: 'inline-flex',
      alignItems: 'center',
      marginRight: '10px',
      paddingTop: 2,
    },

    stars: {
      display: 'inline-flex',
      alignItems: 'center',
    },

    star: {
      display: 'inline-flex',
      alignItems: 'center',
      backgroundImage: `url(${iconStar})`,
      backgroundSize: 'cover',
      height: 18,
    },
  }
})

export type RatingProps = WithExtras<
  {
    rating: number
  },
  {
    component: 'div'
    text: 'span'
    stars: 'span'
    star: 'span'
  }
>

export function Rating({ rating, extras }: RatingProps) {
  const { classes } = useStyles()

  return (
    <div className={classes.component} {...extras?.component}>
      <span className={classes.text} {...extras?.text}>
        {rating.toFixed(1)}
      </span>
      <span className={classes.stars} {...extras?.stars}>
        {[...Array(Math.ceil(rating)).keys()].map((_, i) => (
          <span
            key={i}
            className={classes.star}
            style={{ width: Math.min(rating - i, 1) * 18 }}
            {...extras?.star}
          ></span>
        ))}
      </span>
    </div>
  )
}
