import React from 'react'
import { createStyles } from '@mantine/core'
import { WithChildren, WithExtras } from '../../util/ComponentUtil'

type HeroLabelledValueStyleProps = {
  noBorderBottom: boolean
}

const useStyles = createStyles(
  (theme, { noBorderBottom }: HeroLabelledValueStyleProps) => {
    return {
      component: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 380,
      },

      label: {
        alignSelf: 'flex-start',
        padding: '5px',
        color: theme.white,
        backgroundColor: theme.black,
        textTransform: 'uppercase',
        fontWeight: 600,
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '0.8px',
      },

      value: {
        alignSelf: 'stretch',
        padding: '10px 0 10px 0',
        marginBottom: noBorderBottom ? 0 : '10px',
        color: theme.white,
        fontSize: '22px',
        lineHeight: '26px',
        letterSpacing: '0.8px',
        borderBottom: noBorderBottom ? 'none' : `2px solid ${theme.white}`,
      },
    }
  }
)

export type HeroLabelledValueProps = WithChildren<
  WithExtras<
    {
      label: React.ReactNode
      noBorderBottom?: boolean
    },
    {
      component: 'div'
      label: 'span'
      value: 'div'
    }
  >
>

export function HeroLabelledValue({
  label,
  children,
  noBorderBottom = false,
  extras,
}: HeroLabelledValueProps) {
  const { classes } = useStyles({ noBorderBottom })

  return (
    <div className={classes.component} {...extras?.component}>
      <span className={classes.label} {...extras?.label}>
        {label}
      </span>
      <div className={classes.value} {...extras?.value}>
        {children}
      </div>
    </div>
  )
}
