import React from 'react'
import { createStyles } from '@mantine/core'

import { WithChildren } from '../../util/ComponentUtil'

const useStyles = createStyles((theme, _params) => {
  return {
    component: {
      width: '100%',
      maxWidth: 'calc(100vw - 40px)',
      margin: '0 auto',

      [theme.fn.largerThan(1154 + 40)]: {
        maxWidth: 1154,
      },
    },
  }
})

export type PageMaxWidthContainerProps = React.ComponentPropsWithRef<'div'> &
  WithChildren<{}>

export function PageMaxWidthContainer({
  children,
  ...props
}: PageMaxWidthContainerProps) {
  const { classes } = useStyles()

  return (
    <div className={classes.component} {...props}>
      {children}
    </div>
  )
}
