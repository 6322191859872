import React from 'react'
import {
  Text as MText,
  TextProps as MTextProps,
  createStyles,
} from '@mantine/core'

const useStyles = createStyles((theme, _params) => {
  return {
    custom: {},

    basic: {},

    light: {
      color: theme.colors.brandDarkGray[4],
    },

    link: {
      textDecoration: 'underline',
    },
  }
})

export type TextProps = MTextProps<any> & {
  styleType?: 'custom' | 'basic' | 'light' | 'link'
}

export function Text({ styleType = 'basic', children, ...props }: TextProps) {
  const { classes } = useStyles()

  return (
    <MText className={classes[styleType]} {...props}>
      {children}
    </MText>
  )
}
