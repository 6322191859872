import React from 'react'
import { createStyles } from '@mantine/core'
import { WithChildren, WithExtras } from '../../util/ComponentUtil'
import { PageMaxWidthContainer } from '../PageMaxWidthContainer/PageMaxWidthContainer'

const useStyles = createStyles((theme, _params) => {
  return {
    component: {
      width: '100%',
    },

    innerContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: '26px',
    },
  }
})

export type FooterProps = WithChildren<
  WithExtras<
    {},
    {
      component: 'div'
      pageMaxWidthContainer: typeof PageMaxWidthContainer
      innerContainer: 'div'
    }
  >
>

export function Footer({ children, extras }: FooterProps) {
  const { classes } = useStyles()

  return (
    <div className={classes.component} {...extras?.component}>
      <PageMaxWidthContainer {...extras?.pageMaxWidthContainer}>
        <div className={classes.innerContainer} {...extras?.innerContainer}>
          {children}
        </div>
      </PageMaxWidthContainer>
    </div>
  )
}
