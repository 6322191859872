import { ActionIcon, Group, Loader, LoadingOverlay, NumberInput, Paper, SegmentedControl, Space, Stack, Textarea, Tooltip } from '@mantine/core';
import { InputWrapper } from '@mantine/core/./cjs/Input/InputWrapper/InputWrapper.js'
import 'keen-slider/keen-slider.min.css';
import 'ratpack-ui/src/app/AppImports';
import bgImg from 'ratpack-ui/src/assets/images/background-road.jpg';
// import { Box } from 'ratpack-ui/src/components/Box/Box';
import { Button } from 'ratpack-ui/src/components/Button/Button';
import { Checkbox } from 'ratpack-ui/src/components/Checkbox/Checkbox';
import { DatePicker } from 'ratpack-ui/src/components/DatePicker/DatePicker';
// import { ExpandCollapse } from 'ratpack-ui/src/components/ExpandCollapse/ExpandCollapse';
import { Select } from 'ratpack-ui/src/components/Select/Select';
import { TextInput } from 'ratpack-ui/src/components/TextInput/TextInput';
import { AdminCreateQuotePage } from 'ratpack-ui/src/pages/AdminCreateQuotePage';
import { AdminPageLayout } from 'ratpack-ui/src/pages/AdminPageLayout';
import 'ratpack-ui/src/styles/Font.css';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { GET, POST } from '../api';
import { MustLogIn } from '../session';
import { AU_States, NZ_States, Spaced_States, ZDLocationCanonical, before, selected, stateful, toSelectItems, zdid } from '../util';
import { AllOperators } from 'ratpack-server/src/types';
import { adminDefaults } from './components';
import {
    All_States, DAY, OperatorsGeneric, optionList, typeList,
    // toSlug, // TODO
} from 'ratpack-server/src/types'
import {
    OperatorList, Operators,
} from 'ratpack-server/src/types'

import { Text } from 'ratpack-ui/src/components/Text/Text';
import { useNavigate } from 'react-router-dom';
import { selectorFamily, useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { quotesAtom } from './quoteList';
import { AsIfParsedFromTimezone } from '../time';
import { Anchor } from 'ratpack-ui/src/components/Anchor/Anchor';
import { quoteAtom, res_quote } from './quote';
import { useMultiStateBool } from './createQuote';
import { Box } from 'ratpack-ui/src/components/Box/Box';
// export const toSlug = (s: string) => s.replace(/\s/ig, '_').toLowerCase()

// export const useMultiStateBool = (list: string[] | Record<string, string>, initial = {}): [
//     Record<string, any>,
//     (n: string) => { value: string, onChange: Function },
//     Function,
//     Function,
// ] => {
//     const kv = Array.isArray(list) ? list.map(name => [toSlug(name), name]) : Object.entries(list)
//     const [values, setValues] = React.useState(initial)
//     const useOne = (name: string) => ({
//         value: values[name],
//         onChange: () => setValues((values) => ({ ...values, [name]: !values[name] })),
//     })
//     // console.log({ isa: Array.isArray(list), list, kv })

//     const render = (Component: (p: { key: string, value: string, onChange: Function, label: string }) => React.ReactNode) => {
//         return kv.map(([key, name]) => {
//             return Component({
//                 key,
//                 ...useOne(key),
//                 label: name
//             })
//         })
//     }

//     return [values, useOne, render, setValues]
// }
// const setter = (v: any) => v




type paymentType = 'FULL_PAYMENT' | 'DEPOSIT'
type commissionMode = `NET_UP` | `GROSS_DOWN`
type paymentProfile = { rate: number, mode: commissionMode, type: paymentType }
const payProfile = (rate: number, mode: commissionMode, type: paymentType): paymentProfile => ({ rate, mode, type })
const payProfiles: Record<keyof typeof OperatorList, paymentProfile> = {

    // TODO: insurance commission? See VWXYZ in F2 of CommCalc

    'au.apollo': payProfile(0.25, 'NET_UP', 'FULL_PAYMENT'), // note this is 22 in commcalc
    'au.britz': payProfile(0.22, 'NET_UP', 'FULL_PAYMENT'),
    'au.camperman': payProfile(0.25, 'GROSS_DOWN', 'FULL_PAYMENT'), // 0.30 option?
    'au.cheapa': payProfile(0.18, 'NET_UP', 'FULL_PAYMENT'),
    "au.hippie": payProfile(0.18, 'NET_UP', 'FULL_PAYMENT'),
    "au.jucy": payProfile(0.25, 'GROSS_DOWN', 'DEPOSIT'),
    "au.letsgo": payProfile(0.20, 'GROSS_DOWN', 'FULL_PAYMENT'),
    "au.maui": payProfile(0.20, 'GROSS_DOWN', 'FULL_PAYMENT'),
    "au.mighty": payProfile(0.20, 'GROSS_DOWN', 'FULL_PAYMENT'),
    "au.spaceships": payProfile(0.25, 'GROSS_DOWN', 'DEPOSIT'),
    "au.star-rv": payProfile(0.25, 'GROSS_DOWN', 'DEPOSIT'),
    "au.travellers-autobarn": payProfile(0.23, 'GROSS_DOWN', 'FULL_PAYMENT'),
    // TODO: generic options?
    "nz.apollo": payProfile(0.25, 'NET_UP', 'FULL_PAYMENT'),
    "nz.barefoot-campers": payProfile(0.25, 'GROSS_DOWN', 'DEPOSIT'),
    "nz.biglittle": payProfile(0.20, 'GROSS_DOWN', 'DEPOSIT'),
    "nz.britz": payProfile(0.22, 'NET_UP', 'FULL_PAYMENT'),
    "nz.cheapa": payProfile(0.18, 'NET_UP', 'FULL_PAYMENT'),
    "nz.hippie": payProfile(0.18, 'NET_UP', 'FULL_PAYMENT'),
    "nz.jucy": payProfile(0.20, 'GROSS_DOWN', 'DEPOSIT'),
    "nz.maui": payProfile(0.20, 'GROSS_DOWN', 'FULL_PAYMENT'),
    "nz.mighty": payProfile(0.20, 'GROSS_DOWN', 'FULL_PAYMENT'),
    "nz.spaceships": payProfile(0.25, 'GROSS_DOWN', 'DEPOSIT'),
    "nz.star-rv": payProfile(0.20, 'GROSS_DOWN', 'DEPOSIT'),
    "nz.travellers-autobarn": payProfile(0.23, 'GROSS_DOWN', 'FULL_PAYMENT'),
    // TODO: generic options?
}



export type res_finalise = {
    ticket: {
        name: any,
        reference: any,
        pickup: any,
        start: any,
        dropoff: any,
        end: any,
        pax: any,
        children: any,
        operator: string,
        vehicle: string,
    },
    // quote: res_quote,
}
export const finaliseAtom = selectorFamily<res_finalise, string>({
    key: 'finalise',
    get: zid => ({ get }) => GET(`/api/finalise/${zdid(zid)}`)
})

// Return or display the equivalent discount amount in the opposite format
function convertDiscountDisplay(discountType: 'amount' | 'percent', totalDiscount: number, totalDailyRate: number) {
    const alt = convertDiscount(discountType, totalDiscount, totalDailyRate)
    if (isNaN(alt)) return ''
    const n = (discountType == 'percent') ? `$${alt.toFixed(2)}` : `${alt.toFixed(1)}%`
    return ` (about ${n})`
}
function convertDiscount(discountType: 'amount' | 'percent', totalDiscount: number, totalDailyRate: number, invert: Boolean = false) {
    return (invert != (discountType == 'percent')) ? (totalDiscount / 100) : (100 * totalDiscount / totalDailyRate)
}

export const FinalisePage = () => {
    const { zid } = useParams()
    const [q2, setQ2] = React.useState<res_quote | null>(null)
    const { ticket = ({
        children: 0,
        dropoff: '',
        end: '',
        name: '',
        operator: '',
        pax: 0,
        pickup: '',
        reference: '',
        start: '',
        vehicle: '',
    } as res_finalise['ticket']), ...rest } = useRecoilValue(finaliseAtom(zid)) ?? {}
    const [error, setError] = React.useState('');

    React.useEffect(() => {
        const { error } = (rest ?? {} as any)
        setError(error)
    }, [(rest as any).error])

    // <i>FullName, ref, PULocation, DOLocation, PUDate, DODate, NAdults, NChildren, OperatorCompany, VehicleStyle</i>
    // TODO: NAdults, NChildren ?

    const setter = (v: any) => v
    const [name, useName, validName, setName] = stateful(ticket.name)
    const [ref, useRef, validRef, setRef] = stateful(zid)//ticket.reference)
    const [pax, usePax, validPax, setPax] = stateful(parseInt(ticket.pax).toString(), { setter })
    const [childrenPax, useChildrenPax, validChildrenPax, setChildrenPax] = stateful(parseInt(ticket.children).toString(), { setter })

    const [pickup_date, usePickupDate, validPickupDate, setPickupDate] = stateful<Date>(new Date(ticket.start), { setter }) // , next: dropOffRef.click()
    const [dropoff_date, useDropoffDate, validDropoffDate, setDropoffDate] = stateful<Date>(new Date(ticket.end), { setter })
    // const [dates, useDates, validDates, setDates] = stateful([new Date(ticket.start), new Date(ticket.end)] as any, { setter })
    // const [pickup_date, dropoff_date] = [null,null] // dates

    const [pickup, usePickup, validPickup, setPickup] = stateful(ZDLocationCanonical(ticket.pickup), { setter })
    const [dropoff, useDropoff, validDropoff, setDropoff] = stateful(ZDLocationCanonical(ticket.dropoff), { setter })

    // IDEA: provide a "manual" operator which does no API lookup and has no default commission rates, and one dummy product.
    const [operator, useOperator, validOperator, setOperator] = stateful(ticket.operator, { setter, validate: v => Object.keys(AllOperators).includes(v) })
    const [types, useType, renderTypes, setTypes] = useMultiStateBool(typeList.map(item => item.nice))

    const days = (pickup_date && dropoff_date) ? 1 + Math.round((dropoff_date.getTime() - pickup_date.getTime()) / DAY) : null
    const DropOffDateLabel = `Drop off date ${days !== null ? `( ${days} days )` : ''}`

    const [discountType, useDiscountType, validDiscountType, setDiscountType] = stateful<"percent" | "amount">('amount', {
        setter, effect: discountType => {
            // setDiscount(convertDiscount(discountType, totalDiscount, totalDailyRate, true)); // Requested disabled
        }
    })
    const [discountAmount, useDiscount, validDiscount, setDiscount] = stateful<number>(0, { setter })
    // React.useEffect(()=>{ setDiscount(convertDiscount(discountType, totalDiscount, totalDailyRate, true)); }, [discountType])
    React.useEffect(() => { if (isNaN(discountAmount)) setDiscount(0); }, [discountAmount])

    const [rate, useRate, validRate, setRate] = stateful<number>(0, { setter })
    const [insuranceRate, useInsuranceRate, validInsuranceRate, setInsuranceRate] = stateful<number>(0, { setter })
    const [insuranceBond, useInsuranceBond, validInsuranceBond, setInsuranceBond] = stateful<number>(0, { setter })
    const [commissionableFee, useCFee, validCFee, setCFee] = stateful<number>(0, { setter })
    const [nonCommissionableFee, useNCFee, validNCFee, setNCFee] = stateful<number>(0, { setter })

    const nName = (name: string) => {
        const [first, ...rest] = name.split(' ')
        return [first, rest.join(' ')]
    }

    const getVehicle = (vehicle: string) => q2?.offers?.find(({ id }) => id == vehicle)
    const getPolicy = (vehicle: string, policy: string | null) => {
        const policies = getVehicle(vehicle)?.policies ?? []
        if (policy === null) return policies.sort((b, a) => parseInt(a.daily) - parseInt(b.daily))?.[0]
        return policies.find(({ id }) => id == policy)
    }
    const [vehicle, useVehicle, validVehicle, setVehicle] = stateful(ticket.vehicle, {
        setter, effect: vehicle => { // TODO: make effect: more like useEffect? effect is nice because it doesn't need to re-render N times if all the state is in scope
            // NOTE: use useEffect, otherwise the setter will not be able to see the updated vehicle
            // setRate((getVehicle(vehicle)?.daily ?? 0) /100)
            // const Policy = getPolicy(vehicle,null)
            // setPolicy(getPolicy(vehicle,null)?.id?.toString()??'none')
        }
    })//, validate: v => q2?.offers?.map(({id})=>id).includes(v) }) // TODO: support validator updating on same re-render as setting the value? seems to cause issues
    const Vehicle = getVehicle(vehicle)
    React.useEffect(() => {
        const rate = (getVehicle(vehicle)?.daily ?? 0)
        setRate(rate / 100)
        const Policy = getPolicy(vehicle, null)
        setPolicy(Policy?.id?.toString() ?? 'none')
    }, [vehicle]); // Dependency array includes product
    const [policy, usePolicy, validPolicy, setPolicy] = stateful('', {
        setter, effect: policy => {
            const Policy = getPolicy(vehicle, policy)
            console.log(`Set policy`, { vehicle, policy, Policy })
            setInsuranceRate((Policy?.fees ?? 0) / 100)
            setInsuranceBond((Policy?.excessamount ?? 0) / 100)
        }
    })
    const Policy = getPolicy(vehicle, policy)
    const sortPolicies = policies => policies.sort((a, b) => a.fee - b.fee)
    const ifNaN = (v = 1, d = null) => isNaN(v) ? d : v
    const ifN = (v: number, isN = (v: number) => v.toString(), isNN = (v: typeof NaN) => '?') => isNaN(v) ? isNN(v) : isN(v)

    const [loading, setLoading] = React.useState<boolean>(false)
    const Dropoff_States = pickup.length ? toSelectItems(pickup.startsWith('au.') ? AU_States : NZ_States) : Spaced_States

    const [commRate, useCommRate, validCommRate, setCommRate] = stateful<number>(25, { setter })
    React.useEffect(() => {
        setCommRate(payProfiles[`${country}.${operator}`]?.rate * 100)
    }, [operator])

    const country = 'au'
    console.debug({
        note: 'NEED TO COMPUTE COUNTRY FOR PAYMENT PROFILES',
        country,
        operator,
        pickup,
        dropoff,
    })
    const {
        internal,
        external,
        deposit, // RPT commission, the required deposit amount
        total, // RPT offer total rate
        totalDiscount, // cents discounted
        totalDirectRate, // total with no discounts
        totalDailyRate, // daily direct rate * days
        commission,
    } = generateMemos({
        dropoff: dropoff,
        originalRate: Vehicle?.daily ?? (rate*100),
        pickup: pickup,
        start: pickup_date,
        end: dropoff_date,
        pax: pax,
        paxChildren: childrenPax,
        zdid: zid,
        camper: Vehicle?.name, // TODO: map to format?
        company: operator, //AllOperators[operator], // TODO: map to format?
        bond: insuranceBond * 100,
        insurance: insuranceRate * 100,
        commissionableExtras: commissionableFee * 100,
        nonCommissionableExtras: nonCommissionableFee * 100,
        dailyRate: rate * 100,
        days,
        // discountAmount: totalDiscount,
        discountType,
        discountAmount,
        link: Vehicle?.specs,
        pickupDate: pickup_date,
        commRateForCompany: commRate / 100,
        country,
    })
    const discountedTotal = total - totalDiscount

    const setEventTargetValue = (e: InputEvent) => (e.target as HTMLInputElement).value
    const [internalMemo, useInternalMemo, validInternalMemo, setInternalMemo] = stateful<string>(internal, { setter: setEventTargetValue })
    const [externalMemo, useExternalMemo, validExternalMemo, setExternalMemo] = stateful<string>(external, { setter: setEventTargetValue })
    React.useEffect(() => {
        setInternalMemo(internal)
        setExternalMemo(external)
    }, [internal, external]);
    const internalMemoChanged = internalMemo != internal
    const externalMemoChanged = externalMemo != external

    console.log({ discountType, total, totalDiscount, discountedTotal, pickup_date, dropoff_date, pickup, dropoff, operator, types, Vehicle, Policy, commission, deposit })

    return (<AdminPageLayout {...adminDefaults({ page: 'finalise' })} page={<>
        <MustLogIn />

        {!q2 ? (<AdminCreateQuotePage
            backgroundImage={bgImg}
            topSpace={<Space h={50} />}
            topBox={AdminCreateQuotePage.TopBox({
                firstNameInput: <TextInput {...useName} placeholder="First Name" label="Customer Name" />,
                lastNameInput: [], // <TextInput {...useSurname} placeholder="Last Name" />,
                bookingRefInput: <TextInput {...useRef} disabled placeholder="#12345" label="Ticket number" />, // RPT Booking Ref"
                tripNameInput: [], // <TextInput {...useTitle} placeholder="Description" label="Trip Name" />,
            })}
            middleSpace={<Space h={20} />}
            bottomBox={AdminCreateQuotePage.BottomBox({
                pickUpLocationSelect: (<Group dir='column'>
                    <Select
                        label="Pick up location"
                        onMouseDown={e => setPickup('')}
                        placeholder="Select"
                        {...usePickup}
                        onChange={v => {
                            setPickup(v)
                            setDropoff(v)
                        }}
                        data={Spaced_States}
                    ></Select>
                    <Select
                        label="Drop off location"
                        onMouseDown={e => setDropoff('')}
                        placeholder="Select"
                        {...useDropoff}
                        data={Dropoff_States ?? []}
                    ></Select>
                </Group>
                ),
                dropOffLocationSelect: [],
                // pickUpDatePicker: <DatePicker
                //     {...useDates}
                //     type="range"
                //     placeholder="Select"
                //     label="Dates"
                //     excludeDate={d => before()(AsIfParsedFromTimezone(d))}
                //     onChange={d => {
                //         setDates(d)
                //         // if (!dropoff_date || (dropoff_date.getTime() < d.getTime())) setDropoffDate(d)
                //     }}
                // />,
                pickUpDatePicker: <Group dir='column'>
                    <DatePicker
                        {...usePickupDate}
                        placeholder="Select"
                        label="Pick up date"
                        excludeDate={d => before()(AsIfParsedFromTimezone(d))}
                        onChange={d => {
                            setPickupDate(d);
                            (document.querySelector('#dropoff_date') as HTMLElement)?.click()
                            if (!dropoff_date || ((dropoff_date as any).getTime() < d.getTime())) setDropoffDate(d)
                        }}
                    />
                    <DatePicker
                        {...useDropoffDate}
                        placeholder="Select"
                        label={DropOffDateLabel}
                        excludeDate={d => before(pickup_date as any ?? new Date())(AsIfParsedFromTimezone(d))}
                    />
                </Group>,
                dropOffDatePicker: [],
                numberOfPeopleSelect: (<Group dir='column'>
                    <Select
                        searchable={false}
                        label="Number of people"
                        placeholder="Select"
                        {...usePax}
                        data={[1, 2, 3, 4, 5, 6, 7, 8].map(n => {
                            return { value: `${n}`, label: n == 1 ? `1 Person` : `${n} People` }
                        })}
                    ></Select>
                    <Select
                        searchable={false}
                        label="Number of children"
                        placeholder="Select"
                        {...useChildrenPax}
                        data={[0, 1, 2, 3, 4, 5, 6, 7, 8].map(n => {
                            return { value: `${n}`, label: n == 1 ? `1 Child` : `${n} Children` }
                        })}
                    ></Select>
                </Group>),
                filtersExpandCollapse: <Text>Filters</Text>, // <ExpandCollapse label="Filters" />,
                filtersArea: AdminCreateQuotePage.FiltersArea({
                    discountSelect: [],
                    offerEndsSelect: [],
                    operatorsCheckboxGroup: (
                        <Select
                            searchable={true}
                            onMouseDown={e => setOperator('')}
                            {...useOperator}
                            label="Operator"
                            placeholder="Operator"
                            data={Object.entries(AllOperators).map(([value, label]) => ({ value, label }))}
                        ></Select>
                    ),
                    specOptionsCheckboxGroup: [],
                    camperTypeCheckboxGroup: (false &&
                        <InputWrapper label={
                            <Group dir="row">
                                Camper Type
                                <Tooltip label="Select none to search everything" style={{ display: 'inline' }}>
                                    <p style={{ fontSize: '10px', paddingLeft: '5px' }}>?</p>
                                </Tooltip>
                            </Group>
                        }>
                            {renderTypes(({ value, ...rest }) => <Checkbox checked={value ?? false} {...rest} />)}
                        </InputWrapper>
                    ),
                }),
            })}
            searchArea={
                AdminCreateQuotePage.SearchArea({
                    searchButton:
                        <Group style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Group dir="row" style={{ width: '100%', justifyContent: 'space-between' }}>
                                <Text> </Text>
                                <Group style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <Button
                                        disabled={loading}
                                        onClick={() => {
                                            const quoteRequest = ({
                                                // Inherit
                                                title: `Finalisation for ${ticket.reference}`,
                                                offer_duration: '24',
                                                pax: pax,
                                                // Inputs
                                                'prepared_for': name,
                                                'discount_percent': '0',
                                                'name': nName(name)[0],
                                                'surname': nName(name)[1],
                                                'reference': ref,
                                                pickup,
                                                dropoff,
                                                pickup_date,
                                                dropoff_date,
                                                // Filters
                                                'operators': [operator],
                                                'options': [],
                                                types: selected(types),
                                                // Settings
                                                'mode': 'sync', // Allow empty results, return asynchronously
                                                insurance: true,
                                            })
                                            setLoading(true)
                                            setError(null)
                                            console.log({ quoteRequest })
                                            setVehicle(null)
                                            POST(`/api/finalise/${zdid(zid)}`, quoteRequest)
                                                .then(res => {
                                                    console.log({ res })
                                                    if (res.error || res.status == "Bad Request") return setError(res.error ?? res.message)
                                                    setQ2(res)
                                                    try {
                                                        const vehs = res?.offers ?? []
                                                        const veh = vehs.find(veh => veh?.id == vehicle) ?? vehs[0] ?? null
                                                        console.log({ veh })
                                                        setVehicle(veh?.id?.toString())
                                                    } catch (e) {
                                                        console.error(e)
                                                        setError(`Data error: ${e}`)
                                                    }
                                                })
                                                .catch(e => setError(e))
                                                .finally(() => setLoading(false))
                                            // NOTE: optionally use quoteAtom here
                                        }}
                                    >{loading ? <Loader /> : 'See Rates'}</Button>
                                    {!!error &&
                                        <Group style={{
                                            backgroundColor: 'black',
                                            padding: '10px',
                                            margin: '5px',
                                        }}>
                                            <Text backgroundColor="white" color="red">{error}</Text>
                                        </Group>
                                    }
                                </Group>
                                {/* <Text><Anchor onClick={resetFields} size="sm">Reset All Fields</Anchor></Text> (REFRESH THE PAGE)*/}
                            </Group>
                        </Group>,
                })
            }
        />) : /* STEP 2 */ (<AdminCreateQuotePage
            backgroundImage={bgImg}
            topSpace={<Space h={50} />}
            topBox={

                <Box styleType="translucent">
                    <Group>

                        <Stack style={{ flexGrow: 2 }}>
                            <Group>
                                <Select
                                    label={Vehicle ? `Vehicle ($${((Vehicle.daily ?? 0) / 100)?.toFixed(2)}/d)` : "Vehicle"}
                                    styles={{
                                        root: {
                                            maxWidth: '400px',
                                            minWidth: '200px',
                                        }
                                    }}
                                    onMouseDown={e => {
                                        console.log('clearVehicle')
                                        setVehicle('')
                                    }}
                                    placeholder="Select"
                                    {...useVehicle}
                                    onChange={v => {
                                        console.log({ setVehicle: v })
                                        setVehicle(v.toString())
                                    }}
                                    data={(q2.offers ?? []).map(({ name, id }) => ({ label: name, value: id.toString() }))}
                                ></Select>
                                <RPNumberInput
                                    style={{ width: '80px' }}
                                    placeholder="0"
                                    label="Rate"
                                    defaultValue={0}
                                    precision={2}
                                    {...useRate}
                                    min={0}
                                    hideControls
                                />
                            </Group>
                            <Group>
                                <Select
                                    label={Policy ? `Insurance ($${((Policy.fees ?? 0) / 100)?.toFixed(2)}/d)` : "Insurance"}
                                    styles={{
                                        root: {
                                            maxWidth: '400px',
                                            minWidth: '200px',
                                        }
                                    }}
                                    onMouseDown={e => {
                                        console.log('clearPolicy')
                                        setPolicy('')
                                    }}
                                    itemComponent={SelectItem}
                                    placeholder="Select"
                                    {...usePolicy}
                                    onChange={v => {
                                        console.log({ setPolicy: v })
                                        setPolicy(v.toString())
                                    }}
                                    data={[
                                        { label: 'None', value: 'none', description: 'No insurance' },
                                        ...sortPolicies(Vehicle?.policies ?? []).map(({ id, name, fees, maximumprice, excessamount, totalinsuranceamount }) => ({
                                            label: name,
                                            description: [
                                                `$${(fees / 100).toFixed(0)}/d`,
                                                // maximumprice?`${maximumprice}max`:null,
                                                `$${(excessamount / 100).toFixed(0)}bond`,
                                                `=$${(totalinsuranceamount / 100).toFixed(0)}`,
                                                (maximumprice <= totalinsuranceamount) ? '(max)' : null,
                                            ].filter(Boolean).join(', '),
                                            value: id.toString(),
                                        })),
                                    ]}
                                ></Select>
                                <Group>

                                    <RPNumberInput
                                        style={{ width: '80px' }}
                                        placeholder="0"
                                        label={`Rate`}
                                        defaultValue={0}
                                        precision={2}
                                        {...useInsuranceRate}
                                        min={0}
                                        hideControls
                                    />
                                    <RPNumberInput
                                        style={{ width: '80px' }}
                                        placeholder="0"
                                        label={`Bond`}
                                        defaultValue={0}
                                        precision={2}
                                        {...useInsuranceBond}
                                        min={0}
                                        hideControls
                                    />
                                </Group>

                            </Group>
                        </Stack>
                        <Stack style={{ flexGrow: 1, maxWidth: '200px' }}>
                            <RPNumberInput
                                placeholder="0"
                                label={`Commission Rate`}
                                defaultValue={0}
                                precision={2}
                                {...useCommRate}
                                min={0}
                                rightSection={"%"}
                                hideControls
                            />
                            <RPNumberInput
                                placeholder="0"
                                label={`Commissionable Fees`}
                                defaultValue={0}
                                precision={2}
                                {...useCFee}
                                min={0}
                                hideControls
                            />
                            <RPNumberInput
                                placeholder="0"
                                label={`Non-Commissionable Fees`}
                                defaultValue={0}
                                precision={2}
                                {...useNCFee}
                                min={0}
                                hideControls
                            />
                        </Stack>
                    </Group>
                </Box>
            }
            middleSpace={<Space h={20} />}
            bottomBox={AdminCreateQuotePage.BottomBox({
                pickUpLocationSelect: <Stack>
                    <SegmentedControl
                        data={[
                            { label: 'Amount', value: 'amount' },
                            { label: 'Percent', value: 'percent' },
                        ]}
                        {...useDiscountType}
                        label="Discount Type"
                    />
                    {/* <Select
                        label="Discount Type"
                        searchable={false}
                        onMouseDown={e => setDiscountType('')}
                        placeholder="Select"
                        {...useDiscountType}
                        data={[{label:'Percent', value:'percent'}, {label:"Amount", value:"amount"}]}
                    ></Select> */}
                    <RPNumberInput
                        style={{ width: '250px' }}
                        placeholder="0"
                        label={`Discount Amount` + ((discountType == 'percent') ? convertDiscountDisplay(discountType, totalDiscount, totalDailyRate) : '')}
                        defaultValue={0}
                        precision={2}
                        {...useDiscount}
                        icon={(discountType == 'amount') ? "$" : ''}
                        rightSection={(discountType == 'percent') ? "%" : ''}
                        min={0}
                        max={discountType == 'percent' ? 100 : (totalDailyRate / 100)}
                        hideControls
                    />
                </Stack>,
                dropOffLocationSelect: [],
                // pickUpDatePicker:  <Text size='sm'>Total direct rate: ${(total/100)?.toFixed(2)}</Text>,
                // dropOffDatePicker: <Text size='sm'>Total RPT rate: ${(discountedTotal/100)?.toFixed(2)}</Text>,
                // numberOfPeopleSelect: <Text size='sm'>Total commission: {commission}</Text>,
                pickUpDatePicker: [],
                dropOffDatePicker: [],
                numberOfPeopleSelect: <Group dir="col">
                    <RedNumber label="Total direct rate: " n={totalDirectRate / 100} />
                    <RedNumber label="Total RPT rate: " n={total / 100} />
                    <RedNumber label="Total commission: " n={commission / 100} />
                    {/* <RedNumber label="Total commission: " n={deposit / 100} /> */}
                </Group>,
                filtersExpandCollapse: [],
                filtersArea: <Group dir="col">
                    <NotePaper
                        label={externalMemoChanged ? "External Message (modified)" : "External Message"}
                        useValue={useExternalMemo}
                    />
                    <NotePaper
                        label={internalMemoChanged ? "Internal Note (modified)" : "Internal Note"}
                        useValue={useInternalMemo}
                    />
                </Group>,
            })}

            searchArea={
                AdminCreateQuotePage.SearchArea({
                    searchButton:
                        <Group style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Group dir="row" style={{ width: '100%', justifyContent: 'space-between' }}>
                                <Text> </Text>
                                <Group style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <Button
                                        disabled={loading}
                                        onClick={() => {
                                            // alert('TODO: POST updated ticket details to ZD via /api/finalise/:zid/:q2id, then redirect to ZD page')

                                            setLoading(true)
                                            setError(null)
                                            POST(`/api/finalise/${zdid(zid)}/send`, {
                                                internal: internalMemo,
                                                external: externalMemo,
                                            })
                                                .then(res => {
                                                    console.dir(res)
                                                    if (res.error || res.status == "Bad Request") return setError(res.error ?? res.message)
                                                    window.location.href = `https://theratpacktravelteam.zendesk.com/agent/tickets/${zdid(zid)}`
                                                })
                                                .catch(e => setError(e))
                                                .finally(() => setLoading(false))
                                        }}
                                    >{loading ? <Loader /> : 'Finalise & Save Quote'}</Button>
                                    <Button
                                        disabled={loading}
                                        onClick={() => setQ2(null)}
                                    >{loading ? <Loader /> : 'Search Again'}</Button>
                                    {!!error &&
                                        <Group style={{
                                            backgroundColor: 'black',
                                            padding: '10px',
                                            margin: '5px',
                                        }}>
                                            <Text backgroundColor="white" color="red">{error}</Text>
                                        </Group>
                                    }
                                </Group>
                                {/* <Text><Anchor onClick={resetFields} size="sm">Reset All Fields</Anchor></Text> (REFRESH THE PAGE)*/}
                            </Group>
                        </Group>,
                })
            }
        />)}



        {/* {q2 && (<div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            margin: '10px',
        }}>
            {loading && (<b>LOADING OVERLAY</b>)}
            <hr />
            <h1>FINALIZATION:STEP2:{zid}:{q2.id}</h1>
            list of options and insurance settings to select for finalise offer
            <i>ProductMenu, Show Price, InsuranceMenu, ExtraFees(?), DiscountType,Amount,effectiveAmount, totalDirectRate, totalRPTRate, Commission, breakdownMemos(int,ext)</i>
            checkboxes to send internal/external memos, update quote link, ticket custom fields, or do nothing
            <hr />

        </div>)} */}
    </>}
    />)
}

export const RPNumberInput = (options) => { // TODO use in calendar.tsx // TODO use theme inherit styles properly
    return <NumberInput
        styles={{
            input: {
                color: '#ced4da',
                backgroundColor: '#242424',
                fontSize: '14px',
                '&:hover': {
                    backgroundColor: '#3a3a3a', // Change color on hover
                },
            },
            rightSection: {
                color: '#ced4da',
            },
            root: {
                width: '100%',
            },
        }}
        {...options}
    />
}

// Custom component for select dropdowns
interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    value: string,
    label: string,
    description: string,
}
const SelectItem = React.forwardRef<HTMLDivElement, ItemProps>(
    ({ description, label, ...rest }: ItemProps, ref) => (
        <div ref={ref} {...rest}>
            <Group noWrap>
                <div>
                    <Text size="sm">{label}</Text>
                    <Text size="xs" opacity={0.65}>
                        {description}
                    </Text>
                </div>
            </Group>
        </div>
    )
);


// const v2 = ( // working on insurance calculations from commission calculator
//     dailyRate,
//     dailyInsurance,
//     days,
//     extrasNC,
//     extrasC,
//     discount,
//     commissionRate,
//     company,
// ) => {

//     const S2 = 1 // lookup on J which contains only 1
//     const U2 = 1 // lookup on K which contains only 1

//     const dailyIntermediate = (dailyRate * days) * S2
//     const insuranceIntermediate = (dailyInsurance * days) * U2
//     const totalDirectRate = (extrasNC + extrasC) + dailyIntermediate + insuranceIntermediate
//     const totalDiscountedRate = totalDirectRate - discount // what the customer pays
//     const netFactor = {
//         'au.camperman': 0.75,
//         'au.jucy': 0.75,
//         'au.spaceships': 0.75,
//         'au.star': 0.75,
//         'au.apollo': 1, // NET UP
//         'au.tab': 0.78,
//         'au.letsgo': 0.8,
//         'au.mighty': 0.8,
//         'au.cheapa': 1, // NET UP
//         'au.hippie': 1, // NET UP
//         'au.britz': 0.85,
//         'nz.barefoot': 0.75,
//         'nz.spaceships': 0.75,
//         'nz.apollo': 1, // NET UP
//         'nz.tab': 0.78,
//         'nz.jucy': 0.8,
//         'nz.star': 0.8,
//         'nz.mighty': 0.8,
//         'nz.cheapa': 1, // NET UP
//         'nz.britz': 0.85,
//         'nz.maui': 0.9,
//         'nz.biglittle': 0.8,
//     }[company]

//     const is_net_up = false
//     const NET_RATE = is_net_up ? 1 : netFactor * (dailyIntermediate + insuranceIntermediate) // apollo/cheapa/hippi are net up, and have net rate daily multiplier 1, rest are 0.75 or some
//     const extrasCommission = extrasC * commissionRate
//     const commission = dailyIntermediate + insuranceIntermediate - discount - NET_RATE + extrasCommission

//     return {
//         internal: '',
//         external: '',
//         totalDailyRate: 0,
//         totalDirectRate: 0,
//         totalDiscount: 0,
//         total: 0,
//         deposit: 0,
//     }
// }

const generateMemos = (options: {
    zdid: string, // the ZD ID
    pickup: string,
    start: Date,
    dropoff: string,
    end: Date,
    pax: string, // :number,
    paxChildren: string, // :number,
    // country,
    company: keyof typeof AllOperators,
    camper: string,
    // discountAmount:number, // cents
    discountAmount: number, // dollars or percent
    discountType: 'percent' | 'amount',
    pickupDate: Date,
    link: string,//="http://...",
    days: number,
    dailyRate: number,//=100,
    insurance: number,//=10,
    bond: number,//=1000,
    nonCommissionableExtras: number,//= 0,
    commissionableExtras: number,//= 0,
    commRateForCompany: number,
    country: string,
    originalRate: number,
}) => {
    try {
        const {
            zdid,
            pickup,
            start,
            dropoff,
            end,
            pax,
            paxChildren,
            company,
            camper,
            discountAmount = 0,
            discountType,
            pickupDate,
            link,
            days,

            dailyRate, // cents
            originalRate = dailyRate,

            insurance = 0, // cents
            bond = 0, // cents
            nonCommissionableExtras, // cents
            commissionableExtras, // cents
            commRateForCompany,
            country,
        } = options

        const paymentProfile: paymentProfile = payProfiles[`${country}.${company}`]

        const dailyRateCommission = commRateForCompany
        const extrasRateCommission = commRateForCompany
        const insuranceRateCommission = 0

        const paymentDate = pickupDate.getTime() - (65 * DAY) // CC2C9


        console.debug({
            paymentProfile,
            paymentDate,
            country,company,
        })
        const common = {
            days,
            bond,
            link,
            pax,
            paxChildren,
            company,
            camper,
            paymentProfile,
            dropoff,
            pickup,
            paymentDate,
            start,
            end,
            zdid,
        }

        if (paymentProfile.mode == "GROSS_DOWN") {
            // const commission = (days * originalRate * dailyRateCommission) + (commissionableExtras * extrasRateCommission) + (insurance * days * insuranceRateCommission)
            const totalDailyRate = days * dailyRate
            const totalDailyInsurance = insurance * days
            const totalDiscount = (discountType == 'percent') ? totalDailyRate * (discountAmount ?? 0) / 100 : (discountAmount ?? 0) * 100
            const totalExtras = commissionableExtras + nonCommissionableExtras
            const totalDirectRate = totalDailyRate + (insurance * days) + totalExtras
            const netRate = (dailyRate+insurance) * (1 - dailyRateCommission)
            const commission = totalDailyRate + totalDailyInsurance - totalDiscount - (netRate*days) + (commissionableExtras * extrasRateCommission) + (insurance * days * insuranceRateCommission)
            const total = totalDirectRate - totalDiscount
            const deposit = total + totalDailyInsurance - (netRate*days) + commissionableExtras * extrasRateCommission // AKA commission?
            const totalRPTRate = totalDirectRate - totalDiscount
            console.debug({
                paymentProfile,
                totalDailyRate,
                totalDiscount,
                totalExtras,
                totalDirectRate,
                netRate,
                commission,
                total,
                deposit,
                totalRPTRate,
                totalDailyInsurance,
            })
            return {
                ...memos({
                    ...common,
                    netRate,
                    RPTComm: commission, //totalDailyRate - totalDiscount - netRate + (insurance * days * insuranceRateCommission) + (commissionableExtras * extrasRateCommission),
                    totalDailyInsurance,
                    totalDailyRate,
                    totalDirectRate,
                    totalRPTRate,
                    totalExtras,
                }),
                totalDailyRate,
                totalDiscount,
                totalDirectRate,
                total,
                deposit,
                commission,
            }
        }
        if (paymentProfile.mode == "NET_UP") {
            // const commission = (days * originalRate * dailyRateCommission) + (commissionableExtras * extrasRateCommission) + (insurance * days * insuranceRateCommission)
            const totalDailyRate = days * dailyRate
            const totalDailyInsurance = insurance * days
            const totalDiscount = (discountType == 'percent') ? totalDailyRate * (discountAmount ?? 0) / 100 : (discountAmount ?? 0) * 100
            const totalExtras = commissionableExtras + nonCommissionableExtras
            const totalDirectRate = totalDailyRate + (insurance * days) + totalExtras
            const netRate = originalRate // *(1-dailyRateCommission)
            // const commission = totalDailyRate - totalDiscount - netRate + (commissionableExtras * extrasRateCommission) + (insurance * days * insuranceRateCommission)
            const commission = totalDailyRate + totalDailyInsurance - totalDiscount - (netRate*days) + (commissionableExtras * extrasRateCommission) + (insurance * days * insuranceRateCommission)
            const total = totalDirectRate - totalDiscount
            const deposit = total + totalDailyInsurance - (netRate*days) + commissionableExtras * extrasRateCommission // AKA commission?
            const totalRPTRate = totalDirectRate - totalDiscount
            console.debug({
                paymentProfile,
                totalDailyRate,
                totalDiscount,
                totalExtras,
                totalDirectRate,
                netRate,
                commission,
                total,
                deposit,
                totalRPTRate,
                totalDailyInsurance,
            })
            return {
                ...memos({
                    ...common,
                    netRate,
                    RPTComm: commission, //totalDailyRate - totalDiscount - netRate + (insurance * days * insuranceRateCommission) + (commissionableExtras * extrasRateCommission),
                    totalDailyInsurance,
                    totalDailyRate,
                    totalDirectRate,
                    totalRPTRate, // totalExtras + totalDailyRate - totalDiscount + totalDailyInsurance
                    totalExtras,
                }),
                totalDailyRate,
                totalDiscount,
                totalDirectRate,
                total,
                deposit,
                commission,
            }
        }
        return {
            internal: `Error: Unknown payment profile mode for operator ${country}.${company}`,
            external: '',
            totalDailyRate: 0,
            totalDiscount: 0,
            totalDirectRate: 0,
            total: 0,
            deposit: 0,
            commission: 0,
        }
    } catch (get_memo_error) {
        console.warn({ get_memo_error })
        return {
            internal: `Error: ${get_memo_error}`,
            external: '',
            totalDailyRate: 0,
            totalDirectRate: 0,
            totalDiscount: 0,
            total: 0,
            deposit: 0,
            commission: 0,
        }
    }
    // // TODO discounting logic

    // // const country = "Australia" // CC2B3 // AGENT INPUT
    // // const company = "Spaceships" // CC2C3 // AGENT INPUT // FORMAT: Spaceships Au
    // // const camper = "Beta 2S Au" // CC2D3 // AGENT INPUT // FORMAT: Alpha Au
    // // const days = 10 // CC2F3 // AGENT INPUT
    // // const dailyRate = 100 // CC2H3 // "gross" // AGENT INPUT
    // // const insurance = 30 // CC2K3 // AGENT INPUT
    // // const bond = 0 // CC2L3 // AGENT INPUT
    // // const nonCommissionableExtras = 0 // CC2M6 // AGENT INPUT
    // // const commissionableExtras = 0 // CC2M9 // AGENT INPUT
    // // const discountType = '$' // CC2C17 // AGENT INPUT
    // // const discountProvided = 0 // CC2C18 // AGENT INPUT
    // // const pickupDate = new Date() // CC2C7 // AGENT INPUT

    // // const FF2N2 = "Gross" || "Net Cheapa NZ" || "Net Apollo Oz" || "..."
    // const FF2S2 = 1 // paymentProfile.mode == 'GROSS_DOWN' ? 1 : 1 - commRateForCompany // 1 /* lookup F2J2 where FF2H2 == FF2N2 */
    // const totalDailyRate = days * dailyRate * FF2S2 // CC2I3 // The "direct rate" to show

    // // const link = "http://.." // lookup F2AS=CC2D3 // check data.ts
    // const F2U2 = 1 /* lookup F2K2 where F2B2 == F2T3 */
    // const totalDailyInsurance = insurance * F2U2 * days // CC2M3

    // // TODO: insurance commission rate

    // const totalDailyAndInsurance = totalDailyRate + totalDailyInsurance // CC2C11 // substitute for inline, useless var
    // const totalExtras = nonCommissionableExtras + commissionableExtras // CC2F11
    // const totalDirectRate = totalDailyAndInsurance + totalExtras // CC2F15

    // // const discountAmount = 0 /* lookup (F2AN2=CC2I3*0.01) where F2AM2 == discountProvided */ // F2AP2 // CC2C19 // AKA "0.01 * totalDailyRate when 1%"
    // const totalDiscount = (discountType == 'percent') ? totalDailyRate * (discountAmount ?? 0) / 100 : (discountAmount ?? 0) * 100
    // const rptDailyAndInsurance = totalDailyRate - totalDiscount + totalDailyInsurance // CC2C23 // substitute for inline, useless var
    // // const totalExtras = nonCommissionableExtras + commissionableExtras // CC2F23 // duplicate
    // const totalRPTRate = rptDailyAndInsurance + totalExtras // CC2F27

    // const netRateAppliedDaily = paymentProfile.mode == 'GROSS_DOWN' ? 1 - commRateForCompany : 1 // 0.75 // F2I2 where F2D2 == company // F2L2 // AKA "the net rate for this company"
    // const netRateDaily = dailyRate * days * netRateAppliedDaily // F2O2
    // const netRateInsurance = insurance * days * netRateAppliedDaily // F2P2
    // const netRate = netRateDaily + netRateInsurance // [cheapa,apollo,hippieAu].includes(company) ? "" : netRateDaily + netRateInsurance // CC2M20
    // // const blank = 0 // CC2M21 // mistake? used by CC2M27 and CC2M22

    // // const commRateForCompany = 0.25 // lookup F2V2 where F2D2==CC2C3 // F2AF2 // CC2E3
    // const extrasComm = commissionableExtras * commRateForCompany // F2R2
    // const RPTComm = rptDailyAndInsurance - netRate + extrasComm // CC2M27
    // // const paymentDate = pickupDate.getTime() - (65 * DAY) // CC2C9
    // // const netRate = CC2M20 // CC2M22 // duplicate

    // console.debug({
    //     totalDailyInsurance,
    //     totalDailyAndInsurance,
    //     totalExtras,
    //     totalDirectRate,
    //     totalDiscount,
    //     rptDailyAndInsurance,
    //     totalRPTRate,
    //     netRateAppliedDaily,
    //     netRateDaily,
    //     netRateInsurance,
    //     netRate,
    //     extrasComm,
    //     RPTComm,
    //     paymentDate,
    // })
}
const memos = ({
    bond,
    camper,
    company,
    days,
    dropoff,
    link,
    netRate,
    pax,
    paxChildren,
    paymentDate,
    paymentProfile,
    pickup,
    RPTComm,
    totalDailyInsurance,
    totalDailyRate,
    totalDirectRate,
    totalRPTRate,
    totalExtras,
    start,
    end,
    zdid,
}) => {
    try {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ]

        const dMMMMyyyy = (d: number) => { // 1 October, 2024
            const D = new Date(d)
            const day = D.getDate();
            const month = months[D.getMonth()]
            const year = D.getFullYear()
            return `${day} ${month}, ${year}`
        }
        const MMMMdyyyy = (d: number) => { // October 1, 2024
            const D = new Date(d)
            const day = D.getDate();
            const month = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ][D.getMonth()]
            const year = D.getFullYear()
            return `${day} ${month}, ${year}`
        }

        const paymentType: paymentType = paymentProfile?.type ?? "DEPOSIT" /* lookup F2E2 where F2D2 == company */ // F2F2 // CC2C5 // AKA "paymentType for this company, deposit or full?"
        const alreadyDue = paymentDate <= Date.now() // CC2D9 // AKA "if not depositing, is the 65daysPrior payment date already passed?"

        const external = [
            ``,
            `Here is a reminder of the campervan spec link:`,
            link,
            `The direct daily rate is $${(totalDailyRate / days / 100).toFixed(0)}.00 plus $${(totalDailyInsurance / days / 100).toFixed(0)}.00 per day for the insurance which reduces your excess to $${(bond / 100).toFixed(2)}. Plus the extras of $${(totalExtras / 100).toFixed(2)}`,
            `The total direct cost over the ${days} days = $${(totalDirectRate / 100).toFixed(2)}`,
            `The total discounted rate = $${(totalRPTRate / 100).toFixed(2)}`,
            `This saves you a total of $${((totalDirectRate - totalRPTRate) / 100).toFixed(2)}`,
            `There is only 1 of these vans left at this discounted rate.`,
            (paymentType == 'DEPOSIT') ? `To reserve this camper the company requires a deposit of $${Math.floor(RPTComm / 100)} with the remaining balance due on arrival.`
                : alreadyDue ? `As your pick up is within the next 65 days the camper company requires the balance in full in order to reserve the campervan, the amount payable today is: $${((netRate - RPTComm) / 100).toFixed(2)}`
                    : `To reserve this camper the company requires a deposit of $${Math.floor(RPTComm / 100).toFixed(2)} with the remaining balance due 65 days before your pick-up date on ${dMMMMyyyy(paymentDate)}.`,
            `As a reminder - if any disruptions occur then we can simply move your booking free of charge, meaning there are no admin fees from our side, but the rates and availability are set by the camper company.`,
            `Would you like me to reserve this last discounted campervan for you?`,
        ].join('\n\n')

        const internal = [
            `Reference: ${zdid}`,
            `Pick-up: ${[pickup, MMMMdyyyy(start.getTime())].join(' - ')}`,
            `Drop-off: ${[dropoff, MMMMdyyyy(end.getTime())].join(' - ')}`,
            `Days: ${days}`,
            `Adults: ${pax}`,
            `Children (Under 8): ${paxChildren}`,
            `Company: ${company}`,
            `Model: ${camper}`,
            ...((paymentType == 'DEPOSIT') ? [
                `Deposit Amount: $${(RPTComm / 100).toFixed(2)}`,
                `Remaining Balance (paid on arrival) including extras: $${((netRate + totalDailyInsurance) / 100).toFixed(2)}`,
            ] : alreadyDue ? [
                `Total Payable to RatPack: $${((netRate + RPTComm) / 100).toFixed(2)}`,
                `Extras Payable on Arrival: $${(totalDailyInsurance / 100).toFixed(2)}`,
            ] : [
                `Deposit Amount: $${(RPTComm / 100).toFixed(2)}`,
                `Remaining Balance: ${dMMMMyyyy(paymentDate)} (due 65 days before pickup): $${(netRate / 100).toFixed(2)}`,
                `Total Payable to RatPack: $${((netRate + RPTComm) / 100).toFixed(2)}`,
                `Extras Payable on Arrival: $${(totalDailyInsurance / 100).toFixed(2)}`,
            ]),
            `TOTAL: $${(totalRPTRate / 100).toFixed(2)}`,
            `Insurance level added to booking (per day): $${((totalDailyInsurance / days) / 100).toFixed(2)}`,
            `Bond Amount: $${(bond / 100).toFixed(2)}`,
        ].join('\n\n')

        return {
            internal,
            external,
        }
    } catch (memo_error) {
        console.warn({ memo_error })
        return {
            internal: `Error: ${memo_error}`,
            external: '',
            totalDailyRate: 0,
            totalDirectRate: 0,
            totalDiscount: 0,
            total: 0,
            deposit: 0,
        }
    }
}

// For rendering numbers with a label, and turning red when a rule like <=0 is met
const RedNumber = ({
    prefix = '$',
    rule = v => v <= 0,
    n = 0,
    label = "",
}) => {
    return (<Text size="xs">{label}<Text size="md" color={rule(n) ? 'red' : 'black'}>{prefix}{n.toFixed(2)}</Text></Text>)
}

// For rendering generated text
const NotePaper = ({
    placeholder = "Must include message",
    label,
    useValue,
}) => {
    return <>
        {/* <Paper shadow="xs" radius="xs" p="md" style={{
            maxWidth: '100%',
            overflow:'scroll',
            maxHeight: '250px',
            width: '100%',
        }}> */}
        <Textarea
            size="sm"
            style={{
                overflow: 'scroll',
                maxHeight: '300px',
                width: '100%',
                // fontSize: '12px',
                // minWidth: '1600px',
            }}
            styles={{
                input: {
                    height: '250px',
                    lineHeight: 0.9,
                }
            }}
            placeholder={placeholder}
            label={label}
            {...useValue}
        ></Textarea>
        {/* </Paper> */}
    </>
}