import React from 'react'
import {
  Checkbox as MCheckbox,
  CheckboxProps as MCheckboxProps,
  createStyles,
  MantineColor,
} from '@mantine/core'

const useRootStyles = createStyles((theme, _params) => {
  return {
    custom: {},

    black: {},
  }
})

const useInputStyles = createStyles((theme, _params) => {
  return {
    custom: {},

    black: {
      backgroundColor: theme.colors.brandBlack[6],
    },
  }
})

export type CheckboxProps = MCheckboxProps & {
  styleType?: 'custom' | 'black'
}

export function Checkbox({ styleType = 'black', ...props }: CheckboxProps) {
  const { classes: rootClasses } = useRootStyles()
  const { classes: inputClasses } = useInputStyles()

  let color: MantineColor | undefined = undefined

  switch (styleType) {
    case 'black':
      color = 'brandBlack'
      break
  }

  return (
    <MCheckbox
      classNames={{
        root: rootClasses[styleType],
        input: inputClasses[styleType],
      }}
      color={color}
      {...props}
    >
      {props.children}
    </MCheckbox>
  )
}
