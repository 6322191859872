import React from 'react'
import {
  TextInput as MTextInput,
  TextInputProps as MTextInputProps,
  createStyles,
} from '@mantine/core'

const useRootStyles = createStyles((theme, _params) => {
  return {
    custom: {},

    black: {
      width: '100%',
    },
  }
})

const useInputStyles = createStyles((theme, _params) => {
  return {
    custom: {},

    black: {
      backgroundColor: theme.colors.brandBlack[6],
      color: theme.white,
      '&:hover': {
        backgroundColor: theme.colors.brandBlack[5],
      },
    },
  }
})

export type TextInputProps = MTextInputProps & {
  styleType?: 'custom' | 'black'
}

export function TextInput({ styleType = 'black', ...props }: TextInputProps) {
  const { classes: rootClasses } = useRootStyles()
  const { classes: inputClasses } = useInputStyles()

  return (
    <MTextInput
      classNames={{
        root: rootClasses[styleType],
        input: inputClasses[styleType],
      }}
      {...props}
    >
      {props.children}
    </MTextInput>
  )
}
