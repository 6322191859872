import React from 'react'
import { createStyles } from '@mantine/core'

import { WithChildren, WithExtras } from '../../util/ComponentUtil'
import { PageMaxWidthContainer } from '../PageMaxWidthContainer/PageMaxWidthContainer'

type PageContentContainerStyleProps = {
  noVerticalPadding: boolean
}

const useStyles = createStyles(
  (theme, { noVerticalPadding }: PageContentContainerStyleProps) => {
    return {
      component: {
        width: '100%',
      },

      innerContainer: {
        width: '100%',
        ...(noVerticalPadding
          ? {
              padding: '0 20px',
            }
          : {
              padding: '20px',
            }),
      },
    }
  }
)

export type PageContentContainerProps = WithChildren<
  WithExtras<
    {
      noVerticalPadding?: boolean
    },
    {
      component: 'div'
      pageMaxWidthContainer: typeof PageMaxWidthContainer
      innerContainer: 'div'
    }
  >
>

export function PageContentContainer({
  noVerticalPadding = false,
  children,
  extras,
}: PageContentContainerProps) {
  const { classes } = useStyles({ noVerticalPadding })

  return (
    <div className={classes.component} {...extras?.component}>
      <PageMaxWidthContainer {...extras?.pageMaxWidthContainer}>
        <div className={classes.innerContainer} {...extras?.innerContainer}>
          {children}
        </div>
      </PageMaxWidthContainer>
    </div>
  )
}
