import React from 'react'
import {
  Badge,
  Collapse,
  Grid,
  Menu,
  Pagination,
  Space,
  Table,
} from '@mantine/core'

import { PageContentContainer } from '../components/PageContentContainer/PageContentContainer'
import { TextInput } from '../components/TextInput/TextInput'
import { Button } from '../components/Button/Button'
import { Anchor } from '../components/Anchor/Anchor'
import { Select } from '../components/Select/Select'
import { Checkbox } from '../components/Checkbox/Checkbox'
import { Text } from '../components/Text/Text'

export function AdminListQuotesPage({
  heading = <h2>Trips</h2>,
  topControlsArea = AdminListQuotesPage.TopControlsArea({}),
  tableHeadingRow = (
    <tr>
      <th></th>
      <th>
        <Anchor styleType="table-header">Booking Ref</Anchor>
      </th>
      <th>
        <Anchor styleType="table-header">Name</Anchor>
      </th>
      <th>
        <Anchor styleType="table-header">Quote Date</Anchor>
      </th>
      <th>
        <Anchor styleType="table-header">Agent</Anchor>
      </th>
      <th>
        <Anchor styleType="table-header">Link</Anchor>
      </th>
      <th>
        <Anchor styleType="table-header">Description</Anchor>
      </th>
      <th>
        <Anchor styleType="table-header">Offer expires</Anchor>
      </th>
      <th>
        <Anchor styleType="table-header">Status</Anchor>
      </th>
      <th></th>
    </tr>
  ),
  tableRows = [
    AdminListQuotesPage.TableRow({}),
    AdminListQuotesPage.TableRow({}),
    AdminListQuotesPage.TableRow({}),
  ],
  bottomArea = AdminListQuotesPage.BottomArea({}),
}: {
  heading?: React.ReactNode
  topControlsArea?: React.ReactNode
  tableHeadingRow?: React.ReactNode
  tableRows?: React.ReactNode
  bottomArea?: React.ReactNode
}) {
  return (
    <>
      <PageContentContainer>
        {heading}
        {topControlsArea}
        <Space h={15} />
        <div
          style={{ backgroundColor: '#ffffff', padding: 10, borderRadius: 5 }}
        >
          <Table highlightOnHover>
            <thead style={{ backgroundColor: '#f5f5f5' }}>
              {tableHeadingRow}
            </thead>
            <tbody>{tableRows}</tbody>
          </Table>
        </div>
      </PageContentContainer>
      <PageContentContainer>{bottomArea}</PageContentContainer>
    </>
  )
}

/*

████████╗ ██████╗ ██████╗      ██████╗ ██████╗ ███╗   ██╗████████╗██████╗  ██████╗ ██╗     ███████╗     █████╗ ██████╗ ███████╗ █████╗ 
╚══██╔══╝██╔═══██╗██╔══██╗    ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔══██╗██╔═══██╗██║     ██╔════╝    ██╔══██╗██╔══██╗██╔════╝██╔══██╗
   ██║   ██║   ██║██████╔╝    ██║     ██║   ██║██╔██╗ ██║   ██║   ██████╔╝██║   ██║██║     ███████╗    ███████║██████╔╝█████╗  ███████║
   ██║   ██║   ██║██╔═══╝     ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══██╗██║   ██║██║     ╚════██║    ██╔══██║██╔══██╗██╔══╝  ██╔══██║
   ██║   ╚██████╔╝██║         ╚██████╗╚██████╔╝██║ ╚████║   ██║   ██║  ██║╚██████╔╝███████╗███████║    ██║  ██║██║  ██║███████╗██║  ██║
   ╚═╝    ╚═════╝ ╚═╝          ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝    ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝
                                                                                                                                       
*/

AdminListQuotesPage.TopControlsArea = function ({
  bulkActionsSelect = (
    <Select
      placeholder="Bulk actions"
      value={'bulk-action-1'}
      data={[
        {
          value: 'bulk-action-1',
          label: 'Bulk action #1',
        },
        { value: 'bulk-action-2', label: 'Bulk action #2' },
        { value: 'bulk-action-3', label: 'Bulk action #3' },
      ]}
    />
  ),
  applyButton = <Button styleType="white">Apply</Button>,
  statusSelect = (
    <Select
      placeholder="All Status"
      value={'status-1'}
      data={[
        {
          value: 'all-status-1',
          label: 'All Status #1',
        },
        { value: 'all-status-2', label: 'All Status #2' },
        { value: 'all-status-3', label: 'All Status #3' },
      ]}
    />
  ),
  searchInput = <TextInput placeholder="Search pages" />,
}: {
  bulkActionsSelect?: React.ReactNode
  applyButton?: React.ReactNode
  statusSelect?: React.ReactNode
  searchInput?: React.ReactNode
}) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', width: '100%' }}>
        {bulkActionsSelect}
        <Space w={10} />
        {applyButton}
      </div>
      <div style={{ display: 'flex', marginLeft: 'auto' }}>
        {statusSelect}
        <Space w={10} />
        {searchInput}
      </div>
    </div>
  )
}

/*

████████╗ █████╗ ██████╗ ██╗     ███████╗    ██████╗  ██████╗ ██╗    ██╗
╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝    ██╔══██╗██╔═══██╗██║    ██║
   ██║   ███████║██████╔╝██║     █████╗      ██████╔╝██║   ██║██║ █╗ ██║
   ██║   ██╔══██║██╔══██╗██║     ██╔══╝      ██╔══██╗██║   ██║██║███╗██║
   ██║   ██║  ██║██████╔╝███████╗███████╗    ██║  ██║╚██████╔╝╚███╔███╔╝
   ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝    ╚═╝  ╚═╝ ╚═════╝  ╚══╝╚══╝ 
                                                                        
*/

AdminListQuotesPage.TableRow = function ({
  checkbox = <Checkbox />,
  bookingRef = <Anchor styleType="basic">1234567</Anchor>,
  name = 'Alessandro Buchanan',
  quoteDate = '15 Jan 2022 9.45pm',
  agent = 'Agent',
  urlLink = <Anchor styleType="basic">Link</Anchor>,
  description = 'East Coast Adventure',
  offerExpires = '14 Days',
  status = <Badge variant="dot">pending</Badge>,
  actionButtons = (
    <Menu>
      <Menu.Item>Item 1</Menu.Item>
      <Menu.Item>Item 2</Menu.Item>
      <Menu.Item>Item 3</Menu.Item>
    </Menu>
  ),
  firstDetailTableRows = [
    AdminListQuotesPage.TableDetailsRow({
      label: 'Phone',
      value: '61 421587126',
    }),
    AdminListQuotesPage.TableDetailsRow({
      label: 'Email',
      value: 'alessandrobuchanan@email.com',
    }),
    AdminListQuotesPage.TableDetailsRow({ label: 'Discount', value: '20%' }),
    AdminListQuotesPage.TableDetailsRow({ label: 'Record No.', value: '2112' }),
    AdminListQuotesPage.TableDetailsRow({ label: 'Agent', value: 'Mikey' }),
  ],
  secondDetailTableRows = [
    AdminListQuotesPage.TableDetailsRow({
      label: 'Pickup location',
      value: 'Byron',
    }),
    AdminListQuotesPage.TableDetailsRow({
      label: 'Drop off location',
      value: 'Sydney',
    }),
    AdminListQuotesPage.TableDetailsRow({
      label: 'Start Date',
      value: '15 Jan 2022',
    }),
    AdminListQuotesPage.TableDetailsRow({
      label: 'End Date',
      value: '30 Jan 2022',
    }),
    AdminListQuotesPage.TableDetailsRow({
      label: 'Duration',
      value: '15 Days',
    }),
  ],
  expanded = false,
  extraControls = (<Select
    placeholder="Complete"
    data={[
      { value: 'Item 1', label: 'Item 1' },
      { value: 'Item 2', label: 'Item 2' },
      { value: 'Item 3', label: 'Item 3' },
    ]}
  ></Select>),
  ...rest // onClick
}: {
  checkbox?: React.ReactNode
  bookingRef?: React.ReactNode
  name?: React.ReactNode
  quoteDate?: React.ReactNode
  agent?: React.ReactNode
  urlLink?: React.ReactNode
  description?: React.ReactNode
  offerExpires?: React.ReactNode
  status?: React.ReactNode
  actionButtons?: React.ReactNode
  firstDetailTableRows?: React.ReactNode
  secondDetailTableRows?: React.ReactNode
  expanded?: boolean
    extraControls?: React.ReactNode
}) {
  return (
    <>
      <tr {...rest}>
        <td>
          <Text style={{ wordBreak: 'break-all' }}>{checkbox}</Text>
        </td>
        <td>
          <Text style={{ wordBreak: 'break-all' }}>{bookingRef}</Text>
        </td>
        <td>
          <Text style={{ wordBreak: 'break-all' }}>{name}</Text>
        </td>
        <td>
          <Text style={{ wordBreak: 'break-all' }}>{quoteDate}</Text>
        </td>
        <td>
          <Text style={{ wordBreak: 'break-all' }}>{agent}</Text>
        </td>
        <td>
          <Text style={{ wordBreak: 'break-all' }}>{urlLink}</Text>
        </td>
        <td>
          <Text style={{ wordBreak: 'break-all' }}>{description}</Text>
        </td>
        <td>
          <Text style={{ wordBreak: 'break-all' }}>{offerExpires}</Text>
        </td>
        <td>
          <Text style={{ wordBreak: 'break-all' }}>{status}</Text>
        </td>
        <td>
          <Text style={{ wordBreak: 'break-all' }}>{actionButtons}</Text>
        </td>
      </tr>
      <tr>
        <td style={{ padding: 0, borderBottom: 0 }}></td>
        <td style={{ padding: 0, borderBottom: 0 }}></td>
        <td colSpan={999} style={{ padding: 0, borderBottom: 0 }}>
          <Collapse in={expanded}>
            <Space h={30} />
            <Grid gutter={30}>
              <Grid.Col span={5}>
                <table>
                  <tbody>{firstDetailTableRows}</tbody>
                </table>
              </Grid.Col>
              <Grid.Col span={5}>
                <table>
                  <tbody>{secondDetailTableRows}</tbody>
                </table>
              </Grid.Col>
              <Grid.Col span={2}>
                {extraControls}
              </Grid.Col>
            </Grid>
            <Space h={30} />
          </Collapse>
        </td>
      </tr>
    </>
  )
}

/*

████████╗ █████╗ ██████╗ ██╗     ███████╗    ██████╗ ███████╗████████╗ █████╗ ██╗██╗     ███████╗    ██████╗  ██████╗ ██╗    ██╗
╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝    ██╔══██╗██╔════╝╚══██╔══╝██╔══██╗██║██║     ██╔════╝    ██╔══██╗██╔═══██╗██║    ██║
   ██║   ███████║██████╔╝██║     █████╗      ██║  ██║█████╗     ██║   ███████║██║██║     ███████╗    ██████╔╝██║   ██║██║ █╗ ██║
   ██║   ██╔══██║██╔══██╗██║     ██╔══╝      ██║  ██║██╔══╝     ██║   ██╔══██║██║██║     ╚════██║    ██╔══██╗██║   ██║██║███╗██║
   ██║   ██║  ██║██████╔╝███████╗███████╗    ██████╔╝███████╗   ██║   ██║  ██║██║███████╗███████║    ██║  ██║╚██████╔╝╚███╔███╔╝
   ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝    ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝╚══════╝    ╚═╝  ╚═╝ ╚═════╝  ╚══╝╚══╝ 
                                                                                                                                
*/

AdminListQuotesPage.TableDetailsRow = function ({
  label,
  value,
}: {
  label?: React.ReactNode
  value?: React.ReactNode
}) {
  // We use divs to stop inheriting styles from Mantine's <Table />
  return (
    <tr>
      <div style={{ display: 'table-cell', paddingRight: 20 }}>
        <Text styleType="light">{label}</Text>
      </div>
      <div style={{ display: 'table-cell' }}>
        <Text>{value}</Text>
      </div>
    </tr>
  )
}

/*

██████╗  ██████╗ ████████╗████████╗ ██████╗ ███╗   ███╗     █████╗ ██████╗ ███████╗ █████╗ 
██╔══██╗██╔═══██╗╚══██╔══╝╚══██╔══╝██╔═══██╗████╗ ████║    ██╔══██╗██╔══██╗██╔════╝██╔══██╗
██████╔╝██║   ██║   ██║      ██║   ██║   ██║██╔████╔██║    ███████║██████╔╝█████╗  ███████║
██╔══██╗██║   ██║   ██║      ██║   ██║   ██║██║╚██╔╝██║    ██╔══██║██╔══██╗██╔══╝  ██╔══██║
██████╔╝╚██████╔╝   ██║      ██║   ╚██████╔╝██║ ╚═╝ ██║    ██║  ██║██║  ██║███████╗██║  ██║
╚═════╝  ╚═════╝    ╚═╝      ╚═╝    ╚═════╝ ╚═╝     ╚═╝    ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝
                                                                                                                                                                                             
*/

AdminListQuotesPage.BottomArea = function ({
  leftContent = 'Showing 12 of 5,232 trips',
  rightContent = <Pagination color="brandBlack" page={1} total={3} />,
}: {
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
}) {
  // We use divs to stop inheriting styles from Mantine's <Table />
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: 'auto' }}>{leftContent}</div>
      <div>{rightContent}</div>
    </div>
  )
}
