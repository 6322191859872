import { LoadingOverlay } from '@mantine/core';
import { Anchor } from 'ratpack-ui/src/components/Anchor/Anchor';
import { Button } from 'ratpack-ui/src/components/Button/Button';
import { TextInput } from 'ratpack-ui/src/components/TextInput/TextInput';
import { LoginLoginPage } from 'ratpack-ui/src/pages/LoginLoginPage';
import { LoginPageLayout } from 'ratpack-ui/src/pages/LoginPageLayout';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { post_login } from '../api';
import { isEmail, userAtom } from '../session';
import { stateful } from '../util';

import iconBus from '../assets/bus.png'

import {
    Anchor as MAnchor,
    AnchorProps as MAnchorProps,
    createStyles,
} from '@mantine/core'

export const LoginPage = () => {
    const [username, useUsername, validUsername] = stateful('', { validate: isEmail });
    const [password, usePassword, validPassword] = stateful('', { validate: v => v });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const refreshUser = useRecoilRefresher_UNSTABLE(userAtom)
    const user = useRecoilValue(userAtom)

    const submit = () => {
        if (loading) return
        setLoading(true)
        post_login({ username, password }).then(res => {
            console.log({ res })
            setError(res.status)
            refreshUser()
        }).catch((err) => {
            setError(err?.toString?.())
        }).finally(() => {
            setLoading(false)
        })
    }

    console.log({ user })

    const status = false ? <pre>
        {JSON.stringify(user)}
    </pre> : null

    return <LoginPageLayout page={<>
        <LoadingOverlay visible={loading} />
        <LoginLoginPage
            boxContent={LoginLoginPage.BoxContent({
                image: (<img src={iconBus} alt="Ratpack Travel" style={{ maxWidth: 74 }} />),
                usernameInput: (
                    <TextInput
                        styleType="light"
                        placeholder="Email"
                        style={{ minWidth: 353 }}
                        {...useUsername}
                        onKeyPress={(key: any) => {
                            if (key.key === 'Enter') submit();
                        }}
                    />
                ),
                passwordInput: (
                    <TextInput
                        styleType="light"
                        placeholder="Password"
                        type="password"
                        style={{ width: '100%' }}
                        styles={{
                            rightSection: {
                                width: 130,
                            },
                        }}
                        rightSection={null}
                        {...usePassword}
                        onKeyPress={(key: any) => { if (key.key === 'Enter') submit(); }}
                        // error={error}
                    />
                ),
                submitButton: (<>
                    <Button
                        // component={Link}
                        onClick={submit}
                        // to="/loading"
                        styleType="black"
                        style={{ width: '100%' }}
                    >
                        Login
                    </Button>

                    {status}
                </>
                ),
                bottomContent: (
                    <div>
                        <span>Forgot password? </span>
                        <Anchor styleType="light" component={MAnchor} href="mailto:info@ratpacktravel.com">
                            Email support
                        </Anchor>
                        {error && <br />}
                        {error}
                    </div>
                ),
            })}
        />
    </>} />
}
