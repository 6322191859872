import React from 'react'
import { Outlet } from 'react-router-dom'
import { Group } from '@mantine/core'

import { Footer } from '../components/Footer/Footer'
import { Text } from '../components/Text/Text'
import { Layout } from '../layouts/Layout'

export function LoginPageLayout({
  header = null,
  footer = LoginPageLayout.Footer({}),
  page = <Outlet />,
}: {
  header?: React.ReactNode
  footer?: React.ReactNode
  page?: React.ReactNode
}) {
  return (
    <Layout styleType="white" header={header} footer={footer}>
      {page}
    </Layout>
  )
}

/*

███████╗ ██████╗  ██████╗ ████████╗███████╗██████╗ 
██╔════╝██╔═══██╗██╔═══██╗╚══██╔══╝██╔════╝██╔══██╗
█████╗  ██║   ██║██║   ██║   ██║   █████╗  ██████╔╝
██╔══╝  ██║   ██║██║   ██║   ██║   ██╔══╝  ██╔══██╗
██║     ╚██████╔╝╚██████╔╝   ██║   ███████╗██║  ██║
╚═╝      ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝╚═╝  ╚═╝                                                 

*/

LoginPageLayout.Footer = function ({
  topContent = null,
  spacer = null,
  bottomContent = LoginPageLayout.FooterBottomContentWrapper({}),
}: {
  topContent?: React.ReactNode
  spacer?: React.ReactNode
  bottomContent?: React.ReactNode
}) {
  return (
    <Footer
      extras={{
        component: {
          style: {
            position: 'absolute',
            bottom: 0,
          },
        },
      }}
    >
      {topContent}
      {spacer}
      {bottomContent}
    </Footer>
  )
}

LoginPageLayout.FooterBottomContentWrapper = function ({
  content = <Text>Changing UP the Travel Game</Text>,
}: {
  content?: React.ReactNode
}) {
  return (
    <Group direction="column" align="center">
      {content}
    </Group>
  )
}
