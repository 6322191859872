import React from 'react'
import { createStyles } from '@mantine/core'
import { WithChildren, WithExtras } from '../../util/ComponentUtil'

const useStyles = createStyles((theme, _params) => {
  return {
    component: {},

    normalPrice: {
      textDecoration: 'line-through',
    },

    discountPrice: {
      fontSize: '30px',
      lineHeight: '36px',
      fontWeight: 600,
      marginLeft: 9,
    },
    timeUnit: {
      color: theme.colors.brandDarkGray[4],
    },
    totalDiscountPrice: {
      color: theme.colors.brandDarkGray[4],
    },
  }
})

export type ItemPriceProps = WithChildren<
  WithExtras<
    {
      normalPrice: string
      discountPrice: string
      timeUnit?: string
      totalDiscountPriceLabel: string
      totalDiscountPrice: string
    },
    {
      component: 'div'
      top: 'div'
      normalPrice: 'span'
      discountPrice: 'span'
      timeUnit: 'span'
      totalDiscountPrice: 'div'
    }
  >
>

export function ItemPrice({
  normalPrice = '$80.00',
  discountPrice = '$100.00',
  timeUnit = ' / day',
  totalDiscountPriceLabel = 'Total discounted rate:',
  totalDiscountPrice = '$1600.00',
  extras,
}: ItemPriceProps) {
  const { classes } = useStyles()

  return (
    <div className={classes.component} {...extras?.component}>
      <div {...extras?.top}>
        <span className={classes.normalPrice} {...extras?.normalPrice}>
          {normalPrice}
        </span>
        <span className={classes.discountPrice} {...extras?.discountPrice}>
          {discountPrice}
        </span>
        <span className={classes.timeUnit} {...extras?.timeUnit}>
          {timeUnit}
        </span>
      </div>
      <div
        className={classes.totalDiscountPrice}
        {...extras?.totalDiscountPrice}
      >
        {totalDiscountPriceLabel ? `${totalDiscountPriceLabel} ` : null}
        {totalDiscountPrice}
      </div>
    </div>
  )
}
