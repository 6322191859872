import { Calendar } from '@mantine/dates';
import { useEffect, useRef, useState } from 'react';
import { MustLogIn } from '../session';
import { AdminPageLayout } from 'ratpack-ui/src/pages/AdminPageLayout';
import { adminDefaults } from './components';
import { ActionIcon, CloseButton, Group, Loader, NumberInput, SegmentedControl } from '@mantine/core';
import { deepCompare } from '../util';
import { Button } from 'ratpack-ui/src/components/Button/Button';

// import { IconX } from '@tabler/icons-react';

export const providerNames = { // TODO import from server
    'au.camperman': 'AU Camperman',
    // 'nz.barefoot': 'NZ Barefoot',
} as const
export const productNames = { // TODO import from server
    'au.camperman': {
        // 'juliette3':'Juliette 3',
        'maxie3':'Maxie 3',
        'maxie4':'Maxie 4',
        'paradisefamily':'Paradise Family 5',
        'paradiseshower':'Paradise Shower and Toilet',
    },
    // 'nz.barefoot': {
    //     'lotop': 'Low Top',
    //     'hitop': 'HiTop',
    // },
} as const

type ProviderName = keyof typeof providerNames
// type ProductProviderName = keyof typeof productNames
// type ProductNames = typeof productNames[ProductProviderName]
// type ProductName = keyof ProductNames
type ProductName = keyof typeof productNames['au.camperman'] // | keyof typeof productNames['nz.barefoot'] // Leaves<typeof productNames>

type ProviderProduct = {[k in ProviderName]?: ProductName}
type ProviderProductPrices = {
    [k in ProviderName]?: {
        [j in ProductName]?: {
            [k:number]: {
                [duration:number]: number, //  rate,
                 // days : cents per day
            }
        }
    }
}

type DayRates = { [k:number]: number } // { [numberDays]: dayRateCents }
type ProductRates = { [k:number]: DayRates } // { [date]: rates for that day by duration }
type ProviderRates = { [k:string]: ProductRates } // { [product]: product rate calendar }
type DTO = { [k:string]: ProviderRates } // { [provider]: products }

const UseFocus = ():[React.MutableRefObject<any>,() => void] => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

    return [ htmlElRef,  setFocus ] 
}
const lpad = (n:number|string, c='0', l=2) => ([...new Array(l).fill(c)].join('')+n).slice(-l)
const format = (d:Date) => `${d.getFullYear()}-${lpad(d.getMonth()+1)}-${lpad(d.getDate())}`

export default () => {
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState('')
    const [selectedDate,setSelectedDate] = useState(null)
    const setErrorWithContext = (context:String) => (e:any) => setError(`${context}: ${e}`)

    const [originalStates,setOriginalProviderPrices] = useState(null)
    const [lastUsedProductByProvider,setLastUsedProductByProvider] = useState<ProviderProduct & {provider:ProviderName}>({
        provider: 'au.camperman',
        // 'au.camperman': '',
        // 'nz.barefoot': '',
    })
    const [providerStates,setProviderPrices] = useState<ProviderProductPrices>({
        // 'au.camperman': {},
        // 'nz.barefoot': {},
    })
    // const [activeProvider,setActiveProviderValue] = useState<keyof typeof providerNames>('au.camperman')
    const setActiveProviderValue = (provider:ProviderName) => setLastUsedProductByProvider(v => {
        return {
            ...v,
            provider
        }
    })
    const setActiveProductValue = (product:ProductName) => setLastUsedProductByProvider(v => {
        return {
            ...v,
            [v.provider]: product,
        }
    })
    // const [activeProduct,setActiveProductValue] = useState<ProductName>('juliette3' as ProductName)

    const setActiveProduct = (p:ProductName, provider:ProviderName=lastUsedProductByProvider.provider) => {
        setActiveProductValue(p)
    }
    const setActiveProvider = (p:ProviderName) => {
        const product = lastUsedProductByProvider[p]??Object.keys(productNames[p])[0] as ProductName
        console.log(`setActiveProvider(${p}): ${product} (LU:${lastUsedProductByProvider[p]} or ${Object.keys(productNames[p]).join(',')} for ${p})`)
        setActiveProviderValue(p)
        setActiveProduct(product, p)
    }

    useEffect(() => {
        fetch('/api/providerPrice')
            .then(res => res.json())
            .then(({data}) => {
                setOriginalProviderPrices(data)
                setProviderPrices(data)
                setActiveProvider('au.camperman')
            })
            .then(()=>setLoading(false))
            .catch(setErrorWithContext('Failed to fetch latest provider data from RP server'))
    }, []);

    const sendStates = () =>{
        if(loading) return;
        setLoading(true)
        fetch('/api/providerPrice', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({data:providerStates})
        })
        .then(res => res.json())
        .then(({data})=>{
            setOriginalProviderPrices(data)
            setLoading(false)
        }).catch(setErrorWithContext('Failed to submit latest provider data to RP server'))
    }

    const provider = lastUsedProductByProvider.provider
    const product = lastUsedProductByProvider[provider]
    const dayRates = (date:number)=>providerStates?.[provider]?.[product]?.[date]
    // const setDayRate = (n:number)=>(v:number|null) => setProviderPrices(p => ({
    //     ...p,
    //     [provider]: {
    //         ...p?.[provider]||{},
    //         [product]: {
    //             ...(p?.[provider]?.[product]||{}) as any,
    //             [n]: v,
    //         }
    //     }
    // }))
    const ObjFilter = (f:any) => (obj:any) => Object.fromEntries(Object.entries(obj).filter(f))
    const removeNullK = ObjFilter(([k,v])=>k)
    const removeNullV = ObjFilter(([k,v])=>v!==null)
    const removeNanV = ObjFilter(([k,v])=>!isNaN(v as any))
    const removeEmptyV = ObjFilter(([k,v])=>Object.keys(v).length)
    // const removeEmpty = (obj:any) => (obj && Object.keys(obj).length) ? obj : null
    // const tee = (obj:any) => {
    //     console.log({tee:obj})
    //     return obj
    // }
    const setDayRateF = (provider:string, product:string, startDate:number)=>(f:(a:DayRates) => (DayRates)) => setProviderPrices(ProviderProductPricesRates => {
        const Provider = ProviderProductPricesRates?.[provider]||{}
        const Product = ProviderProductPricesRates?.[provider]?.[product]||{}
        const Prices = ProviderProductPricesRates?.[provider]?.[product]?.[startDate]??{}
        return {
            ...ProviderProductPricesRates,
            [provider]: {
                ...Provider,
                [product]: removeEmptyV(removeNullV({
                    ...Product,
                    [startDate]: removeNullV(removeNanV(f(Prices))),
                    //removeNullKV(f(Prices)),
                }))
            }
        }
    })
    // const toggleValue = (n:number)=>setDayRate(dayRates(n) ==null ? 0 : null)
    
    const changed = providerStates && !deepCompare(originalStates??{}, providerStates)

    return (<AdminPageLayout {...adminDefaults({ page: 'settings' })} page={<>
        <MustLogIn />
        <div style={{
            margin:10,
        }}>
            <Group
                style={{
                    justifyContent: 'space-between',
                }}
            >
                Set the daily rate for bookings starting on a given day for a provider. Set 0 for unavailable. If no value is set, carry the previous rate.
                <Button
                    disabled={!changed || loading}
                    onClick={sendStates}
                    color='red'
                >
                    Save Changes
                </Button>
            </Group>
        </div>
        {error && <div style={{
            margin:10,
            color: 'red'
        }}>{JSON.stringify(error)}</div>}
        {loading ? 
            <div style={{
                margin:'30px',
                justifyContent: 'center',
                display: 'grid',
            }}><Group dir='col'><Loader/>Fetching latest data from server...</Group></div> : <>
            <SegmentedControl
                data={Object.entries(providerNames).map(([value,label])=>({label,value}))}
                onChange={v => setActiveProvider(v as ProviderName)}
                value={provider}
            />
            <SegmentedControl
                key={provider}
                data={Object.entries(productNames[provider]).map(([value,label])=>({label,value}))}
                onChange={v => setActiveProduct(v as ProductName)}
                value={product}
            />
            <div style={{
                margin:'30px',
                justifyContent: 'center',
                display: 'grid',
            }}>
                <Calendar
                    key={[provider,product].join(':')}
                    w='100%'
                    size='sm'
                    
                    renderDay={(date) => {
                        const time = date.getTime()
                        const priceSetDate = getPrecedingDate(providerStates?.[provider]?.[product], time, true)
                        const hasOwnRate = priceSetDate==time
                        const rates = dayRates(priceSetDate)
                        
                        const selected = selectedDate && (time==selectedDate)

                        const [ref, focus] = UseFocus()
                        return <div
                            style={{
                                // width:'auto',
                                width: '100%',
                                height: '100%',
                                overflowY: 'scroll',
                                justifyContent: 'center',
                                display: 'flex',
                                borderRadius: 3,
                                alignItems: 'flex-start',
                                backgroundColor: selected ? '#b0b0b0' : null,
                            }}
                            onClick={()=>{
                                console.log(date, selected)
                                if(!selected) setSelectedDate(time)
                            }}
                        >
                            <Group style={{
                                gap:0,
                                width: 'auto',
                                // display: 'contents',
                                display: 'flex',
                                flexDirection: 'column',
                                flexWrap: 'nowrap',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                            }}>
                                {format(date)}
                                <ul
                                    style={{
                                        color: hasOwnRate ? 'black' : 'gray',
                                        padding: 0,
                                        margin: 0,
                                    }}
                                >
                                    {priceSetDate == 0 && <li>No rate</li>}
                                    {Object.entries(rates??{}).map(([duration,rate],i)=>{
                                        const red = hasOwnRate ? 'red' : 'palevioletred'
                                        return <li
                                            key={i}
                                            color={rate==0 ? 'red' : undefined}
                                            style={{
                                                color: (rate==0) ? red : undefined,
                                            }}
                                        >{duration}d: ${rate/100}/d</li>
                                    })}
                                </ul>
                            </Group>
                        </div>
                    }}
                    styles={{
                        calendar: {
                            width: '100%',
                        },
                        month: {
                            width: '100%',
                        },
                        calendarHeader: {
                            width: '100%',
                            maxWidth: 'unset',
                        },
                        monthThead: {
                            width: '100%',
                        },
                        day: {
                            height: 90,
                            width: '100%',
                            // border: 'black 1px solid',
                            backgroundColor: '#ededed',
                            fontSize: '12px',
                        },
                        monthCell: {
                            padding: `5px !important`,
                        }
                    }}
                    // onDateChange={date=>{
                    //     console.log(date)
                    //     const n = date.getTime()
                    //     const v = dayRates(n)
                    //     const unset = v == null
                    //     setDayRate(n)(unset?0:null)
                    // }}
                />
                {selectedDate&&<RateDurations
                    dayRate={dayRates(selectedDate)}
                    setDayRate={setDayRateF(provider,product,selectedDate)}
                />||'Select a calendar day to view and modify rates.'}
            </div>
        </>}
    </>} />)
}

export function RateDurations({dayRate={}, setDayRate}){
    const durations = Object.keys(dayRate).map(dur => parseInt(dur))
    const isUnset = [undefined,null].map(v=>typeof v).includes(typeof dayRate)
    const hasRates = Object.entries(dayRate??{}).length
    return <Group>
        <Group dir='column' style={{
            flexDirection: 'column',
            borderRight: '1px solid black',
            paddingRight: 10,
        }}>
            {!hasRates && !isUnset && 'No rates for this day.'}

            {Object.entries(dayRate).map(([duration, rate], i)=>{
                return <Group dir='row' key={duration}>
                    <NumberInput
                        defaultValue={5}
                        value={parseInt(duration)}
                        onChange={newDuration => setDayRate((dayRates={}) => {
                            if(isNaN(parseInt(newDuration as any))) return dayRates // newDuration = 1 
                            return {
                                ...dayRates,
                                [duration]: null, // unset the old rate
                                [newDuration]: dayRates[duration], // move it to the new duration being set
                            }
                        })}
                        max={(durations[i+1]??366)-1} // cannot set a duration above the next duration range, this would cause 2 entries to set rate for same duration on selected day
                        min={Math.max(0, (durations[i-1]??0)+1)} // as above, prevent this input setting the rate for a duration already set by the previous (lower) entry
                        radius="xs"
                        size="xs"
                        style={{
                            width:100,
                        }}
                    /> days at 
                    <NumberInput
                        defaultValue={39800/100}
                        value={dayRate[duration]/100}
                        precision={2}
                        onChange={rate => setDayRate((dayRates={}) => {
                            if(isNaN(parseInt(rate as any))) rate = 1
                            return {
                                ...dayRates,
                                [duration]: (rate as any)*100,
                            }
                        })}
                        min={0}
                        radius="xs"
                        size="xs"
                        style={{
                            width:100,
                        }}
                    />/d.
                    <ActionIcon
                        variant="outline"
                        size="sm"
                        color="red"
                        onClick={() => setDayRate((dayRates={}) => {
                            return {
                                ...dayRates,
                                [duration]: null,
                            }
                        })}
                        style={{
                            height: 'auto',
                            width: 10,
                        }}
                    >X</ActionIcon>
                </Group>
            })}
        </Group>
        <Group>
            {!Object.entries(dayRate).length && 'Add rates for this day:' }
            <ActionIcon
                size="sm"
                variant="outline"
                color="green"
                onClick={() => setDayRate((dayRates={}) => {
                    const max = Math.max(0,...durations)+1
                    return {
                        ...dayRates,
                        [max]: 100_00,
                    }
                })}
                style={{
                    height: 'auto',
                    width: 10,
                }}
            >+</ActionIcon>
{/* 
            {<ActionIcon
                size="sm"
                variant="outline"
                color="red"
                onClick={() => setDayRate((dayRates={}) => {
                    const max = Math.max(0,...durations)+1
                    return {
                        ...dayRates,
                        [max]: 100_00,
                    }
                })}
                style={{
                    height: 'auto',
                    width: 10,
                }}
            >X</ActionIcon>} */}
        </Group>
    </Group>
}

export const getPrecedingDate = (vehicleRates:ProductRates,target:number,allowEqual=false) => Object.keys(vehicleRates??{}).reduce((best, next)=>{
    const Next = parseInt(next)
    if(Next == target && allowEqual) return Next
    if(Next>best && Next<target) return Next
    return best
},0) // 0 means unavailable