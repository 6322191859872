import { Global } from '@mantine/core'

export function GlobalStyles() {
  return (
    <Global
      styles={(theme) => ({
        '*, *::before, *::after': {
          boxSizing: 'border-box',
        },

        html: {
          minHeight: '100vh',
        },

        body: {
          minHeight: '100vh',
          margin: 0,
          color: theme.colors.gray[9],
        },

        '#root': {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        },

        // Style basic anchors
        a: {
          color: theme.colors.gray[9],
          textDecoration: 'underline',
          ':hover': {
            color: theme.colors.gray[9],
            textDecoration: 'none',
          },
        },
      })}
    />
  )
}
