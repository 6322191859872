import React from 'react'
import { Link } from 'react-router-dom'
import { Space } from '@mantine/core'

import { ScreenCenterContainer } from '../components/ScreenCenterContainer/ScreenCenterContainer'
import { Box } from '../components/Box/Box'
import { TextInput } from '../components/TextInput/TextInput'
import { Anchor } from '../components/Anchor/Anchor'
import { Button } from '../components/Button/Button'

import iconBus from '../assets/images/icon-bus.svg'

export function LoginLoginPage({
  boxContent = LoginLoginPage.BoxContent({}),
}: {
  boxContent?: React.ReactNode
}) {
  return (
    <ScreenCenterContainer>
      <Box styleType="login">{boxContent}</Box>
    </ScreenCenterContainer>
  )
}

/*

██████╗  ██████╗ ██╗  ██╗     ██████╗ ██████╗ ███╗   ██╗████████╗███████╗███╗   ██╗████████╗
██╔══██╗██╔═══██╗╚██╗██╔╝    ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔════╝████╗  ██║╚══██╔══╝
██████╔╝██║   ██║ ╚███╔╝     ██║     ██║   ██║██╔██╗ ██║   ██║   █████╗  ██╔██╗ ██║   ██║   
██╔══██╗██║   ██║ ██╔██╗     ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══╝  ██║╚██╗██║   ██║   
██████╔╝╚██████╔╝██╔╝ ██╗    ╚██████╗╚██████╔╝██║ ╚████║   ██║   ███████╗██║ ╚████║   ██║   
╚═════╝  ╚═════╝ ╚═╝  ╚═╝     ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚══════╝╚═╝  ╚═══╝   ╚═╝   
                                                                                            
*/

LoginLoginPage.BoxContent = function ({
  image = <img src={iconBus} alt="Ratpack Travel" style={{ maxWidth: 74 }} />,
  usernameInput = (
    <TextInput
      styleType="custom"
      placeholder="Username or email"
      style={{ minWidth: 353 }}
      styles={{
        input: {
          fontSize: '16px',
        },
      }}
    />
  ),
  passwordInput = (
    <TextInput
      styleType="custom"
      placeholder="Password"
      type="password"
      style={{ width: '100%' }}
      styles={{
        rightSection: {
          width: 130,
        },
        input: {
          fontSize: '16px',
        },
      }}
      rightSection={
        <Anchor styleType="light-small" component={Link} to="/forgot-password">
          Forgot password
        </Anchor>
      }
    />
  ),
  submitButton = (
    <Button
      component={Link}
      to="/loading"
      styleType="black"
      style={{ width: '100%' }}
    >
      Submit
    </Button>
  ),
  bottomContent = (
    <div>
      <span>Don't have an account? </span>
      <Anchor styleType="light" component={Link} to="/sign-up">
        Request here
      </Anchor>
    </div>
  ),
}: {
  image?: React.ReactNode
  usernameInput?: React.ReactNode
  passwordInput?: React.ReactNode
  submitButton?: React.ReactNode
  bottomContent?: React.ReactNode
}) {
  return (
    <>
      {image}
      <Space h={40} />
      {usernameInput}
      {passwordInput}
      <Space h={10} />
      {submitButton}
      <Space h={20} />
      {bottomContent}
    </>
  )
}
