import React from 'react'
import { createStyles } from '@mantine/core'
import {
  DatePicker as MDatePicker,
  DatePickerProps as MDatePickerProps,
} from '@mantine/dates'

const useRootStyles = createStyles((theme, _params) => {
  return {
    custom: {},

    black: {
      width: '100%',
    },
  }
})

const useInputStyles = createStyles((theme, _params) => {
  return {
    custom: {},

    black: {
      backgroundColor: theme.colors.brandBlack[6],
      color: theme.white,
      '&:hover': {
        backgroundColor: theme.colors.brandBlack[5],
      },
    },
  }
})

export type DatePickerProps = MDatePickerProps & {
  styleType?: 'custom' | 'black'
}

export function DatePicker({ styleType = 'black', ...props }: DatePickerProps) {
  const { classes: rootClasses } = useRootStyles()
  const { classes: inputClasses } = useInputStyles()

  return (
    <MDatePicker
      classNames={{
        root: rootClasses[styleType],
        input: inputClasses[styleType],
      }}
      {...props}
    >
      {props.children}
    </MDatePicker>
  )
}
