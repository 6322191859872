import React from 'react'
import { Accordion, AccordionItem, createStyles } from '@mantine/core'
import { WithChildren, WithExtras } from '../../util/ComponentUtil'

type ExpandCollapseStyleProps = {
  hasChildren: boolean
}

const useStyles = createStyles(
  (theme, { hasChildren }: ExpandCollapseStyleProps) => {
    return {
      component: {
        maxWidth: 300,
      },

      label: {},

      accordionControl: {
        padding: 0,
        ':hover': {
          backgroundColor: 'transparent',
        },
      },

      accordionLabel: {
        flexGrow: 0,
        flexBasis: 'content',
      },

      accordionIcon: {
        justifyContent: 'flex-start',
        marginLeft: 0,
        marginRight: 'auto',
      },

      accordionItem: {
        borderBottom: 'none',
      },

      accordionContentInner: {
        padding: hasChildren ? '5px 0 5px 0' : '0',
      },
    }
  }
)

export type ExpandCollapseProps = WithChildren<
  WithExtras<
    {
      label: React.ReactNode
    },
    {
      accordion: typeof Accordion
      accordionItem: typeof AccordionItem
    }
  >
>

export function ExpandCollapse({
  label,
  children,
  extras,
}: ExpandCollapseProps) {
  const { classes } = useStyles({ hasChildren: !!children })

  return (
    <Accordion
      className={classes.component}
      iconPosition="right"
      classNames={{
        control: classes.accordionControl,
        label: classes.accordionLabel,
        icon: classes.accordionIcon,
        item: classes.accordionItem,
        contentInner: classes.accordionContentInner,
      }}
      {...extras?.accordion}
    >
      <AccordionItem label={label} {...extras?.accordionItem}>
        {children}
      </AccordionItem>
    </Accordion>
  )
}
