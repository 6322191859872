import React from 'react'
import { createStyles } from '@mantine/core'

import { WithChildren } from '../../util/ComponentUtil'

const useStyles = createStyles((theme, _params) => {
  return {
    login: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '30px 40px 30px 40px',
      border: '1px solid #F5F5F5',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },

    translucent: {
      padding: '30px 25px 30px 25px',
      backgroundColor: 'rgba(245, 245, 245, 0.7)',
    },
  }
})

export type BoxProps = React.ComponentPropsWithRef<'div'> &
  WithChildren<{
    styleType: 'login' | 'translucent'
  }>

export function Box({ styleType, children, ...props }: BoxProps) {
  const { classes } = useStyles()

  return (
    <div className={classes[styleType]} {...props}>
      {children}
    </div>
  )
}
