import React from 'react'
import { createStyles, Image } from '@mantine/core'
import { WithChildren } from '../../util/ComponentUtil'

import { Anchor, AnchorProps } from '../Anchor/Anchor'
import iconWhatsAppLight from '../../assets/images/icon-whatsapp-light.svg'

const useStyles = createStyles((theme, _params) => {
  return {
    bubble: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 44,
      height: 44,
      position: 'fixed',
      right: 20,
      bottom: 20,
      color: theme.white,
      backgroundColor: theme.colors.brandBlack[6],
      borderRadius: 999,
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
      zIndex: 1000,
      cursor: 'pointer',
    },

    box: {
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: 250,
      height: 229,
      position: 'fixed',
      right: 20,
      bottom: 20,
      color: theme.white,
      backgroundColor: theme.colors.brandBlack[6],
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      zIndex: 1000,
      padding: 23,
    },

    line: {
      width: '100%',
      border: '1px solid #706F6F',
      margin: '20px 0',
    },

    linksContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 20,
    },

    linkContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },

    linkImageContainer: {
      width: 37,
      height: 37,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#484747',
      borderRadius: '100%',
    },

    linkImage: {
      width: 21,
      height: 21,
    },
  }
})

export type SocialBubbleProps = React.ComponentPropsWithRef<'div'> &
  WithChildren<{
    links?: React.ReactNode
  }>

export function SocialBubble({ links, children, ...props }: SocialBubbleProps) {
  const { classes } = useStyles()
  const [show, setShow] = React.useState(false)

  return (
    <>
      <div className={classes.bubble} onClick={() => setShow(true)} {...props}>
        ?
      </div>
      <div
        className={classes.box}
        style={{ ...(show ? { display: 'flex' } : {}) }}
      >
        <div
          style={{
            position: 'absolute',
            top: 5,
            right: 5,
            width: 20,
            height: 20,
            cursor: 'pointer',
          }}
          onClick={() => setShow(false)}
        >
          🗙
        </div>
        {children}
        <hr className={classes.line} />
        <div className={classes.linksContainer}>{links}</div>
      </div>
    </>
  )
}

SocialBubble.Link = function ({
  text = 'Whatsapp',
  image = <Image src={iconWhatsAppLight} />,
  ...rest
}: {
  text?: React.ReactNode
  image?: React.ReactNode
} & AnchorProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { classes } = useStyles()

  return (
    <Anchor key={text?.toString()} styleType="white" {...rest}>
      <div className={classes.linkContent}>
        <div className={classes.linkImageContainer}>
          <div className={classes.linkImage}>{image}</div>
        </div>
        {text}
      </div>
    </Anchor>
  )
}
