import React from 'react'
import { Grid, InputWrapper, Space } from '@mantine/core'

import { PageContentContainer } from '../components/PageContentContainer/PageContentContainer'
import { Box } from '../components/Box/Box'
import { TextInput } from '../components/TextInput/TextInput'

import backgroundRoad from '../assets/images/background-road.jpg'
import { Select } from '../components/Select/Select'
import { DatePicker } from '../components/DatePicker/DatePicker'
import { ExpandCollapse } from '../components/ExpandCollapse/ExpandCollapse'
import { Checkbox } from '../components/Checkbox/Checkbox'
import { Button } from '../components/Button/Button'

export function AdminCreateQuotePage({
  backgroundImage = backgroundRoad,
  topSpace = <Space h={50} />,
  topBox = AdminCreateQuotePage.TopBox({}),
  middleSpace = <Space h={25} />,
  bottomBox = AdminCreateQuotePage.BottomBox({}),
  searchArea = AdminCreateQuotePage.SearchArea({}),
}: {
  backgroundImage?: string
  topSpace?: React.ReactNode
  topBox?: React.ReactNode
  middleSpace?: React.ReactNode
  bottomBox?: React.ReactNode
  searchArea?: React.ReactNode
}) {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom center',
        flexGrow: 1,
        fontSize: '16pt',
      }}
    >
      <PageContentContainer>
        {topSpace}
        {topBox}
        {middleSpace}
        {bottomBox}
        {searchArea}
      </PageContentContainer>
    </div>
  )
}

/*

████████╗ ██████╗ ██████╗     ██████╗  ██████╗ ██╗  ██╗
╚══██╔══╝██╔═══██╗██╔══██╗    ██╔══██╗██╔═══██╗╚██╗██╔╝
   ██║   ██║   ██║██████╔╝    ██████╔╝██║   ██║ ╚███╔╝ 
   ██║   ██║   ██║██╔═══╝     ██╔══██╗██║   ██║ ██╔██╗ 
   ██║   ╚██████╔╝██║         ██████╔╝╚██████╔╝██╔╝ ██╗
   ╚═╝    ╚═════╝ ╚═╝         ╚═════╝  ╚═════╝ ╚═╝  ╚═╝
                                                                                                                                               
*/

AdminCreateQuotePage.TopBox = function ({
  firstNameInput = <TextInput placeholder="First Name" label="Customer Name" />,
  lastNameInput = <TextInput placeholder="Last Name" />,
  bookingRefInput = <TextInput placeholder="#12345" label="RPT Booking Ref" />,
  tripNameInput = <TextInput placeholder="Description" label="Trip Name" />,
}: {
  firstNameInput?: React.ReactNode
  lastNameInput?: React.ReactNode
  bookingRefInput?: React.ReactNode
  tripNameInput?: React.ReactNode
}) {
  return (
    <Box styleType="translucent">
      <Grid gutter={30}>
        <Grid.Col span={12} md={5}>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            {firstNameInput}
            {lastNameInput}
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={3}>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            {bookingRefInput}
          </div>
        </Grid.Col>
        <Grid.Col span={0} md={4}></Grid.Col>
        <Grid.Col span={12} md={5}>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            {tripNameInput}
          </div>
        </Grid.Col>
        <Grid.Col span={0} md={7}></Grid.Col>
      </Grid>
    </Box>
  )
}

/*

██████╗  ██████╗ ████████╗████████╗ ██████╗ ███╗   ███╗    ██████╗  ██████╗ ██╗  ██╗
██╔══██╗██╔═══██╗╚══██╔══╝╚══██╔══╝██╔═══██╗████╗ ████║    ██╔══██╗██╔═══██╗╚██╗██╔╝
██████╔╝██║   ██║   ██║      ██║   ██║   ██║██╔████╔██║    ██████╔╝██║   ██║ ╚███╔╝ 
██╔══██╗██║   ██║   ██║      ██║   ██║   ██║██║╚██╔╝██║    ██╔══██╗██║   ██║ ██╔██╗ 
██████╔╝╚██████╔╝   ██║      ██║   ╚██████╔╝██║ ╚═╝ ██║    ██████╔╝╚██████╔╝██╔╝ ██╗
╚═════╝  ╚═════╝    ╚═╝      ╚═╝    ╚═════╝ ╚═╝     ╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═╝
                                                                                    
*/

AdminCreateQuotePage.BottomBox = function ({
  pickUpLocationSelect = (
    <Select
      label="Pick up location"
      placeholder="Select"
      data={[
        { value: 'Item 1', label: 'Item 1' },
        { value: 'Item 2', label: 'Item 2' },
        { value: 'Item 3', label: 'Item 3' },
      ]}
    ></Select>
  ),
  dropOffLocationSelect = (
    <Select
      label="Drop off location"
      placeholder="Select"
      data={[
        { value: 'Item 1', label: 'Item 1' },
        { value: 'Item 2', label: 'Item 2' },
        { value: 'Item 3', label: 'Item 3' },
      ]}
    ></Select>
  ),
  pickUpDatePicker = <DatePicker placeholder="Select" label="Pick up date" />,
  dropOffDatePicker = <DatePicker placeholder="Select" label="Drop off date" />,
  numberOfPeopleSelect = (
    <Select
      label="Number of people"
      placeholder="Select"
      data={[
        { value: 'Item 1', label: 'Item 1' },
        { value: 'Item 2', label: 'Item 2' },
        { value: 'Item 3', label: 'Item 3' },
      ]}
    ></Select>
  ),
  filtersExpandCollapse = <ExpandCollapse label="Filters" />,
  filtersArea = AdminCreateQuotePage.FiltersArea({}),
}: {
  pickUpLocationSelect?: React.ReactNode
  dropOffLocationSelect?: React.ReactNode
  pickUpDatePicker?: React.ReactNode
  dropOffDatePicker?: React.ReactNode
  numberOfPeopleSelect?: React.ReactNode
  filtersExpandCollapse?: React.ReactNode
  filtersArea?: React.ReactNode
}) {
  return (
    <Box styleType="translucent">
      <Grid gutter={30}>
        <Grid.Col span={12} md={5}>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            {pickUpLocationSelect}
            {dropOffLocationSelect}
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={5}>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            {pickUpDatePicker}
            {dropOffDatePicker}
          </div>
        </Grid.Col>
        <Grid.Col span={12} md={2}>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            {numberOfPeopleSelect}
          </div>
        </Grid.Col>
        <Grid.Col span={12}>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            {filtersExpandCollapse}
          </div>
          <hr />
        </Grid.Col>
      </Grid>
      {filtersArea}
    </Box>
  )
}

/*

███████╗██╗██╗  ████████╗███████╗██████╗ ███████╗     █████╗ ██████╗ ███████╗ █████╗ 
██╔════╝██║██║  ╚══██╔══╝██╔════╝██╔══██╗██╔════╝    ██╔══██╗██╔══██╗██╔════╝██╔══██╗
█████╗  ██║██║     ██║   █████╗  ██████╔╝███████╗    ███████║██████╔╝█████╗  ███████║
██╔══╝  ██║██║     ██║   ██╔══╝  ██╔══██╗╚════██║    ██╔══██║██╔══██╗██╔══╝  ██╔══██║
██║     ██║███████╗██║   ███████╗██║  ██║███████║    ██║  ██║██║  ██║███████╗██║  ██║
╚═╝     ╚═╝╚══════╝╚═╝   ╚══════╝╚═╝  ╚═╝╚══════╝    ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝
                                                                                     
*/

AdminCreateQuotePage.FiltersArea = function ({
  discountSelect = (
    <Select
      label="RPT % Discount"
      placeholder="All discounts"
      data={[
        { value: 'Item 1', label: 'Item 1' },
        { value: 'Item 2', label: 'Item 2' },
        { value: 'Item 3', label: 'Item 3' },
      ]}
    ></Select>
  ),
  offerEndsSelect = (
    <Select
      label="Offer Ends"
      placeholder="All offers"
      data={[
        { value: 'Item 1', label: 'Item 1' },
        { value: 'Item 2', label: 'Item 2' },
        { value: 'Item 3', label: 'Item 3' },
      ]}
    ></Select>
  ),
  operatorsCheckboxGroup = (
    <InputWrapper label="Operators">
      <Checkbox label="Apollo" />
      <Checkbox label="Camperman" />
      <Checkbox label="Cheapa" />
      <Checkbox label="Hippie" />
      <Checkbox label="Jucy" />
      <Checkbox label="Lets Go" />
      <Checkbox label="Spaceships" />
      <Checkbox label="Star RV" />
      <Checkbox label="Travellers Autobarn" />
    </InputWrapper>
  ),
  specOptionsCheckboxGroup = (
    <InputWrapper label="Spec Options">
      <Checkbox label="Auto" />
      <Checkbox label="Toilet &amnp; Shower" />
      <Checkbox label="Anchor Points: 1" />
      <Checkbox label="Anchor Points: 2" />
    </InputWrapper>
  ),
  camperTypeCheckboxGroup = (
    <InputWrapper label="Spec Options">
      <Checkbox label="Sleeper" />
      <Checkbox label="Low Top" />
      <Checkbox label="Hi Top" />
      <Checkbox label="Motorhome" />
      <Checkbox label="4x4" />
    </InputWrapper>
  ),
}: {
  discountSelect?: React.ReactNode
  offerEndsSelect?: React.ReactNode
  operatorsCheckboxGroup?: React.ReactNode
  specOptionsCheckboxGroup?: React.ReactNode
  camperTypeCheckboxGroup?: React.ReactNode
}) {
  return (
    <Grid gutter={30}>
      <Grid.Col span={12} md={5}>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          {discountSelect}
          {offerEndsSelect}
        </div>
      </Grid.Col>
      <Grid.Col span={12} md={7}>
        <Grid gutter={30}>
          <Grid.Col span={12} md={4}>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              {operatorsCheckboxGroup}
            </div>
          </Grid.Col>
          <Grid.Col span={12} md={4}>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              {specOptionsCheckboxGroup}
            </div>
          </Grid.Col>
          <Grid.Col span={12} md={4}>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              {camperTypeCheckboxGroup}
            </div>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  )
}

/*

███████╗███████╗ █████╗ ██████╗  ██████╗██╗  ██╗     █████╗ ██████╗ ███████╗ █████╗ 
██╔════╝██╔════╝██╔══██╗██╔══██╗██╔════╝██║  ██║    ██╔══██╗██╔══██╗██╔════╝██╔══██╗
███████╗█████╗  ███████║██████╔╝██║     ███████║    ███████║██████╔╝█████╗  ███████║
╚════██║██╔══╝  ██╔══██║██╔══██╗██║     ██╔══██║    ██╔══██║██╔══██╗██╔══╝  ██╔══██║
███████║███████╗██║  ██║██║  ██║╚██████╗██║  ██║    ██║  ██║██║  ██║███████╗██║  ██║
╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝    ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝
                                                                                                                                                                                                                               
*/

AdminCreateQuotePage.SearchArea = function ({
  searchButton = <Button>Search</Button>,
}: {
  searchButton?: React.ReactNode
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Space h={30} />
      {searchButton}
      <Space h={100} />
    </div>
  )
}
