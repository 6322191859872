import React from 'react'
import { Group, Avatar, Select, Space, Image } from '@mantine/core'

import { PageContentContainer } from '../components/PageContentContainer/PageContentContainer'
import { TwoContentWrapContainer } from '../components/TwoContentWrapContainer/TwoContentWrapContainer'
import { LabelledValue } from '../components/LabelledValue/LabelledValue'
import { ExpandCollapse } from '../components/ExpandCollapse/ExpandCollapse'
import { Hero } from '../components/Hero/Hero'
import { HeroLabelledValue } from '../components/HeroLabelledValue/HeroLabelledValue'
import { Rating } from '../components/Rating/Rating'
import { Anchor, AnchorProps } from '../components/Anchor/Anchor'
import { ItemBox, ItemBoxProps } from '../components/ItemBox/ItemBox'
import { Modal } from '../components/Modal/Modal'
import { Text } from '../components/Text/Text'
import { Button } from '../components/Button/Button'
import { SocialBubble } from '../components/SocialBubble/SocialBubble'

import avatar1 from '../assets/images/avatar-1.png'
import hero1Large from '../assets/images/hero-1-large.jpg'
import hero1Small from '../assets/images/hero-1-small.jpg'
import iconBaby from '../assets/images/icon-baby.svg'
import iconWhatsAppLight from '../assets/images/icon-whatsapp-light.svg'
import iconFbMessengerLight from '../assets/images/icon-fb-messenger-light.svg'

export function PublicQuotePage({
  topBlock = PublicQuotePage.TopBlock({}),
  heroBlock = PublicQuotePage.HeroBlock({}),
  reviewsBlock = PublicQuotePage.ReviewsBlock({}),
  shareSortBlock = PublicQuotePage.ShareSortBlock({}),
  itemsBlock = PublicQuotePage.ItemsBlock({}),
  bookingModal = PublicQuotePage.BookingModal({}),
  reviewsModal = PublicQuotePage.ReviewsModal({}),
  socialBubble = (
    <SocialBubble
      links={[
        SocialBubble.Link({
          text: 'Whatsapp',
          image: <Image src={iconWhatsAppLight} />,
          href: 'https://www.whatsapp.com/',
        }),
        SocialBubble.Link({
          text: 'Facebook',
          image: <Image src={iconFbMessengerLight} />,
          href: 'https://www.messenger.com/',
        }),
      ]}
    >
      Confused about the options we have sent you - or need to modify your
      itinerary?
      <br />
      Continue your chat below.
      <br />
    </SocialBubble>
  ),
}: {
  topBlock?: React.ReactNode
  heroBlock?: React.ReactNode
  reviewsBlock?: React.ReactNode
  shareSortBlock?: React.ReactNode
  itemsBlock?: React.ReactNode
  bookingModal?: React.ReactNode
  reviewsModal?: React.ReactNode
  socialBubble?: React.ReactNode
}) {
  return (
    <>
      {topBlock}
      {heroBlock}
      {reviewsBlock}
      {shareSortBlock}
      {itemsBlock}
      {bookingModal}
      {reviewsModal}
      {socialBubble}
    </>
  )
}

/*

████████╗ ██████╗ ██████╗ 
╚══██╔══╝██╔═══██╗██╔══██╗
   ██║   ██║   ██║██████╔╝
   ██║   ██║   ██║██╔═══╝ 
   ██║   ╚██████╔╝██║     
   ╚═╝    ╚═════╝ ╚═╝                               

*/

PublicQuotePage.TopBlock = function ({
  content = PublicQuotePage.TopBlockContent({}),
}: {
  content?: React.ReactNode
}) {
  return <PageContentContainer>{content}</PageContentContainer>
}

PublicQuotePage.TopBlockContent = function ({
  leftContent = <Avatar src={avatar1} size={64} radius={999} />,
  rightContent = PublicQuotePage.TopBlockInnerRightContent({}),
  bottomContent = undefined,
}: {
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
  bottomContent?: React.ReactNode
}) {
  return (
    <>
      <Group noWrap>
        {leftContent}
        {rightContent}
      </Group>
      {bottomContent ? <Group noWrap>{bottomContent}</Group> : null}
    </>
  )
}

PublicQuotePage.TopBlockInnerRightContent = function ({
  leftContent = (
    <>
      <LabelledValue label="Your RPT Specialist">Mikey</LabelledValue>
      <LabelledValue label="Booking Ref">#12345</LabelledValue>
    </>
  ),
  rightContent = <ExpandCollapse label="Pricing Guarantee" />,
}: {
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
}) {
  return (
    <TwoContentWrapContainer
      leftContent={leftContent}
      rightContent={rightContent}
    />
  )
}

/*

██╗  ██╗███████╗██████╗  ██████╗ 
██║  ██║██╔════╝██╔══██╗██╔═══██╗
███████║█████╗  ██████╔╝██║   ██║
██╔══██║██╔══╝  ██╔══██╗██║   ██║
██║  ██║███████╗██║  ██║╚██████╔╝
╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝ ╚═════╝                        

*/

PublicQuotePage.HeroBlock = function ({
  largeImageSrc = hero1Large,
  smallImageSrc = hero1Small,
  labels = {
    'Personalised for': 'Huw Morris - East Coast Road Trip',
    'Pick up': 'Cairns, 28 May 2022',
    'Drop off': 'Brisbane, 10 June 2022',
    Duration: '19 Days',
  },
}: {
  largeImageSrc?: string
  smallImageSrc?: string
  labels?: Record<string, string>
}) {
  return (
    <Hero largeImageSrc={largeImageSrc} smallImageSrc={smallImageSrc}>
      {Object.entries(labels).map(([k, v]) => (
        <HeroLabelledValue label={k} key={k}>
          {v}
        </HeroLabelledValue>
      ))}
    </Hero>
  )
}

/*

██████╗ ███████╗██╗   ██╗██╗███████╗██╗    ██╗███████╗
██╔══██╗██╔════╝██║   ██║██║██╔════╝██║    ██║██╔════╝
██████╔╝█████╗  ██║   ██║██║█████╗  ██║ █╗ ██║███████╗
██╔══██╗██╔══╝  ╚██╗ ██╔╝██║██╔══╝  ██║███╗██║╚════██║
██║  ██║███████╗ ╚████╔╝ ██║███████╗╚███╔███╔╝███████║
╚═╝  ╚═╝╚══════╝  ╚═══╝  ╚═╝╚══════╝ ╚══╝╚══╝ ╚══════╝

*/

PublicQuotePage.ReviewsBlock = function ({
  content = PublicQuotePage.ReviewsBlockAnchor({}),
}: {
  content?: React.ReactNode
}) {
  return (
    <PageContentContainer>
      <Space h={10} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {content}
      </div>
    </PageContentContainer>
  )
}

PublicQuotePage.ReviewsBlockAnchor = function ({
  rating = <Rating rating={4} />,
  text = <Text styleType="link">212 Google reviews</Text>,
  ...rest
}: { rating?: React.ReactNode; text?: React.ReactNode } & AnchorProps) {
  return (
    <Anchor
      styleType="black-no-underline"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
      }}
      {...rest}
    >
      {rating}
      {text}
    </Anchor>
  )
}

/*

███████╗██╗  ██╗ █████╗ ██████╗ ███████╗    ███████╗ ██████╗ ██████╗ ████████╗
██╔════╝██║  ██║██╔══██╗██╔══██╗██╔════╝    ██╔════╝██╔═══██╗██╔══██╗╚══██╔══╝
███████╗███████║███████║██████╔╝█████╗      ███████╗██║   ██║██████╔╝   ██║   
╚════██║██╔══██║██╔══██║██╔══██╗██╔══╝      ╚════██║██║   ██║██╔══██╗   ██║   
███████║██║  ██║██║  ██║██║  ██║███████╗    ███████║╚██████╔╝██║  ██║   ██║   
╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝    ╚══════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝                                                                                                                           

*/

PublicQuotePage.ShareSortBlock = function ({
  content = (
    <>
      {PublicQuotePage.ShareSortBlockShareLink({})}
      {PublicQuotePage.ShareSortBlockNumOptionsAndSortSelect({})}
    </>
  ),
  style={},
}: {
  content?: React.ReactNode,
  style?: any,
}) {
  return (
    <PageContentContainer noVerticalPadding>
      <Group position="apart" style={style}>{content}</Group>
    </PageContentContainer>
  )
}

PublicQuotePage.ShareSortBlockShareLink = function ({
  text = 'Share',
  image = (
    <Image
      style={{ display: 'inline-flex' }}
      src={iconBaby}
      width={16}
      height={16}
    />
  ),
  children,
  ...rest
}: {
  text?: React.ReactNode
  image?: React.ReactNode
} & AnchorProps) {
  return (
    <Anchor
      style={{
        display: 'inline-flex',
        gap: 5,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      styleType="black"
      {...rest}
    >
      {text}
      {image}
    </Anchor>
  )
}

PublicQuotePage.ShareSortBlockNumOptionsAndSortSelect = function ({
  numOptionsText = '3 discounted options',
  sortSelect = (
    <Select
      placeholder="Sort"
      value={'least-expensive'}
      data={[
        {
          value: 'least-expensive',
          label: 'Sort by least expensive',
        },
        { value: 'most-expensive', label: 'Sort by most expensive' },
      ]}
    />
  ),
}: {
  numOptionsText?: React.ReactNode
  sortSelect?: React.ReactNode
}) {
  return (
    <div
      style={{
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text
        styleType="light"
        sx={(theme) => ({
          [theme.fn.smallerThan('xs')]: {
            display: 'none',
          },
        })}
      >
        {numOptionsText}
      </Text>
      {sortSelect}
    </div>
  )
}

/*

██╗████████╗███████╗███╗   ███╗███████╗
██║╚══██╔══╝██╔════╝████╗ ████║██╔════╝
██║   ██║   █████╗  ██╔████╔██║███████╗
██║   ██║   ██╔══╝  ██║╚██╔╝██║╚════██║
██║   ██║   ███████╗██║ ╚═╝ ██║███████║
╚═╝   ╚═╝   ╚══════╝╚═╝     ╚═╝╚══════╝
                                                                                    
*/

PublicQuotePage.ItemsBlock = function ({
  itemBoxes = PublicQuotePage.ItemBoxes([{}, {}, {}]),
}: {
  itemBoxes?: React.ReactNode
}) {
  return <PageContentContainer>{itemBoxes}</PageContentContainer>
}

PublicQuotePage.ItemBoxes = function (configs: Array<ItemBoxProps>) {
  return configs.map((v, i) => <PublicQuotePage.ItemBox key={i} {...v} />)
}

PublicQuotePage.ItemBox = function ({
  imageCarouselContent = ItemBox.ImageCarouselContent({}),
  topInfoBasicContent = ItemBox.TopInfoBasicContent({}),
  topInfoDetailsContent = ItemBox.TopInfoDetailsContent({}),
  priceContent = ItemBox.PriceContent({}),
  bottomInfoContent = ItemBox.BottomInfoContent({}),
}: ItemBoxProps) {
  return ItemBox({
    imageCarouselContent,
    topInfoBasicContent,
    topInfoDetailsContent,
    priceContent,
    bottomInfoContent,
  })
}

/*

██████╗  ██████╗  ██████╗ ██╗  ██╗██╗███╗   ██╗ ██████╗     ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗     
██╔══██╗██╔═══██╗██╔═══██╗██║ ██╔╝██║████╗  ██║██╔════╝     ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║     
██████╔╝██║   ██║██║   ██║█████╔╝ ██║██╔██╗ ██║██║  ███╗    ██╔████╔██║██║   ██║██║  ██║███████║██║     
██╔══██╗██║   ██║██║   ██║██╔═██╗ ██║██║╚██╗██║██║   ██║    ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║     
██████╔╝╚██████╔╝╚██████╔╝██║  ██╗██║██║ ╚████║╚██████╔╝    ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗
╚═════╝  ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝ ╚═════╝     ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝
                                                                                                        
*/

PublicQuotePage.BookingModal = function ({
  content = PublicQuotePage.BookingModalContent({}),
  opened = false,
  onClose = () => {},
}: {
  content?: React.ReactNode
  opened?: boolean
  onClose?: () => void
}) {
  return (
    <Modal opened={opened} onClose={onClose}>
      {content}
    </Modal>
  )
}

PublicQuotePage.BookingModalContent = function ({
  text = (
    <Text>
      <strong>Travellers Autobarn - Kuga</strong>
      <br />
      Nice choice
      <br />
      <br />
      Mikey will go through the next steps and help you book that van.
      <br />
      <br />
      Continue your chat.
    </Text>
  ),
  textSpace = <Space h={30} />,
  whatsAppButton = <Button styleType="whatsapp">Whatsapp</Button>,
  buttonSpace = <Space h={19} />,
  facebookMessengerButton = (
    <Button styleType="facebook">Facebook Messenger</Button>
  ),
}: {
  text?: React.ReactNode
  textSpace?: React.ReactNode
  whatsAppButton?: React.ReactNode
  buttonSpace?: React.ReactNode
  facebookMessengerButton?: React.ReactNode
}) {
  return (
    <div style={{ textAlign: 'center' }}>
      {text}
      {textSpace}
      {whatsAppButton}
      {buttonSpace}
      {facebookMessengerButton}
    </div>
  )
}

/*

██████╗ ███████╗██╗   ██╗██╗███████╗██╗    ██╗███████╗    ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗     
██╔══██╗██╔════╝██║   ██║██║██╔════╝██║    ██║██╔════╝    ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║     
██████╔╝█████╗  ██║   ██║██║█████╗  ██║ █╗ ██║███████╗    ██╔████╔██║██║   ██║██║  ██║███████║██║     
██╔══██╗██╔══╝  ╚██╗ ██╔╝██║██╔══╝  ██║███╗██║╚════██║    ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║     
██║  ██║███████╗ ╚████╔╝ ██║███████╗╚███╔███╔╝███████║    ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗
╚═╝  ╚═╝╚══════╝  ╚═══╝  ╚═╝╚══════╝ ╚══╝╚══╝ ╚══════╝    ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝
                                                                                                      
*/

PublicQuotePage.ReviewsModal = function ({
  content = undefined,
  opened = false,
  onClose = () => {},
}: {
  content?: React.ReactNode
  opened?: boolean
  onClose?: () => void
}) {
  return (
    <Modal opened={opened} onClose={onClose}>
      {content}
    </Modal>
  )
}
