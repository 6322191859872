import React, { MouseEventHandler } from 'react'
import { createStyles, Modal, Loader } from '@mantine/core'
import { KeenSliderPlugin, useKeenSlider } from 'keen-slider/react'
import { WithExtras } from '../../util/ComponentUtil'

const useStyles = createStyles((theme, _params) => {
  return {
    component: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },

    sliderImage: {
      transition: 'all 200ms ease-in-out',
      objectFit: 'cover',
      objectPosition: 'center',
      ':hover': {
        transform: 'scale(1.03)',
      },
      cursor: 'pointer',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },

    modalSliderImage: {
      objectFit: 'cover',
      objectPosition: 'center',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },

    loaderContainer: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }
})

export type ImageCarouselProps = WithExtras<
  {
    images: string[]
    onClickImage?: (index: number) => void
    loader?: React.ReactNode
  },
  {
    component: 'div'
    slider: 'div'
    sliderSlide: 'div'
    sliderSlideImage: 'img'
    arrowLeft: typeof Arrow
    arrowRight: typeof Arrow
  }
>

export function ImageCarousel({
  images,
  onClickImage,
  loader = <Loader variant="dots" size="xl" color="brandBlack" />,
  extras,
}: ImageCarouselProps) {
  const { classes } = useStyles()

  // Slider
  const [sliderLoaded, setSliderLoaded] = React.useState(false)
  const [sliderRef, sliderInstanceRef] = useKeenSlider({
    initial: 0,
    loop: true,
    created() {
      setSliderLoaded(true)
    },
  })

  // Modal
  const [modalSliderLoaded, setModalSliderLoaded] = React.useState(false)
  const [modalSliderStyle, setModalSliderStyle] =
    React.useState<React.CSSProperties>({})
  const [modalSliderArrowContainerStyle, setModalSliderArrowContainerStyle] =
    React.useState<React.CSSProperties>({})
  const modalSliderPlugin: KeenSliderPlugin = (slider) => {
    const updateSize = () => {
      // Get current slide and current image
      const currentSlide = slider.slides[slider.track.details.rel]
      const currentImage = currentSlide.children[0] as HTMLImageElement

      // Wait for the image to finish loading before we try to resize
      if (!currentImage.complete) {
        currentImage.onload = () => updateSize()
      } else {
        // Resize acccording to the natural size of the image, adjusted to fit inside our screen size
        const ratio = Math.min(
          (window.innerWidth * 0.9) / currentImage.naturalWidth,
          (window.innerHeight * 0.9) / currentImage.naturalHeight
        )
        const width = currentImage.naturalWidth * ratio
        const height = currentImage.naturalHeight * ratio

        setModalSliderStyle({
          width,
          height,
          marginLeft: (window.innerWidth - width) / 2,
        })
        setModalSliderArrowContainerStyle({
          width,
          height,
          marginLeft: (window.innerWidth - width) / 2,
        })
      }
    }

    slider.on('created', updateSize)
    slider.on('slideChanged', updateSize)
    window.addEventListener('resize', () => {
      updateSize()
      slider.update()
    })
  }
  const [modalSliderRef, modalSliderInstanceRef] = useKeenSlider(
    {
      initial: 0,
      loop: true,
      created() {
        setModalSliderLoaded(true)
      },
    },
    [modalSliderPlugin]
  )
  const [modalOpened, setModalOpened] = React.useState(false)
  const [modalSliderReady, setModalSliderReady] = React.useState(false)

  React.useEffect(() => {
    sliderInstanceRef?.current?.update()
    modalSliderInstanceRef?.current?.update()
  }, [images, modalSliderInstanceRef, sliderInstanceRef])

  return (
    <div className={classes.component} {...extras?.component}>
      <div className={classes.loaderContainer}>{loader}</div>
      <div
        ref={sliderRef}
        className="keen-slider"
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        }}
        {...extras?.slider}
      >
        {images.map((image, key) => (
          <div
            key={key}
            className="keen-slider__slide"
            style={{ position: 'relative' }}
            {...extras?.sliderSlide}
          >
            <img
              className={classes.sliderImage}
              src={image}
              alt=""
              onClick={() => {
                if (onClickImage) {
                  onClickImage(key)
                } else {
                  setModalOpened(true)
                  setModalSliderReady(false)

                  // Disgusting magic timeouts
                  // This is needed because I haven't found an easy way to determine when the modal
                  // open animation has finished in mantine (I could maybe hook into some kind of
                  // transition/css end event... but I didn't want to investigate that yet)
                  setTimeout(() => {
                    modalSliderInstanceRef.current?.update()
                    modalSliderInstanceRef.current?.moveToIdx(
                      sliderInstanceRef.current?.track.details.position ?? 0,
                      true,
                      { duration: 0 }
                    )
                    setModalSliderReady(true)
                  }, 250)

                  // I'm not sure if this one is even necessary - just adding this to test if it
                  // fixes the weird issue where the images are weirdly misaligned in the modal
                  setTimeout(() => {
                    modalSliderInstanceRef.current?.update()
                  }, 500)
                }
              }}
              {...extras?.sliderSlideImage}
            />
          </div>
        ))}
      </div>
      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        hideCloseButton
        centered
        padding={0}
        size={'100%'}
        styles={{
          inner: {
            padding: 0,
          },
          modal: {
            marginLeft: '0 !important',
            backgroundColor: 'transparent',
            '&:focus': {
              boxShadow: 'none',
            },
          },
          body: {
            width: '100%',
          },
        }}
      >
        <div
          style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}
          onClick={() => setModalOpened(false)}
        />
        <div
          ref={modalSliderRef}
          className="keen-slider"
          style={{
            ...modalSliderStyle,
            opacity: modalSliderReady ? 1 : 0,
            transition: 'opacity 0.5s ease',
          }}
          {...extras?.slider}
        >
          {images.map((image, key) => (
            <div
              key={key}
              className="keen-slider__slide"
              style={{ position: 'relative' }}
              {...extras?.sliderSlide}
            >
              <img
                className={classes.modalSliderImage}
                src={image}
                alt=""
                {...extras?.sliderSlideImage}
              />
            </div>
          ))}
        </div>
        {modalSliderLoaded &&
          modalSliderInstanceRef.current &&
          modalSliderReady && (
            <div
              style={{
                ...modalSliderArrowContainerStyle,
                position: 'absolute',
                left: 0,
                top: 0,
              }}
            >
              <Arrow
                left
                onClick={(e) => {
                  e.stopPropagation()
                  modalSliderInstanceRef.current?.prev()
                }}
                {...extras?.arrowLeft}
              />

              <Arrow
                onClick={(e) => {
                  e.stopPropagation()
                  modalSliderInstanceRef.current?.next()
                }}
                {...extras?.arrowRight}
              />
            </div>
          )}
      </Modal>
      {sliderLoaded && sliderInstanceRef.current && (
        <>
          <Arrow
            left
            onClick={(e) => {
              e.stopPropagation()
              sliderInstanceRef.current?.prev()
            }}
            {...extras?.arrowLeft}
          />

          <Arrow
            onClick={(e) => {
              e.stopPropagation()
              sliderInstanceRef.current?.next()
            }}
            {...extras?.arrowRight}
          />
        </>
      )}
    </div>
  )
}

type ArrowStyleProps = {
  left: boolean
  disabled: boolean
}

const useArrowStyles = createStyles(
  (theme, { left, disabled }: ArrowStyleProps) => {
    return {
      component: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(36, 36, 36, 0.5)',
        width: 45,
        height: 45,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        ...(left
          ? {
              left: 0,
            }
          : {}),
        ...(!left
          ? {
              left: 'auto',
              right: 0,
            }
          : {}),
      },

      svg: {
        width: 30,
        height: 30,
        fill: theme.white,
        ...(disabled
          ? {
              fill: 'rgba(255, 255, 255, 0.5)',
            }
          : {}),
      },
    }
  }
)

export type ArrowProps = {
  left?: boolean
  disabled?: boolean
  onClick: MouseEventHandler<HTMLDivElement>
}

function Arrow({ left = false, disabled = false, onClick }: ArrowProps) {
  const { classes } = useArrowStyles({ left, disabled })

  return (
    <div onClick={onClick} className={classes.component}>
      <svg
        className={classes.svg}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        {left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
      </svg>
    </div>
  )
}
