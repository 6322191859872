import { Avatar, Collapse, Group, Image as MImage, Loader, LoadingOverlay, Progress, Skeleton, Space, Tooltip, UnstyledButton, Modal } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import 'keen-slider/keen-slider.min.css';
import { Option } from 'ratpack-server/src/types';
import { getLocationByKey, getOperatorByKey } from 'ratpack-server/src/types';
import 'ratpack-ui/src/app/AppImports';
import { Button } from 'ratpack-ui/src/components/Button/Button';
import { ExpandCollapse } from 'ratpack-ui/src/components/ExpandCollapse/ExpandCollapse';
import { ItemBox } from 'ratpack-ui/src/components/ItemBox/ItemBox';
import { LabelledValue } from 'ratpack-ui/src/components/LabelledValue/LabelledValue';
import { Text } from 'ratpack-ui/src/components/Text/Text';
import { PublicPageLayout } from 'ratpack-ui/src/pages/PublicPageLayout';
import { PublicQuotePage } from 'ratpack-ui/src/pages/PublicQuotePage';
import 'ratpack-ui/src/styles/Font.css';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { selector, selectorFamily, useRecoilValue } from 'recoil';
import { GET } from '../api';
import { niceDate, timeDifferenceFuture, useTime } from '../time';
import { parseQuoteDetails } from './quoteList';
import iconBaby from 'ratpack-ui/src/assets/images/icon-baby.svg'
import { Anchor } from 'ratpack-ui/src/components/Anchor/Anchor';
// import { Select } from 'ratpack-ui/src/components/Select/Select';
import { Select } from '@mantine/core';
import { Rating } from 'ratpack-ui/src/components/Rating/Rating';
import { PageContentContainer } from 'ratpack-ui/src/components/PageContentContainer/PageContentContainer';
import { MdOutlineOpenInNew } from "react-icons/md";

import iconBus from '../assets/bus.png'

import logoRatpackTravel from '../assets/logo.png'
import shareIcon from '../assets/send2.svg'
import iconFbMessenger from 'ratpack-ui/src/assets/images/icon-fb-messenger.svg'
import iconWhatsApp from 'ratpack-ui/src/assets/images/icon-whatsapp.svg'
import { LoginLoadingPage } from 'ratpack-ui/src/pages/LoginLoadingPage';
import { SocialBubble } from 'ratpack-ui/src/components/SocialBubble/SocialBubble';


import iconWhatsAppLight from 'ratpack-ui/src/assets/images/icon-whatsapp-light.svg'
import iconFbMessengerLight from 'ratpack-ui/src/assets/images/icon-fb-messenger-light.svg'

import { useClipboard } from '@mantine/hooks';
import { zdid } from '../util';


const REVIEW_URL = "https://ratpacktravel.com/reviews/"
// "https://www.google.com/search?q=ratpack+travel&rlz=1C1VDKB_en-GBGB975GB975#lrd=0x6b908f3a992a815f:0x25a8ffdf05fa13f7,1"
// "https://www.ratpacktravel.com/the-ratpack/reviews/"

export type res_quote = any
export const quoteAtom = selectorFamily<res_quote, string>({
    key: 'quote',
    get: key => ({ get }) => GET(`/api/quote/${zdid(key)}`)
})

export const SOCIAL_MESSAGE_QUESTION = (reference:string) => `Booking Ref: #${reference}

As you did not select a campervan type from the options we sent you, please leave any questions you have below: 
`
export const SOCIAL_MESSAGE_SELECTED = (reference:string, operator:string, product:string) => `_'Feel free to leave any questions below, under the campervan that you selected'_ 

Booking Ref: #${reference}

I selected the ${operator}, ${product}, please let me know the next steps:
`
export const NO_RESULTS_MESSAGE = (reference:string) => `_'It appears there was no availability for your initial search '_

Booking Ref: #${reference}
`
export const WHATSAPP_LINK = (msg = `Question about RatPack Quote`) => `https://wa.me/61490149348?lang=en&text=${encodeURIComponent(msg)}`
export const FACEBOOK_LINK = (msg = `Question about RatPack Quote`) => `https://m.me/ratpacktravel?text=${encodeURIComponent(msg)}`
export const CALENDLY_LINK = 'https://bit.ly/Call-Times'

export const RoundedButton = ({children, onClick, style={} as React.CSSProperties, ...rest}) => {
    return <UnstyledButton
        onClick={onClick}
        style={{
            backgroundColor: '#18bb67',
            color: 'white',
            borderRadius: 35,
            padding: 16,
            width: 180,
            textAlign: 'center',

            ...style,
        }}
    >{children}</UnstyledButton>
}
export const RoundedModalButton = ({children, onClick, style={} as React.CSSProperties, ...rest}) => {
    return <RoundedButton
        onClick={onClick}
        style={{
            width: '85%',
            padding: '12px 16px 20px 16px',
            height: 50,
            fontSize: 16,

            ...style,
        }}
        {...rest}
    >{children}</RoundedButton>
}

export default () => {
    const [guaranteeOpen, setGuaranteeOpen] = React.useState(false)
    const { key } = useParams()

    const quote = useRecoilValue(quoteAtom(key))
    const navigate = useNavigate()


    const IconStyle = {
        display:'inline-block',
        objectFit: 'cover',
        width: 'auto',
        height: 20,
        paddingBottom: 5,
        paddingLeft: 5,
    } as any
    const WAIcon = <MImage style={IconStyle} src={iconWhatsAppLight} />
    const FBIcon = <MImage style={IconStyle} src={iconFbMessengerLight} />

    const BOOK_A_CALL_SECTION = (reference)=>(
        <Group
            style={{
                flexDirection:'column',
                fontSize: 22,
                paddingTop: 20,
                textAlign: 'left',
            }}
            noWrap
        >
            <p style={{
                margin: '50px 0px 0px 50px',
                alignSelf: 'baseline',
            }}>Send a...</p>
            <p style={{
                fontFamily: 'Holidaze',
                fontSize: 70,
                padding: 0,
                margin: 0,
                fontStyle: 'normal',
                fontWeight: 400,
                // padding: '10px 10px 50px 10px',
            }}>DM</p>
            & we will refresh our search
            <RoundedModalButton
                style={{
                    backgroundColor: 'rgb(61, 194, 78)',
                }}
                onClick={()=>window.open(WHATSAPP_LINK(NO_RESULTS_MESSAGE(reference)), "_blank")}
            >DM us {WAIcon}</RoundedModalButton>
            <RoundedModalButton
                style={{
                    backgroundColor: 'rgb(0, 106, 255)',
                }}
                onClick={()=>window.open(FACEBOOK_LINK(NO_RESULTS_MESSAGE(reference)), "_blank")}
            >DM us {FBIcon}</RoundedModalButton>
            <div style={{height:'50px'}}></div>
            <RoundedModalButton
                style={{
                    padding: '15px 16px 20px',
                    backgroundColor: 'rgb(219, 219, 219)',
                    color: 'black',
                }}
                onClick={()=>window.open(CALENDLY_LINK, "_blank")}
            >Book a call →</RoundedModalButton>
        </Group>
    )

    if (quote.status == 'Bad Request') {
        // setTimeout(() => navigate('/'), 5000)
        return <LoadingOverlay
            visible={true}
            // loader={(<Text>{`This quote does not exist or has been removed...`}</Text>)}
            loader={BOOK_A_CALL_SECTION(`404:${key}`)}
        />
    }

    const {
        id,
        // key,
        prepared_for,
        name,
        surname,
        title,
        reference,
        offer_duration,
        pax,
        operators,
        options, // specOptions not to be confused with offer options
        types,
        discount_percent,
        discount_expiry,
        pickup,
        dropoff,
        pickup_date,
        dropoff_date,
        status,
        selection_made,
        created_at,
        by,
        days,
        duration,
        pickup_location,
        dropoff_location,
        offers,
    } = parseQuoteDetails(quote)

    const [sortModeValue, setSortMode] = React.useState('least')

    const sortModeLeast = (a, b) => a.total - b.total
    const sortModeMost = (a, b) => b.total - a.total
    const sortMode = ({
        least: sortModeLeast,
        most: sortModeMost,
    })[sortModeValue] ?? sortModeLeast
    // console.log({ offers, sortMode })


    const offerList = [...offers].map((v, i) => {
        return { ...v, i }
    })

    const [overlayPercentInterval, setOverlayPercentInterval] = React.useState(null)
    const [overlayPercent, setOverlayPercent] = React.useState(1)
    React.useEffect(() => {
        setOverlayPercentInterval(setInterval(() => setOverlayPercent(v => v + 5), 50))
    }, [])
    const [overlayOpacity, setOverlayOpacity] = React.useState(1)
    React.useEffect(() => {
        setTimeout(() => setOverlayOpacity(0), 1000)
        if (overlayPercentInterval) clearInterval(overlayPercentInterval)
    }, [])


    const [openNoResultsModal, setOpenNoResultsModal] = React.useState(false)
    
    const [imageList, setImageList] = React.useState({})

    const [bookedOpen, setBookedOpen] = React.useState(null)

    const [imagesReady, setImagesReady] = React.useState({}) // Setting true can cause images to "stack" horizontally instead of scroll (until resized)
    // const toggleImage = (img: string, to = false) => (to ?? !images.includes(img)) ? setImages(images => [...images, img]) : setImages(images => images.filter(i => i != img))

    function canLoadImage(url: string) {
        const img = new Image();
        return new Promise((a, r) => {
            img.addEventListener('load', a);
            img.addEventListener('error', r);
            img.src = url;
        })
    }

    React.useEffect(() => {
        offerList.map((option: Option & { i: number }) => {
            const possibleImages = new Array(option.imagesLink ? 10 : 0).fill(null).map((_, i) => `${option.imagesLink}/${i}.png`)
            const imagePromises = possibleImages.map(img => canLoadImage(img).then(() => img).catch(e => null))
            Promise.all(imagePromises).then(images => {
                setImageList(obj => ({ ...obj, [option.imagesLink]: images.filter(Boolean) }))
                setImagesReady(obj => ({ ...obj, [option.imagesLink]: true }))
            })
            // return [option.imagesLink, imagePromises]
        })
    }, [])

    const smallImages = useMediaQuery('(max-width: 500px)');
    const gotOffers = offers.length
    const currencyText = gotOffers ? ({
        'AU': 'Pricing in AUD',
        'NZ': 'Pricing in NZD',
    })[pickup_location.country] ?? '' : ''

    const noOptionsBody = (<div
        style={{
            margin: '0 auto 50px auto',
            backgroundColor: 'white',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.07)',
        }}
    >
        <Group style={{
            justifyContent: 'center',
            justifyItems: 'center',
            display: 'grid',
            textAlign: 'center',
        }}>
            <h3 style={{
                textAlign: 'center',
            }}>
                No options available for your dates and locations.
                <br/>
                <h1 style={{display: 'inline'}}>🤔</h1> Don't panic just yet!
            </h3>

            Our team of experts will call the camper companies to check for any last-minute cancellations.
            <br/>
            Alternatively, we can search the market for different dates & locations providing you can be flexi.

            <RoundedButton
                onClick={()=>setOpenNoResultsModal(true)}
                style={{
                    marginBottom: 30,
                }}
            >Send a DM</RoundedButton>
        </Group>
    </div>);

    //                 <Button
    //                     styleType="request"
    //                     fullWidth
    //                     onClick={() => alert('Clicked blue button')}
    //                     color="blue"
    //                 >
    //                     Request This One
    //                 </Button>

    const numOptionsText = gotOffers ? `${offers.length} Discounted Options - ${currencyText}` : ''
    const sortSelect = gotOffers ? (<Select
        placeholder="Sort"
        style={{
            minWidth: '230px'
        }}
        value={sortModeValue}
        onChange={setSortMode}
        data={[
            { value: 'least', label: 'Sort by least expensive' },
            { value: 'most', label: 'Sort by most expensive' },
        ]}
    />) : ''
    const itemsBlock = PublicQuotePage.ItemsBlock({
        itemBoxes: gotOffers ? offerList.sort(sortMode).map((option: Option & { i: number }) => {
            // console.log({ pk: option.provider_key })
            const provider = getOperatorByKey(option.provider_key)

            // "fee": 10000,
            // "daily": 21833,
            // "total": 140998,
            // "discount_total": 12619.96,
            // 2%

            const priceTextNoRounding = (cents: number, suffix = '.00') => `$${(cents / 100).toFixed(2)}`
            const priceText = (cents: number, suffix = '.00') => [`$`, ~~(cents / 100), suffix].join('')

            const pluralBooster = option.boosterSeats > 1 ? `Seats` : `Seat`
            const booster = option.boosterSeats ? [{ icon: iconBaby, text: `${option.boosterSeats} Baby / Booster ${pluralBooster}` }] : []

            const carousel = {} as any

            if (option.imagesLink) carousel.images = imageList[option.imagesLink] || [] // images
            // else default value

            return < PublicQuotePage.ItemBox key={option.i} {...({
                imageCarouselContent:
                    <Skeleton visible={!imagesReady[option.imagesLink]} style={{ height: '100%' }}>
                        {ItemBox.ImageCarouselContent({
                            overlay: ItemBox.ImageCarouselOverlay({
                                overlayIconSrc: `/images/${provider.region}.${provider.slug}.png`,
                                overlayText: '',
                                height: smallImages ? 50 * 0.65 : 50,
                                fit: 'contain',
                            }),
                            carousel: imagesReady[option.imagesLink] ? <ItemBox.ImageCarouselCarousel {...carousel} loader={null} /> : null
                        })}
                    </Skeleton>,


                topInfoBasicContent: ItemBox.TopInfoBasicContent({
                    heading: option.name,
                    info: [
                        option.pax?`${option.pax} passengers`:null,
                        option.bed,
                        option.transmission,
                    ].filter(Boolean).join(' / ').replace('Automatic', 'Auto'),
                }),
                topInfoDetailsContent: ItemBox.TopInfoDetailsContent({
                    infoList: [option.fridge, option.amenity, option.newness].filter(Boolean),
                    specsTitle: <>
                        <a href={option.specs} target="_blank">Camper Spec <MdOutlineOpenInNew /></a>
                        {option.youtubeLink && <>
                            <br />
                            <a href={option.youtubeLink} target="_blank">Video Link <MdOutlineOpenInNew /></a>
                        </>}
                    </> as any,
                    specsList: [
                        ...booster,
                    ],
                }),
                priceContent: ItemBox.PriceContent({
                    normalPrice: priceTextNoRounding(option.daily),
                    discountPrice: priceText(option.daily * ((100 - discount_percent) / 100), ''),
                    timeUnit: ' / day',
                    totalDiscountPrice: priceText(option.discount_total, ''),
                }),
                bottomInfoContent: <OfferExpiring
                    discount_expiry={discount_expiry}
                    discount_percent={discount_percent}
                    onRequest={() => setBookedOpen({
                        provider: provider.nice,
                        model: option.name,
                        text: SOCIAL_MESSAGE_SELECTED(reference, provider.nice, option.name), // `${reference}: ${option.name} from ${provider.nice}`,
                    })}
                />,
            })} />
        }) : [noOptionsBody]
    })

    const reviewsBody = (
        <Group noWrap position="center" onClick={() => window.open(REVIEW_URL)}>
            <Rating rating={5} />
            <Anchor href={REVIEW_URL}
                target="_blank" rel="noreferrer">
                {smallImages ? "1000+ Google & FB Reviews" : "1000+ Google & Facebook Reviews"}
            </Anchor>
        </Group>
        )
    
    const clipboard = useClipboard({ timeout: 2000 });
    //       <Group position="apart">{content}</Group>

    return (<>
        <div style={{
            opacity: overlayOpacity,
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 10000,
            transition: 'opacity 0.5s ease',
            transitionDelay: '2s',
            backgroundColor: 'white',
            overflowX: 'hidden',
            pointerEvents: 'none',
        }}>
            <LoginLoadingPage
                loader={null}
                imageContainer={<>
                    <img src={iconBus} alt="Ratpack Travel" style={{ maxWidth: 74 }} />
                    <Progress color="dark" radius="xs" value={overlayPercent} style={{ width: '200px' }} />
                </>}
            />
        </div>
        <Modal
            title={null}
            opened={openNoResultsModal}
            onClose={()=>setOpenNoResultsModal(false)}
            styles={{
                content:{
                    borderRadius: 30,
                    backgroundColor: 'black',
                    color: 'white',
                },
                header: {
                    display: 'none',
                },
            }}
        >
            {BOOK_A_CALL_SECTION(reference)}
        </Modal>
        <PublicPageLayout
            header={PublicPageLayout.Header({
                leftContent: <MImage src={logoRatpackTravel} style={{
                    maxWidth: `165px`,
                    margin: '10px'
                }} />,
                rightContent: (
                    <Group>
                        <a href={FACEBOOK_LINK(SOCIAL_MESSAGE_QUESTION(reference))} target="_blank" rel="noreferrer">
                            <MImage src={iconFbMessenger} />
                        </a>
                        <a href={WHATSAPP_LINK(SOCIAL_MESSAGE_QUESTION(reference))} target="_blank" rel="noreferrer">
                            <MImage src={iconWhatsApp} />
                        </a>
                    </Group>
                ),
            })}
            page={<PublicQuotePage
                socialBubble={
                    <SocialBubble
                        links={[
                            SocialBubble.Link({
                                text: 'Whatsapp',
                                image: <MImage src={iconWhatsAppLight} />,
                                href: WHATSAPP_LINK(SOCIAL_MESSAGE_QUESTION(reference)),
                            }),
                            SocialBubble.Link({
                                text: 'Facebook',
                                image: <MImage src={iconFbMessengerLight} />,
                                href: FACEBOOK_LINK(SOCIAL_MESSAGE_QUESTION(reference)),
                            }),
                        ]}
                    >
                        Confused about the options we have sent you - or need to modify your
                        itinerary?
                        <br />
                        Continue your chat below.
                        <br />
                    </SocialBubble>
                }
                bookingModal={PublicQuotePage.BookingModal({
                    opened: Boolean(bookedOpen),
                    onClose: () => setBookedOpen(null),
                    content: PublicQuotePage.BookingModalContent({
                        text: (
                            <Text>
                                <strong>{[bookedOpen?.provider, bookedOpen?.model].join(' - ')}</strong>
                                <br />
                                Nice choice
                                <br />
                                <br />
                                {by?.name ?? 'We'} will go through the next steps and help you book that van.
                                <br />
                                <br />
                                Continue your chat.
                            </Text>
                        ),
                        whatsAppButton: (<Button styleType="whatsapp" onClick={() => window.open(WHATSAPP_LINK(bookedOpen?.text ?? `RatPack booking ${reference}`), "_blank")} > Whatsapp</Button>),
                        facebookMessengerButton: (<Button styleType="facebook" onClick={() => window.open(FACEBOOK_LINK(bookedOpen?.text ?? `RatPack booking ${reference}`), "_blank")}> Facebook Messenger</Button >),
                    })
                })}
                // Hooking up pricing guarantee "button" to collapse content
                topBlock={
                    PublicQuotePage.TopBlock({
                        content: PublicQuotePage.TopBlockContent({
                            leftContent: <Avatar src={by?.avatar ?? by?.avatar ?? ''} size={64} radius={999} />,
                            rightContent: PublicQuotePage.TopBlockInnerRightContent({
                                leftContent: (<>
                                    <LabelledValue label="Your RPT Specialist">{(by?.name??'Sammy').split(' ')[0]}</LabelledValue>
                                    <LabelledValue label="Booking Ref">#{reference}</LabelledValue>
                                </>),
                                rightContent: (
                                    <ExpandCollapse
                                        label="Pricing Guarantee"
                                        extras={{
                                            accordion: {
                                                onClick: () =>
                                                    setGuaranteeOpen(!guaranteeOpen),
                                            },
                                        }}
                                    />
                                ),
                            }),
                            bottomContent: (
                                <Collapse in={guaranteeOpen}>
                                    <Space h="md" />
                                    Our service is provided FREE of charge. The rates displayed within this link are from the most reputable companies on the market.
                                    The rates displayed and availability are taken at the time of our search and can not be held until payment is made.
                                    If you have seen an alternative company or price that is not featured within this link elsewhere feel free to send it over and we will check the authenticity and reputability of the company for you.
                                    We have a price-beat guarantee. If you have seen the same option elsewhere for a cheaper rate please notify us before booking and we will check the rate for you.
                                    We have over 1000 5⭐ reviews and pride ourselves on our service.
                                </Collapse>
                            ),
                        }),
                    })
                }
                reviewsBlock={ gotOffers ? < PageContentContainer >{reviewsBody}</PageContentContainer > : ''}
                shareSortBlock={
                    PublicQuotePage.ShareSortBlock({
                        style:gotOffers?{}:{padding: '10px'},
                        content: <>
                            <Tooltip // .Floating ?
                                inline // ?
                                opened={clipboard.copied}
                                label="Copied!"
                                withArrow
                            >
                                {PublicQuotePage.ShareSortBlockShareLink({
                                    text: 'Share',
                                    image: (
                                        <MImage
                                            src={shareIcon}
                                            width={16}
                                            height={16}
                                            style={{
                                                display: 'inline-flex',
                                            }}

                                        />
                                    ),
                                    onClick: () => {
                                        if (navigator.share) {
                                            return navigator.share({
                                                title: 'RatPack Travel Quote',
                                                url: window.location.toString(),
                                            }).then(() => {
                                                console.log('Thanks for sharing!');
                                            }).catch(console.error);
                                        }
                                        if (navigator.clipboard) {
                                            return clipboard.copy(window.location.toString())
                                        }
                                        alert('Your browser does not support copying, but you can share this URL!')
                                    }
                                    // rel="noreferrer"
                                    // styleType="gray"
                                })}
                            </Tooltip>

                            {gotOffers ? <Text
                                styleType="light"
                                sx={(theme) => ({
                                    [theme.fn.largerThan('xs')]: {
                                        display: 'none',
                                    },
                                })}
                            >{currencyText}</Text> : reviewsBody}

                            {PublicQuotePage.ShareSortBlockNumOptionsAndSortSelect({
                                numOptionsText,
                                sortSelect,
                            })}
                        </>,

                    })
                }
                heroBlock={
                    PublicQuotePage.HeroBlock({
                        labels: {
                            'Personalised for': `${name} ${surname} - ${title}`,
                            'Pick up': `${pickup_location?.state}, ${niceDate(pickup_date)}`,
                            'Drop off': `${dropoff_location?.state}, ${niceDate(dropoff_date)}`,
                            Duration: duration,
                            // Adding an extra label to the header
                            // Testing: 'Success',
                        },
                    })
                }
                itemsBlock={itemsBlock}
            />}
        />
    </>)
}

export const OfferExpiring = ({ discount_percent, discount_expiry, onRequest }) => {
    useTime(1000)
    const time_remaining = timeDifferenceFuture(new Date(discount_expiry), new Date(), null)
    const offer_ends = time_remaining === null ? 'Offer Expired' : 'Offer ends'
    // '6d 2hr 43m'


    return ItemBox.BottomInfoContent({
        leftContent: ItemBox.BottomInfoBlackWhiteInfoBox({
            topText: `${discount_percent}% off Direct Rate`,
            bottomLabel: offer_ends,
            bottomText: time_remaining,
        }),
        rightContent: ItemBox.BottomInfoButton({
            onClickRequestButton: onRequest,
        }),
    })
}