import React from 'react'
import { createStyles } from '@mantine/core'

import { WithChildren } from '../../util/ComponentUtil'

const useStyles = createStyles((theme, _params) => {
  return {
    component: {
      display: 'flex',
      width: '100vw',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  }
})

export type ScreenCenterContainerProps = React.ComponentPropsWithRef<'div'> &
  WithChildren<{}>

export function ScreenCenterContainer({
  children,
  ...props
}: ScreenCenterContainerProps) {
  const { classes } = useStyles()

  return (
    <div className={classes.component} {...props}>
      {children}
    </div>
  )
}
