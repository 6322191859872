import React from 'react'
import { createStyles } from '@mantine/core'

import { WithChildren, WithExtras } from '../../util/ComponentUtil'
import { PageMaxWidthContainer } from '../PageMaxWidthContainer/PageMaxWidthContainer'

type HeroStyleProps = {
  largeImageSrc: string
  smallImageSrc: string
}

const useStyles = createStyles(
  (theme, { largeImageSrc, smallImageSrc }: HeroStyleProps) => {
    return {
      component: {
        width: '100%',
        backgroundImage: `url(${smallImageSrc})`,
        backgroundSize: 'cover',
        position: 'relative',

        [theme.fn.largerThan('sm')]: {
          backgroundImage: `url(${largeImageSrc})`,
        },
      },

      fade: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundImage:
          'linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)',
      },

      innerContainer: {
        width: '100%',
        padding: '42px 4px 42px 4px',
        position: 'relative',
      },
    }
  }
)

export type HeroProps = WithChildren<
  WithExtras<
    {
      largeImageSrc: string
      smallImageSrc: string
    },
    {
      component: 'div'
      fade: 'div'
      pageMaxWidthContainer: typeof PageMaxWidthContainer
      innerContainer: 'div'
    }
  >
>

export function Hero({
  largeImageSrc,
  smallImageSrc,
  children,
  extras,
}: HeroProps) {
  const { classes } = useStyles({ largeImageSrc, smallImageSrc })

  return (
    <div className={classes.component} {...extras?.component}>
      <div className={classes.fade} {...extras?.fade}></div>
      <PageMaxWidthContainer {...extras?.pageMaxWidthContainer}>
        <div className={classes.innerContainer} {...extras?.innerContainer}>
          {children}
        </div>
      </PageMaxWidthContainer>
    </div>
  )
}
