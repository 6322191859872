
/*
// ██████╗  █████╗ ████████╗ █████╗ 
// ██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
// ██║  ██║███████║   ██║   ███████║
// ██║  ██║██╔══██║   ██║   ██╔══██║
// ██████╔╝██║  ██║   ██║   ██║  ██║
// ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝
*/

export const toSlug = (s: string, delimiter:string='_') => s.replace(/\s/ig, delimiter).toLowerCase()
export const tuple = <T extends string[]>(...args: T) => args;

/*
// ██╗      ██████╗  ██████╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
// ██║     ██╔═══██╗██╔════╝██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
// ██║     ██║   ██║██║     ███████║   ██║   ██║██║   ██║██╔██╗ ██║
// ██║     ██║   ██║██║     ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
// ███████╗╚██████╔╝╚██████╗██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
// ╚══════╝ ╚═════╝  ╚═════╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
*/

export const New_Zealand_States = tuple(
    'Auckland',
    'Christchurch',
    'Queenstown',
)
export type New_Zealand_State = typeof New_Zealand_States[number]
export const Australia_States = tuple(
    'Adelaide',
    'Alice Springs',
    'Airlie Beach',
    'Brisbane',
    'Byron Bay',
    'Cairns',
    'Darwin',
    'Gold Coast',
    'Hobart',
    'Perth',
    'Melbourne',
    'Sydney',
)
type CountryCode = "AU" | "NZ"
type LocationData = {
    key: string,
    nice: string,
    state: State,
    country: CountryCode,
}

export const All_States: LocationData[] = Object.entries({
    'NZ': New_Zealand_States,
    'AU': Australia_States,
} as Record<CountryCode, State[]>).flatMap(([country, states]: [CountryCode, State[]]) => {
    return states.map(state => ({
        key: toSlug(`${country}.${state}`),
        nice: `${state}, ${country}`,
        state,
        country,
    }))
}) // [{ key:'au.byron_bay', nice:'Byron Bay, AU', state, country }]

export type Australia_State = typeof Australia_States[number]
export type State = New_Zealand_State | Australia_State

export function isNZ(l: string): l is New_Zealand_State {
    return New_Zealand_States.includes(l as any)
}
export function isAustralia(l: string): l is Australia_State {
    return Australia_States.includes(l as any)
}
export function isState(l: string): l is State {
    return isNZ(l) || isAustralia(l)
}
export function isLocationKey(l: string): l is State {
    return All_States.some(({ key }) => key == l)
}
export function getLocationByKey(l: string): LocationData {
    return All_States.find(({ key }) => key == l)
}

/*
// ███████╗████████╗ █████╗ ████████╗██╗   ██╗███████╗
// ██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██║   ██║██╔════╝
// ███████╗   ██║   ███████║   ██║   ██║   ██║███████╗
// ╚════██║   ██║   ██╔══██║   ██║   ██║   ██║╚════██║
// ███████║   ██║   ██║  ██║   ██║   ╚██████╔╝███████║
// ╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚══════╝
*/

export const StatusList = {
    'Pending': 'pending',
    'Requote': 'requote',
    'Expired': 'expired',
    'Booked': 'booked',
    'Deleted': 'deleted',
}
export const Statuses = tuple(...Object.values(StatusList))
export const StatusesNice = tuple(...Object.keys(StatusList))
export type Status = typeof Statuses[number]

export function isStatus(s: string): s is Status {
    return Statuses.includes(s as any)
}

export const HR = 60 * 60 * 1000
export const DAY = 24 * HR
export const DEFAULT_DISCOUNT_WINDOW = 7 * DAY

/*
//  ██████╗ ██████╗ ████████╗██╗ ██████╗ ███╗   ██╗███████╗
// ██╔═══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
// ██║   ██║██████╔╝   ██║   ██║██║   ██║██╔██╗ ██║███████╗
// ██║   ██║██╔═══╝    ██║   ██║██║   ██║██║╚██╗██║╚════██║
// ╚██████╔╝██║        ██║   ██║╚██████╔╝██║ ╚████║███████║
//  ╚═════╝ ╚═╝        ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
*/

// Amenity

export const NoToiletShower = `No Toilet & Shower`
export const ToiletNoShower = `Toilet* & No Shower`
export const ToiletShower = `Toilet & Shower`
export const AmenityRequested = `Requested with company`
export const Amenity = tuple(
    NoToiletShower,
    ToiletNoShower,
    ToiletShower,
    AmenityRequested,
)
export type AmenityOption = typeof Amenity[number]

// Fridge

export const Fridge = `Fridge`
export const NoFridge = `No fridge`
export const FridgeOptions = tuple(
    Fridge,
    NoFridge,
)
export type FridgeOption = typeof FridgeOptions[number]

// Transmission

export const Automatic = 'Automatic'
export const Manual = 'Manual'
export const TransmissionOptions = tuple(
    Automatic,
    Manual,
)
export type TransmissionOption = typeof TransmissionOptions[number]

// SpecOptions


export const SpecOptionsList = {
    'Auto': 'auto',
    'Toilet & Shower': 'toilet_shower',
    'Fridge': 'fridge',
    'Anchor Points: 1': 'anchor_1',
    'Anchor Points: 2': 'anchor_2',
}
export const SpecOptionsNice = tuple(...Object.keys(SpecOptionsList))
export const SpecOptionsSlug = tuple(...Object.values(SpecOptionsList))
export type SpecOptionNice = typeof SpecOptionsNice[number]
export type SpecOptionSlug = typeof SpecOptionsSlug[number]
export const optionList = Object.entries(SpecOptionsList).map(([nice, slug]) => ({ slug, nice }))

/*
anchor_points:_1: true
anchor_points:_2: true
auto: true
fridge: true
toilet_&_shower: true
*/

/*
// ████████╗██╗   ██╗██████╗ ███████╗███████╗
// ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝
//    ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗
//    ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║
//    ██║      ██║   ██║     ███████╗███████║
//    ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝
*/
export const VehicleTypeList = {
    Sleeper: 'sleeper',
    "Low Top": 'low_top',
    "Hi Top": 'hi_top',
    Motorhome: 'motorhome',
    '4x4': '4x4',
}


export const VehicleTypes = tuple(...Object.values(VehicleTypeList))
export const VehicleTypesNice = tuple(...Object.keys(VehicleTypeList))
//     'Sleeper', // Sleep
//     'Low Top', // LT
//     'Hi Top', // HT
//     'Motorhome', // MH
//     '4x4'      , // 4x4
// )
export type VehicleType = typeof VehicleTypes[number]
export type VehicleTypeNice = typeof VehicleTypesNice[number]
export const typeList = VehicleTypesNice.map(vehicleType => ({
    slug: toSlug(vehicleType),
    nice: vehicleType,
}))


/*
//  ██████╗ ██████╗ ███████╗██████╗  █████╗ ████████╗ ██████╗ ██████╗ ███████╗
// ██╔═══██╗██╔══██╗██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔═══██╗██╔══██╗██╔════╝
// ██║   ██║██████╔╝█████╗  ██████╔╝███████║   ██║   ██║   ██║██████╔╝███████╗
// ██║   ██║██╔═══╝ ██╔══╝  ██╔══██╗██╔══██║   ██║   ██║   ██║██╔══██╗╚════██║
// ╚██████╔╝██║     ███████╗██║  ██║██║  ██║   ██║   ╚██████╔╝██║  ██║███████║
//  ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝
*/

// import { australia, new_zealand } from "./data";
// const prefix_key = (pre = 'au.') => ([k, v]) => [`${pre}${k}`, v.name]
export const OperatorList = { // Object.fromEntries([ // TODO compute this automatically from data.ts
    // ...Object.entries(australia).map(prefix_key('au.')),
    'au.apollo': 'Apollo',
    'au.britz': 'Britz',
    'au.maui': 'Maui',
    'au.mighty': 'Mighty',
    'au.camperman': `Camperman`,
    'au.cheapa': `Cheapa`,
    'au.hippie': `Hippie`,
    'au.jucy': `Jucy`,
    'au.letsgo': `Lets Go`,
    'au.spaceships': `Spaceships`,
    'au.star-rv': `Star RV`,
    'au.travellers-autobarn': 'Travellers Autobarn',
    // ...Object.entries(new_zealand).map(prefix_key('nz.')),
    'nz.apollo': `Apollo`,
    'nz.britz': `Britz`,
    'nz.maui': `Maui`,
    'nz.mighty': `Mighty`,
    'nz.cheapa': `Cheapa`,
    'nz.hippie': `Hippie`,
    'nz.jucy': `Jucy`,
    'nz.spaceships': `Spaceships`,
    'nz.star-rv': `Star RV`,
    'nz.travellers-autobarn': `Travellers Autobarn`,
    'nz.barefoot-campers': `Barefoot Campers`,
    'nz.biglittle': `Big Little`,
// ]) 
} as const

export const AllOperators = Object.fromEntries(Object.entries(OperatorList).map(([k,v])=>[k.split('.')[1], v]))

export const OperatorsGeneric = Object.fromEntries(Object.entries(OperatorList).map(([k, v]) => [k.split('.')[1], v])) // TODO move to types and name properly
export const Operators = tuple(...Object.keys(OperatorList))
export type Operator = typeof Operators[number]
export const OperatorNames = tuple(...Object.values(OperatorList))
export type OperatorName = typeof Operators[number]

export function getOperatorByKey(l: string): { nice: string, slug: string, region: string } {
    let [region, slug] = [...l.split('.'), null]
    if (!region) return null
    if (!slug) {
        slug = region
        region = null
    }
    const nice = Object.entries(OperatorList).find(([k]) => k == l || k == slug)[1]
    return {
        nice,
        slug,
        region,
    }
}

export const multipliers:Record<keyof typeof OperatorList,number> = {
    'au.apollo': 1.25,
    "au.britz": 1,
    "au.maui": 1,
    "au.mighty": 1,
    "au.camperman": 1,
    "au.cheapa": 1.18,
    "au.hippie": 1.18,
    "au.jucy": 1,
    "au.letsgo": 1,
    "au.spaceships": 1,
    "au.star-rv": 1,
    "au.travellers-autobarn": 1,
    "nz.apollo": 1.25,
    "nz.britz": 1,
    "nz.maui": 1,
    "nz.mighty": 1,
    "nz.cheapa": 1.18,
    "nz.hippie": 1.18,
    "nz.jucy": 1,
    "nz.spaceships": 1,
    "nz.star-rv": 1,
    "nz.travellers-autobarn": 1,
    "nz.barefoot-campers": 1,
    "nz.biglittle": 1
}

/*
//  █████╗ ██████╗ ██████╗ 
// ██╔══██╗██╔══██╗██╔══██╗
// ███████║██████╔╝██████╔╝
// ██╔══██║██╔═══╝ ██╔═══╝ 
// ██║  ██║██║     ██║     
// ╚═╝  ╚═╝╚═╝     ╚═╝     
*/

export type Product = {
    id: number | string | null, // used by upstream APIs to identify products
    rp_id: string, // used in ZD field 6684723339161 to select product used in finalisation
    name: string,
    type: VehicleType,
    pax: number,
    bed: string,
    transmission: typeof Manual | typeof Automatic,
    fridge: FridgeOption,
    amenity: AmenityOption,
    newness: string,
    specs: string,
    boosterSeats: number,
    imagesLink: string,
    youtubeLink: string,
    internalDataLink: string,
    policies?: Array<InsurancePolicyOption>,
}
export type InsurancePolicyOption = {
    id: number|string,
    name: string,
    fees: number,
    totalinsuranceamount: number,
    maximumprice: number,
    excessamount: number,
}
export type Option = Product & {
    provider_key: ProviderKey,
    fee: number,
    daily: number,
    discount_total: number,
    total: number,
    policies?: Array<InsurancePolicyOption>,
    id?: string|number, // from upstream API
    rp_id?: string, // from ZD
}
export type ProviderKey = Operator
export type ProviderName = OperatorName

export const sleep = (ms=1000) => new Promise(a=>setTimeout(a,ms))
