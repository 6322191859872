import React from 'react'
import { createStyles } from '@mantine/core'
import { WithChildren } from '../../util/ComponentUtil'

const useStyles = createStyles((theme, _params) => {
  return {
    component: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      position: 'absolute',
      left: -1,
      top: -1,
      backgroundColor: theme.white,
      padding: '8px 10px 8px 10px',
      borderBottomRightRadius: 4,
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '20px',
      zIndex: 1,
      pointerEvents: 'none',
    },
  }
})

export type OverlayTagProps = React.ComponentPropsWithRef<'div'> &
  WithChildren<{}>

export function OverlayTag({ children, ...props }: OverlayTagProps) {
  const { classes } = useStyles()

  return (
    <div className={classes.component} {...props}>
      {children}
    </div>
  )
}
