import React from 'react'
import {
  Button as MButton,
  ButtonProps as MButtonProps,
  createStyles,
  MantineColor,
  MantineSize,
} from '@mantine/core'

const useRootStyles = createStyles((theme, _params) => {
  return {
    custom: {},
    black: {},
    white: {
      border: `1px solid ${theme.colors.brandBlack[6]}`,
      borderRadius: 0,
    },
    request: {},
    whatsapp: {
      width: 220,
    },
    facebook: {
      width: 220,
    },
  }
})

const useLabelStyles = createStyles((theme, _params) => {
  return {
    custom: {},
    black: {
      fontSize: '16px',
      lineHeight: '19.2px',
      color: theme.white,
      fontWeight: 400,
    },
    white: {
      fontSize: '16px',
      lineHeight: '19.2px',
      color: theme.colors.brandBlack[6],
      fontWeight: 400,
    },
    request: {
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 600,
    },
    whatsapp: {
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 600,
    },
    facebook: {
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 600,
    },
  }
})

export type ButtonProps = MButtonProps<any> & {
  styleType?: 'custom' | 'black' | 'white' | 'request' | 'whatsapp' | 'facebook'
}

export function Button({
  styleType = 'black',
  children,
  ...props
}: ButtonProps) {
  const { classes: rootClasses } = useRootStyles()
  const { classes: labelClasses } = useLabelStyles()

  let size: MantineSize = 'sm'
  let color: MantineColor | undefined = undefined

  switch (styleType) {
    case 'black':
      size = 'md'
      color = 'brandBlack'
      break
    case 'white':
      size = 'sm'
      color = 'brandWhite'
      break
    case 'request':
      size = 'lg'
      color = 'brandGreen'
      break
    case 'whatsapp':
      size = 'lg'
      color = 'brandGreen'
      break
    case 'facebook':
      size = 'lg'
      color = 'brandFacebook'
      break
  }

  return (
    <MButton
      color={color}
      size={size}
      classNames={{
        root: rootClasses[styleType],
        label: labelClasses[styleType],
      }}
      {...props}
    >
      {children}
    </MButton>
  )
}
