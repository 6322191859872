import React from 'react'
import { createStyles } from '@mantine/core'
import { WithExtras } from '../../util/ComponentUtil'

const useStyles = createStyles((theme, _params) => {
  return {
    component: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',

      [theme.fn.largerThan('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },

    leftContent: {},

    rightContent: {},
  }
})

export type TwoContentWrapContainerProps = WithExtras<
  {
    leftContent: React.ReactNode
    rightContent: React.ReactNode
  },
  {
    component: 'div'
    leftContent: 'div'
    rightContent: 'div'
  }
>

export function TwoContentWrapContainer({
  leftContent,
  rightContent,
  extras,
}: TwoContentWrapContainerProps) {
  const { classes } = useStyles()

  return (
    <div className={classes.component} {...extras?.component}>
      <div className={classes.leftContent} {...extras?.leftContent}>
        {leftContent}
      </div>
      <div className={classes.rightContent} {...extras?.rightContent}>
        {rightContent}
      </div>
    </div>
  )
}
