import { Group, Loader, LoadingOverlay, Space } from '@mantine/core';
import { InputWrapper } from '@mantine/core/./cjs/Input/InputWrapper/InputWrapper.js'
import 'keen-slider/keen-slider.min.css';
import 'ratpack-ui/src/app/AppImports';
import bgImg from 'ratpack-ui/src/assets/images/background-road.jpg';
// import { Box } from 'ratpack-ui/src/components/Box/Box';
import { Button } from 'ratpack-ui/src/components/Button/Button';
import { Checkbox } from 'ratpack-ui/src/components/Checkbox/Checkbox';
import { DatePicker } from 'ratpack-ui/src/components/DatePicker/DatePicker';
// import { ExpandCollapse } from 'ratpack-ui/src/components/ExpandCollapse/ExpandCollapse';
import { Select } from 'ratpack-ui/src/components/Select/Select';
import { TextInput } from 'ratpack-ui/src/components/TextInput/TextInput';
import { AdminCreateQuotePage } from 'ratpack-ui/src/pages/AdminCreateQuotePage';
import { AdminPageLayout } from 'ratpack-ui/src/pages/AdminPageLayout';
import 'ratpack-ui/src/styles/Font.css';
import * as React from 'react';
import { POST } from '../api';
import { MustLogIn } from '../session';
import { AU_States, NZ_States, Spaced_States, before, selected, stateful, toSelectItems } from '../util';
import { adminDefaults } from './components';
import {
    AllOperators,
    All_States, DAY, OperatorsGeneric, optionList, typeList,
    // toSlug, // TODO
} from 'ratpack-server/src/types'
import {
    OperatorList, Operators,
} from 'ratpack-server/src/types'

import { Text } from 'ratpack-ui/src/components/Text/Text';
import { useNavigate } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE } from 'recoil';
import { quotesAtom } from './quoteList';
import { AsIfParsedFromTimezone } from '../time';
import { Anchor } from 'ratpack-ui/src/components/Anchor/Anchor';
export const toSlug = (s: string, delimiter:string='_') => s.replace(/\s/ig, delimiter).toLowerCase()

export const useMultiStateBool = (list: string[] | Record<string, string>, initial = {}): [
    Record<string, any>,
    (n: string) => { value: string, onChange: Function },
    Function,
    Function,
] => {
    const kv = Array.isArray(list) ? list.map(name => [toSlug(name), name]) : Object.entries(list)
    const [values, setValues] = React.useState(initial)
    const useOne = (name: string) => ({
        value: values[name],
        onChange: () => setValues((values) => ({ ...values, [name]: !values[name] })),
    })
    // console.log({ isa: Array.isArray(list), list, kv })

    const render = (Component: (p: { key: string, value: string, onChange: Function, label: string }) => React.ReactNode) => {
        return kv.map(([key, name]) => {
            return Component({
                key,
                ...useOne(key),
                label: name
            })
        })
    }

    return [values, useOne, render, setValues]
}
const setter = (v: any) => v

export default () => {
    const navigate = useNavigate();
    const refreshQuotes = useRecoilRefresher_UNSTABLE(quotesAtom)
    const [error, setError] = React.useState('');
    const [createdFeedback, setCreatedFeedback] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const SearchButtonText = loading ? <Loader /> : 'Search'

    const [title, useTitle, validTitle, setTitle] = stateful('')
    const [name, useName, validName, setName] = stateful('')
    const [surname, useSurname, validSurname, setSurname] = stateful('')
    const [reference, useReference, validReference, setReference] = stateful('', { validate: (v:string) => !Number.isNaN(parseInt(v)) })

    const [pickup_date, usePickupDate, validPickupDate, setPickupDate] = stateful(null, { setter }) // , next: dropOffRef.click()
    const [dropoff_date, useDropoffDate, validDropoffDate, setDropoffDate] = stateful(null, { setter })

    const days = (pickup_date && dropoff_date) ? 1 + Math.round((dropoff_date - pickup_date) / DAY) : null
    const DropOffDateLabel = `Drop off date ${days !== null ? `( ${days} days )` : ''}`

    const [pickup, usePickup, validPickup, setPickup] = stateful('', { setter })
    const [dropoff, useDropoff, validDropoff, setDropoff] = stateful('', { setter })

    const [pax, usePax, validPax, setPax] = stateful('2', { setter })
    const [discount_percent, useDiscount, validDiscount, setDiscount] = stateful('5', { setter })
    const [offer_duration, useOfferEnds, validOfferEnds, setOfferEnds] = stateful('24', { setter })
    const [mode, useMode, validMode, setMode] = stateful('sync', { setter })

    // TODO
    // types.operatorData.map(o => o.name)
    // types.optionData.map(o => o.name)
    // types.typeData.map(o => o.name)
    const OperatorsGenericSorted = Object.fromEntries(Object.entries(OperatorsGeneric).sort(([a], [b]) => a.localeCompare(b)))
    const defaultOperators = Object.fromEntries(Object.keys(AllOperators).map(k=>[k,true]))
    const [operators, useOperator, renderOperators, setOperators] = useMultiStateBool(OperatorsGenericSorted, defaultOperators)

    const someOperatorsSelected = Object.keys(OperatorsGenericSorted).map(k => operators[k]).every(Boolean)
    const toggleAllOperators = () => {
        setOperators(operators => Object.fromEntries(Object.entries(OperatorsGenericSorted).map(([k, v]) => [k, !someOperatorsSelected])))
    }

    const [options, useOption, renderOptions, setOptions] = useMultiStateBool(optionList.map(item => item.nice))
    const [types, useType, renderTypes, setTypes] = useMultiStateBool(typeList.map(item => item.nice))

    const setHours = (d: Date, n: number) => {
        const D = new Date(d)
        D.setHours(n % 24)
        return D
    }

    const resetFields = () => {
        setOperators(defaultOperators)
        setTitle('')
        setName('')
        setSurname('')
        setReference('')
        setPickupDate(null)
        setDropoffDate(null)
        setPickup('')
        setDropoff('')
        setPax('2')
        setDiscount('5')
        setOfferEnds('24')
        setOptions({})
        setTypes({})
    }

    console.log({ types, options })
    const submit = async (e) => {
        if (loading) return
        setCreatedFeedback(null)
        setError('')
        setLoading(true)
        if (!selected(operators).length) {
            setError('Must set at least one provider')
            setLoading(false)
            return
        }
        const res = await POST('/api/quote', {
            prepared_for: `${name} ${surname}`,
            pickup,
            dropoff,
            discount_percent,
            dropoff_date: AsIfParsedFromTimezone(setHours(dropoff_date, 12)), // Treat any agent input as if it had come from AEDT, no matter what their timezone is.
            pickup_date: AsIfParsedFromTimezone(setHours(pickup_date, 12)), // Date inputs behave as if selecting a day inside the AEDT timezone

            name,
            surname,
            title,
            reference,

            offer_duration,
            pax,

            operators: selected(operators),
            options: selected(options),
            types: selected(types),

            mode,
        }).then(res => {
            if (!res.key) throw res
            refreshQuotes()
            // navigate()
            setReference('')
            setCreatedFeedback({ key: res.key, ref: res.reference })
            return res
        }).catch(err => {
            console.log({ err })
            setError(`${err?.message ?? err?.status ?? err}`)
        }).finally(() => setLoading(false))

        // const href = `/quote/${res.key}`
        const href = `https://bookings.ratpacktravel.com/q/${res.key}`
        window.open(href, '_blank'); // Fails if unfocussed & inside handler, see https://stackoverflow.com/questions/4907843/open-a-url-in-a-new-tab-and-not-a-new-window
        // Alternative methods:
        // Object.assign(document.createElement('a'), { target: '_blank', href }).click();
        // const tab = window.open('about:blank'); tab.location = href; tab.focus(); tab.close();
    }

    const Dropoff_States = pickup.length ? toSelectItems(pickup.startsWith('au.') ? AU_States : NZ_States) : Spaced_States


    // if (pickup && !dropoff) setDropoff(pickup)
    // if (pickup_date && !dropoff_date) setDropoffDate(pickup_date)

    return (<AdminPageLayout {...adminDefaults({ page: 'create' })} page={<>
        <MustLogIn />
        <AdminCreateQuotePage
            backgroundImage={bgImg}
            topSpace={<Space h={50} />}
            topBox={AdminCreateQuotePage.TopBox({
                firstNameInput: <TextInput {...useName} placeholder="First Name" label="Customer Name" />,
                lastNameInput: <TextInput {...useSurname} placeholder="Last Name" />,
                bookingRefInput: <TextInput {...useReference} placeholder="#12345" label="RPT Booking Ref" />,
                tripNameInput: <TextInput {...useTitle} placeholder="Description" label="Trip Name" />,
            })}
            middleSpace={<Space h={20} />}
            bottomBox={AdminCreateQuotePage.BottomBox({
                pickUpLocationSelect: (
                    <Select
                        label="Pick up location"
                        onMouseDown={e => setPickup('')}
                        placeholder="Select"
                        {...usePickup}
                        onChange={v => {
                            setPickup(v)
                            setDropoff(v)
                        }}
                        data={Spaced_States}
                    ></Select>
                ),
                dropOffLocationSelect: (
                    <Select
                        label="Drop off location"
                        onMouseDown={e => setDropoff('')}
                        placeholder="Select"
                        {...useDropoff}
                        data={Dropoff_States ?? []}
                    ></Select>
                ),
                pickUpDatePicker: <DatePicker
                    {...usePickupDate}
                    placeholder="Select"
                    label="Pick up date"
                    excludeDate={d => before()(AsIfParsedFromTimezone(d))}
                    onChange={d => {
                        setPickupDate(d)
                        if (!dropoff_date || (dropoff_date.getTime() < d.getTime())) setDropoffDate(d)
                    }}
                />,
                dropOffDatePicker: <DatePicker
                    {...useDropoffDate}
                    placeholder="Select"
                    label={DropOffDateLabel}
                    excludeDate={d => before(pickup_date ?? new Date())(AsIfParsedFromTimezone(d))}
                />,
                numberOfPeopleSelect: (
                    <Select
                        searchable={false}
                        label="Number of people"
                        placeholder="Select"
                        {...usePax}
                        data={[1, 2, 3, 4, 5, 6].map(n => {
                            return { value: `${n}`, label: n == 1 ? `1 Person` : `${n} People` }
                        })}
                    ></Select>
                ),
                filtersExpandCollapse: <Text>Filters</Text>, // <ExpandCollapse label="Filters" />,
                filtersArea: AdminCreateQuotePage.FiltersArea({
                    discountSelect: (
                        <Select
                            searchable={false}
                            {...useDiscount}
                            label="RPT % Discount"
                            placeholder="All discounts"
                            data={[2, 5, 10, 15, 20].map(n => {
                                return { value: `${n}`, label: `${n}%` }
                            })}
                        ></Select>
                    ),
                    offerEndsSelect: (
                        <Select
                            searchable={false}
                            {...useOfferEnds}
                            label="Offer Ends"
                            placeholder="All offers"
                            data={[24, 36, 48, 72, 120, 168].map(n => {
                                if (n > 48) return { value: `${n}`, label: `${n / 24} Days` }
                                return { value: `${n}`, label: `${n} Hours` }
                            })}
                        ></Select>
                    ),
                    operatorsCheckboxGroup: (
                        <InputWrapper label={<Text>Operators <Anchor onClick={toggleAllOperators} size="sm">{someOperatorsSelected ? 'Deselect all' : 'Select all'}</Anchor></Text>}>
                            {renderOperators(({ value, ...rest }) => <Checkbox checked={value??false} {...rest} />)}
                        </InputWrapper>
                    ),
                    specOptionsCheckboxGroup: (
                        <InputWrapper label="Spec Options">
                            {renderOptions(({ value, ...rest }) => <Checkbox checked={value??false} {...rest} />)}
                        </InputWrapper>
                    ),
                    camperTypeCheckboxGroup: (
                        <InputWrapper label="Camper Type">
                            {renderTypes(({ value, ...rest }) => <Checkbox checked={value??false} {...rest} />)}
                        </InputWrapper>
                    ),
                }),
            })}
            searchArea={
                AdminCreateQuotePage.SearchArea({
                    searchButton: 
                        <Group style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Group dir="row" style={{ width: '100%', justifyContent: 'space-between' }}>
                            <Text> </Text>
                            <Group style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Button onClick={submit}>{SearchButtonText}</Button>
                                {!!createdFeedback?.key &&
                                    <Group style={{
                                        backgroundColor: 'black',
                                        padding: '10px',
                                        margin: '5px',
                                        zIndex: 10,
                                    }}>
                                        <Text
                                            backgroundColor="white"
                                            color="white"
                                            style={{
                                                textDecoration: 'underline'
                                            }}
                                            onClick={() => window.open(createdFeedback?.url, '_blank')}
                                        >Created Quote {createdFeedback?.key} (Ref#{createdFeedback?.ref})</Text>
                                        <br/>
                                        <Text
                                            backgroundColor="white"
                                            color="white"
                                            style={{
                                                textDecoration: 'underline'
                                            }}
                                            onClick={() => window.open(`/q/${createdFeedback?.key}`, '_blank')}
                                        >Internal link</Text>
                                    </Group>
                                }
                                {!!error &&
                                    <Group style={{
                                        backgroundColor: 'black',
                                        padding: '10px',
                                        margin: '5px',
                                    }}>
                                        <Text backgroundColor="white" color="red">{error}</Text>
                                    </Group>
                                }
                            </Group>
                            <Text><Anchor onClick={resetFields} size="sm">Reset All Fields</Anchor></Text>
                        </Group>
                        <Select
                            searchable={false}
                            label="Search mode"
                            placeholder="Select"
                            {...useMode}
                            data={['async','sync','sync_allow_empty'].map(n => {
                                return { value: `${n}`, label: n.replace(/_/mgi, ' ') }
                            })}
                        ></Select>
                    </Group>,
                })
            }
        />
    </>} />)
}