import React from 'react'
import { createStyles } from '@mantine/core'
import { PageMaxWidthContainer } from '../PageMaxWidthContainer/PageMaxWidthContainer'
import { WithExtras } from '../../util/ComponentUtil'

const useStyles = createStyles((theme, _params) => {
  return {
    component: {
      display: 'flex',
      width: '100%',
      height: 76,
      alignItems: 'center',
      backgroundColor: theme.white,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.07)',
    },

    innerContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },

    leftContent: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexGrow: 1,
    },

    rightContent: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexGrow: 1,
    },
  }
})

export type HeaderProps = WithExtras<
  {
    leftContent: React.ReactNode
    rightContent: React.ReactNode
  },
  {
    component: 'div'
    pageMaxWidthContainer: typeof PageMaxWidthContainer
    innerContainer: 'div'
    leftContent: 'div'
    rightContent: 'div'
  }
>

export function Header({ leftContent, rightContent, extras }: HeaderProps) {
  const { classes } = useStyles()

  return (
    <div className={classes.component} {...extras?.component}>
      <PageMaxWidthContainer {...extras?.pageMaxWidthContainer}>
        <div className={classes.innerContainer} {...extras?.innerContainer}>
          <div className={classes.leftContent} {...extras?.leftContent}>
            {leftContent}
          </div>
          <div className={classes.rightContent} {...extras?.rightContent}>
            {rightContent}
          </div>
        </div>
      </PageMaxWidthContainer>
    </div>
  )
}
