import { useEffect, useState } from 'react';

// This timezone consistently represents (even under DST) UTC+11 (see https://en.wikipedia.org/wiki/List_of_tz_database_time_zones)
// Since times are input on agent machines (usually in AEDT), they may be +10 or +11 in DST.
// Only the DATE, not the TIME is needed, so the following methods are used to maintain consistency:
// - Input dates have their time set to 12:00 so that drift in either direction is unlikely to change the date.
// - Dates are interpreted as being in TIMEZONE so as to accurately indicate the current date in the target areas (AWST to NZDT, approximated as AEDT DST aka UTC+11 aka GMT-11).
export const TIMEZONE = `Etc/GMT-11`


export function niceDate(now: Date = new Date()) {
    if (typeof now == 'string') now = new Date(now)
    try {
        const [M, D, Y] = new Intl.DateTimeFormat("en-US", {
            // weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: TIMEZONE,
        }).format(now).replace(',', '').split(' ')
        // console.log({ now, s: now.getUTCDate(), D, M })
        return [D, M, Y].join(' ')
    } catch (date_error) {
        console.error({ date_error })
        return now.toISOString()
    }
}

// export const AEDT = (d?: string) => {
//     const offset = 11 // AEDT is UTC+11
//     const arg = [d].filter(Boolean)
//     const D = new Date(...arg)
//     const t = D.getTime() + (D.getTimezoneOffset() * 60000)
//     return new Date(t + offset * 3600e3)
// }

// Treat a time as if it had been parsed in a different timezone
// When in AEST, AsIfParsedFromTimezone(new Date('2020-02-02T20:00'), AEDT=11) is like saying "If someone in AEDT constructed a date with the same string, what UTC date object would they get?"
// In this case it is the same as subtracting one hour, but this works with any two timezones
// AsIfParsedFromTimezone(new Date()) also works, but it is like saying "If I gave someone in AEDT my current time string, what would they construct from it?"
export const AsIfParsedFromTimezone = (d: Date, offset: number = 11) => {// AEDT is UTC+11
    const t = d.getTime() + (((offset * -60) - d.getTimezoneOffset()) * 60000)
    const D = new Date(t)
    console.log({ d, offset, D })
    return D
}

// const tz = (d, offset= 11) => {// AEDT is UTC+11
//     const t = d.getTime() + ((offset*-60)-d.getTimezoneOffset() * 60000)
//     return new Date(t)
// }
export const DDMMYYYY = (d: string | Date) => new Date(d).toLocaleDateString('en-GB', { timeZone: TIMEZONE })
export const HHMMSS = (d: string | Date) => new Date(d).toLocaleTimeString('en-GB', { timeZone: TIMEZONE })

export function timeDifferenceSignificantParts(future: Date, parts = 2, ifLess = undefined, ifPast = undefined) {
    const delta = timeDifferenceFuture(future, new Date(), null)
    if (delta === null) return ifPast ?? ''
    const split = delta.split(' ')
    if (ifLess !== undefined && split.length < parts) return ifLess;
    return split.slice(0, parts).join(' ')
}

export function timeDifferenceFuture(future: Date, current: Date = new Date(), ifPast = undefined) {
    // TODO generalize and combine with timeDifference for past relative time

    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    // const msPerYear = msPerDay * 365;

    const delta = future.getTime() - current.getTime();

    // const Y = ~~(delta / msPerYear)
    // const M = ~~((delta % msPerYear) / msPerMonth)
    const d = ~~((delta % msPerMonth) / msPerDay)
    const h = ~~((delta % msPerDay) / msPerHour)
    const m = ~~((delta % msPerHour) / msPerMinute)
    const s = ~~((delta % msPerMinute) / 1000)

    if (ifPast !== undefined && delta < 0) return ifPast;

    return Object.entries({ d, h, m, s }).filter(([k, v], i, a) => v || a.slice(0, i).some(([k, v]) => v)).map(([k, v]) => `${v}${k}`).join(' ')
}

export function useTime(ms = 1000) {
    const [time, setTime] = useState(new Date())
    useEffect(() => {
        const timer = setTimeout(() => {
            setTime(new Date());
        }, ms);

        return () => clearTimeout(timer);
    });
    return time
}