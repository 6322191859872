import { DatePicker } from '@mantine/dates';
import { useEffect, useState } from 'react';
import { MustLogIn } from '../session';
import { AdminPageLayout } from 'ratpack-ui/src/pages/AdminPageLayout';
import { adminDefaults } from './components';
import { Group, Loader, Modal, Table } from '@mantine/core';
import { DAY } from 'ratpack-server/src/types';
import { Anchor } from 'ratpack-ui/src/components/Anchor/Anchor';

export default () => {
    const [error,setError] = useState('')
    const setErrorWithContext = (context:String) => (e:any) => setError(`${context}: ${e}`)
    
    const [date, setDate] = useState<Date | null>(new Date(Date.now()+(10*DAY)));
    const [loadingAvailability, setLoadingAvailability] = useState<Boolean>(false);
    const [availability, setAvailability] = useState({});
    useEffect(()=>{
        setLoadingAvailability(true)
        fetch(`/api/availability?pickup=${date.getTime()}`)
        .then(res => res.json())
        .then((data) => {
            console.log({data})
            setAvailability(data)
            setLoadingAvailability(false)
        })
        .catch(setErrorWithContext('Failed to fetch availability data from RP server'))
    }, [date])
    
    const [selectedCombo, setSelectedCombo] = useState<[string,string]|null>(null);

    const dateString = (date:Date) => date.toISOString().split('T')[0]
    //

    const data = Object.entries(availability??{})
    const locations = Object.keys(data.reduce((t,[k,v])=>{
        return {...t, [k.split('@')[1]]:1}
    },{}))
    const providers = Object.keys(data.reduce((t,[k,v])=>{
        return {...t, [k.split('@')[0]]:1}
    },{}))

    console.log({locations,providers,availability})

    const selection = (provider=(selectedCombo??[])[0] ?? providers[0], location=(selectedCombo??[])[1] ?? locations[0]) => (availability[`${provider}@${location}`]??[])

    return <AdminPageLayout {...adminDefaults({ page: 'availability' })} page={<>
        <MustLogIn />
        <div style={{
            margin:10,
        }}>
            <Group
                style={{
                    justifyContent: 'space-between',
                }}
            >
                Display availability for known products in all regions for a 15-days trip from the selected date.
            </Group>
        </div>
            <Group dir='col' style={{flexDirection:'column'}}>
            {loadingAvailability ? <div
                    style={{
                        margin:'30px',
                        justifyContent: 'center',
                        display: 'grid',
                    }}
                >
                    <Group dir='col'><Loader/>Fetching latest data from all provider APIs...</Group>
                </div> : <DatePicker value={date} onChange={setDate} />
            }
            {error && <div style={{
                margin:10,
                color: 'red'
            }}>{JSON.stringify(error)}</div>}

            <hr/>
            <h1>Availability for {dateString(date)}</h1>
            <Table style={{margin:20, width:'90%'}}>
                <thead><tr>{['Provider',...locations].map((l,i) => (<th key={i}>{l}</th>))}</tr></thead>
                <tbody>{providers.map((provider,i) => {
                    return (<tr key={i}>
                        {[provider, ...locations.map(location => {
                            const noChance = provider.split('.')[0] != location.split('.')[0]
                            const num = selection(provider,location).length
                            const red = 'rgba(255,50,50,0.5)'
                            const green = 'rgba(50,255,50,0.5)'
                            const gray = 'rgba(100,100,100,0.5)'
                            return (<td style={{backgroundColor: noChance ? gray : (num ? green : red)}}>
                                <Anchor href="#" onClick={e=>setSelectedCombo([provider,location])}>
                                    {num}
                                </Anchor>
                            </td>)
                        })]}
                    </tr>)
                })}</tbody>
            </Table>

            <Modal
                size="75%"
                opened={!!selectedCombo}
                onClose={()=>setSelectedCombo(null)}
            >
                {selectedCombo && (<p>
                    {selection().length} options from {selectedCombo[0]} at {selectedCombo[1]} on
                    <span style={{whiteSpace: 'nowrap'}}> {dateString(date)}</span> (15 days):
                    <hr/>
                    <ul>
                        {selection().map((opt,i) => (<li key={i}>{opt.name} ({opt.id}) for ${(opt.daily/100).toFixed(2)}/day</li>))}
                    </ul>
                </p>)}
            </Modal>

        </Group>
    </>}/>
}

