const credentials: RequestCredentials = 'include'
const mode: RequestMode = 'cors'

/*
//  ██████╗ ███████╗███╗   ██╗███████╗██████╗ ██╗ ██████╗
// ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██║██╔════╝
// ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝██║██║
// ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██║██║
// ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║╚██████╗
//  ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝ ╚═════╝
*/

const catcher = (ctx: string) => (error) => {
    console.error({ ctx, error })
    return null
}

/*
//  █████╗ ██████╗ ██╗
// ██╔══██╗██╔══██╗██║
// ███████║██████╔╝██║
// ██╔══██║██╔═══╝ ██║
// ██║  ██║██║     ██║
// ╚═╝  ╚═╝╚═╝     ╚═╝
*/

export const headers = {
    "content-type": "application/json",
    "accept": "application/json",
    "accept-language": "en-US,en;q=0.9",
    // "cache-control": "max-age=0",
    "sec-fetch-dest": "empty",
    "sec-fetch-mode": "cors",
    "sec-fetch-site": "same-origin",
    "sec-gpc": "1"
}
export const defaults = {
    headers,
    "referrerPolicy": "strict-origin-when-cross-origin" as ReferrerPolicy,
    "body": null,
    "method": "GET",
    mode,
    credentials,
}

const isLocal = false // window.location.hostname.includes('local') // USE PROXYRC
export const API_HOST = isLocal ? `//${window.location.hostname}:8185` : ""
export const GET = (path) => {
    return fetch(`${API_HOST}${path}`, defaults).then(res => res.json()).catch(catcher(`GET:${path}`))
}
export const POST = (path = "/api/signup", body = {}) => {
    console.log({ body })
    return fetch(`${API_HOST}${path}`, {
        ...defaults,
        body: JSON.stringify(body),
        "method": "POST",
    }).then(res => res.json()).catch(catcher(`POST:${path}`))
}

export type req_quote = {}
export type res_get_me = {
    id: string,
    email: string,
    avatar: string,
}

export const get_me = (): Promise<res_get_me> => GET(`/api/me`)
export const post_login = ({ username = "", password = "" }) => POST(`/api/signin`, { username, password })
export const get_quotes = () => GET(`/api/quotes`)
export const post_new_quote = () => POST(`/api/quote`)
export const post_quote = (id: string, body: req_quote) => POST(`/api/quote/${id}`, body)
export const get_quote = (key: string) => GET(`/api/quote/${key}`)

export const logout = () => GET("/api/logout").then(v => { console.log(v); return v })
