import { MantineThemeOverride } from '@mantine/core'

const breakpoints = {
  xl: 1400,
  lg: 1200,
  md: 992,
  sm: 768,
  xs: 576,
}

export const theme: MantineThemeOverride = {
  fontFamily: '"Quiet Sans", sans-serif',
  breakpoints,
  // Use https://maketintsandshades.com/
  colors: {
    brandWhite: [
      '#ffffff',
      '#ffffff',
      '#ffffff',
      '#ffffff',
      '#ffffff',
      '#ffffff',
      '#ffffff',
      '#e6e6e6',
      '#cccccc',
      '#b3b3b3',
    ],
    brandGray: [
      '#f9f9f9',
      '#f8f8f8',
      '#f7f7f7',
      '#f6f6f6',
      '#f5f5f5',
      '#dddddd',
      '#c4c4c4',
      '#acacac',
      '#939393',
      '#7b7b7b',
    ],
    brandDarkGray: [
      '#a9a9a9',
      '#9b9a9a',
      '#8d8c8c',
      '#7e7d7d',
      '#706f6f',
      '#656464',
      '#5a5959',
      '#4e4e4e',
      '#434343',
      '#383838',
    ],
    brandBlack: [
      '#a7a7a7',
      '#929292',
      '#7c7c7c',
      '#666666',
      '#505050',
      '#3a3a3a',
      '#242424',
      '#202020',
      '#1d1d1d',
      '#191919',
    ],
    brandGreen: [
      '#a8edc2',
      '#92e9b3',
      '#7ce5a3',
      '#66e094',
      '#51dc85',
      '#3bd775',
      '#25d366',
      '#21be5c',
      '#1ea952',
      '#1a9447',
    ],
    brandFacebook: [
      '#b1bcd6',
      '#9eaccc',
      '#8a9bc1',
      '#778ab7',
      '#6379ad',
      '#5069a2',
      '#3c5898',
      '#364f89',
      '#30467a',
      '#2a3e6a',
    ],
  },
}
