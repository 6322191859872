import React from 'react'
import { createStyles } from '@mantine/core'

import { WithChildren } from '../util/ComponentUtil'

type LayoutStyleProps = {
  styleType: 'gray' | 'white'
}

const useStyles = createStyles((theme, { styleType }: LayoutStyleProps) => {
  return {
    component: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      ...(styleType === 'gray'
        ? { backgroundColor: theme.colors.brandGray[4] }
        : {}),
      ...(styleType === 'white' ? { backgroundColor: theme.white } : {}),
    },
  }
})

export type LayoutProps = React.ComponentPropsWithRef<'div'> &
  WithChildren<{
    styleType?: 'gray' | 'white'
    header: React.ReactNode
    footer: React.ReactNode
  }>

export function Layout({
  styleType = 'gray',
  header,
  footer,
  children,
  ...props
}: LayoutProps) {
  const { classes } = useStyles({ styleType })

  return (
    <div className={classes.component} {...props}>
      {header}
      {children}
      {footer}
    </div>
  )
}
