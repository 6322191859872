import React from 'react'
import { Image, Group, Space, Avatar } from '@mantine/core'
import { Link, Outlet } from 'react-router-dom'

import { Layout } from '../layouts/Layout'
import { Header } from '../components/Header/Header'
import { Footer } from '../components/Footer/Footer'
import { Text } from '../components/Text/Text'
import { Anchor } from '../components/Anchor/Anchor'

import iconBus from '../assets/images/icon-bus.svg'
import avatar1 from '../assets/images/avatar-1.png'

export function AdminPageLayout({
  header = AdminPageLayout.Header({}),
  footer = AdminPageLayout.Footer({}),
  page = <Outlet />,
}: {
  header?: React.ReactNode
  footer?: React.ReactNode
  page?: React.ReactNode
}) {
  return (
    <Layout header={header} footer={footer}>
      {page}
    </Layout>
  )
}

/*

██╗  ██╗███████╗ █████╗ ██████╗ ███████╗██████╗ 
██║  ██║██╔════╝██╔══██╗██╔══██╗██╔════╝██╔══██╗
███████║█████╗  ███████║██║  ██║█████╗  ██████╔╝
██╔══██║██╔══╝  ██╔══██║██║  ██║██╔══╝  ██╔══██╗
██║  ██║███████╗██║  ██║██████╔╝███████╗██║  ██║
╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚═╝  ╚═╝                                              

*/

AdminPageLayout.Header = function ({
  leftContent = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Image src={iconBus} height={40} style={{ objectFit: 'contain' }} />
      <Space w={40} />
      <div style={{ display: 'flex' }}>
        <Anchor styleType="black" to="/admin/quotes">
          Search Results
        </Anchor>
        <Space w={24} />
        <Anchor
          styleType="black"
          to="/admin/create-quote"
          style={{ fontWeight: 600 }}
        >
          Search
        </Anchor>
      </div>
    </div>
  ),
  rightContent = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar src={avatar1} size={56} radius={999} />
      <Space w={20} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text>Mikey Jones</Text>
        <Anchor component={Link} styleType="gray" to="/login">
          Log out
        </Anchor>
      </div>
    </div>
  ),
}: {
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
}) {
  return <Header leftContent={leftContent} rightContent={rightContent} />
}

/*

███████╗ ██████╗  ██████╗ ████████╗███████╗██████╗ 
██╔════╝██╔═══██╗██╔═══██╗╚══██╔══╝██╔════╝██╔══██╗
█████╗  ██║   ██║██║   ██║   ██║   █████╗  ██████╔╝
██╔══╝  ██║   ██║██║   ██║   ██║   ██╔══╝  ██╔══██╗
██║     ╚██████╔╝╚██████╔╝   ██║   ███████╗██║  ██║
╚═╝      ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝╚═╝  ╚═╝                                                 

*/

AdminPageLayout.Footer = function ({
  topContent = null,
  spacer = null,
  bottomContent = AdminPageLayout.FooterBottomContentWrapper({}),
}: {
  topContent?: React.ReactNode
  spacer?: React.ReactNode
  bottomContent?: React.ReactNode
}) {
  return (
    <Footer>
      {topContent}
      {spacer}
      {bottomContent}
    </Footer>
  )
}

AdminPageLayout.FooterBottomContentWrapper = function ({
  content = <Text style={{
    bottom: '10px',
    position: 'fixed',
    textShadow: '0px 0px 3px white',
    backgroundColor: 'rgba(255,255,255,0.5)',
    padding: 5,
    borderRadius: 5,
    fontSize: 12,
    userSelect: 'none',
  }}>Changing UP the Travel Game</Text>,
}: {
  content?: React.ReactNode
}) {
  return (
    <Group direction="column" align="center" style={{padding:0}}>
      {content}
    </Group>
  )
}
