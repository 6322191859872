import React from 'react'
import { createStyles } from '@mantine/core'
import { WithExtras } from '../../util/ComponentUtil'

const useStyles = createStyles((theme, _params) => {
  return {
    component: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      border: `1px solid ${theme.colors.dark[6]}`,
    },

    top: {
      padding: '5px 0 5px 0',
      textAlign: 'center',
      fontSize: '17px',
      lineHeight: '20px',
      fontWeight: 600,
      backgroundColor: theme.white,
    },

    bottom: {
      padding: '5px 0 5px 0',
      textAlign: 'center',
      fontSize: '17px',
      lineHeight: '20px',
      fontWeight: 600,
      backgroundColor: theme.colors.dark[6],
      color: theme.white,
    },

    bottomLabel: {
      fontWeight: 700,
    },

    bottomText: {},
  }
})

export type BlackWhiteInfoBoxProps = WithExtras<
  {
    topText?: React.ReactNode
    bottomLabel?: React.ReactNode
    bottomText?: React.ReactNode
  },
  {
    component: 'div'
    top: 'div'
    bottom: 'div'
    bottomLabel: 'span'
    bottomText: 'span'
  }
>

export function BlackWhiteInfoBox({
  topText,
  bottomLabel,
  bottomText,
  extras,
}: BlackWhiteInfoBoxProps) {
  const { classes } = useStyles()

  return (
    <div className={classes.component} {...extras?.component}>
      <div className={classes.top} {...extras?.top}>
        {topText}
      </div>
      <div className={classes.bottom} {...extras?.bottom}>
        <span className={classes.bottomLabel} {...extras?.bottomLabel}>
          {bottomLabel}&nbsp;
        </span>
        <span className={classes.bottomText} {...extras?.bottomText}>
          {bottomText}
        </span>
      </div>
    </div>
  )
}
