import React, { useEffect, useState } from 'react'
import { LoadingOverlay } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { selector, useRecoilValue } from "recoil"
import { get_me, res_get_me } from './api'

export const MustLogIn = ({ to = '/login', delay = 1000, children = [] as any, id = null, replace = true }) => {
    const user = useRecoilValue(userAtom)
    const navigate = useNavigate()
    if (!user?.id || (id && user?.id != id)) {
        console.log(`Navigating to ${to}`)
        isNaN(delay) ? navigate(to, { replace }) : setTimeout(() => navigate(to, { replace }), delay)
        return <LoadingOverlay visible={true} />
    }
    return <>{children ?? null}</>
}

export const MustNotLogIn = ({ to = '/', delay = 1000, children = [] as any, replace = true }) => {
    const user = useRecoilValue(userAtom)
    const navigate = useNavigate()
    const go = () => (!isNaN(delay)) ? navigate(to, { replace }) : setTimeout(() => navigate(to, { replace }), delay)
    if (user?.id) {
        console.log(`Navigating to ${to}`)
        isNaN(delay) ? navigate(to, { replace }) : setTimeout(() => navigate(to, { replace }), delay)
        return <LoadingOverlay visible={true} />
    }
    return <>{children ?? null}</>
}

export const validates = (value, f = Boolean, badProps = {}, goodProps = {}) => {
    if (f(value)) return goodProps
    return badProps
}
export const isEmail = (s = '') => !s.includes(' ') && s.split('@').filter(Boolean).length == 2
// export const unless = (v=false, ifFalse={}, ifTrue={}) => v ? ifTrue : ifFalse
export const invalid = { invalid: true }

export const userAtom = selector<res_get_me>({
    key: 'user',
    get: ({ get }) => get_me(),
    // set: ({ get, set, reset}) => 
})

export function useHash() {
    const getHash = () => {
        const base = window.location.hash.split('#', 2)
        const slash = (base[1] ?? base[0]).split('/')
        return (slash[1] ? slash.slice(1).join('/') : slash.join('/'))
    }
    const [hash, setHash] = useState(getHash())
    useEffect(() => {
        const handleNewHash = () => setHash(getHash())
        window.addEventListener('hashchange', handleNewHash, false);
        return () => window.removeEventListener('hashchange', handleNewHash)
    })
    return hash
}
export function will(f: Function, ...arg: any[]) {
    return () => f(...arg)
}

export function useGo() {
    return useNavigate(); // useGo()("../success", { replace: true });
}
