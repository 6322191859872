import { MantineProvider } from '@mantine/core'
import { GlobalStyles } from '../styles/GlobalStyles.tsx'
import { theme } from '../styles/Theme.ts'
import { WithChildren } from '../util/ComponentUtil.ts'

export type UIProviderProps = WithChildren<{}>

export const UIProvider = function ({ children }: UIProviderProps) {
  return (
    <MantineProvider withNormalizeCSS withGlobalStyles theme={theme}>
      <GlobalStyles />
      {children}
    </MantineProvider>
  )
}
