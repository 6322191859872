import React from 'react'
import { Space } from '@mantine/core'
import { useNavigate } from 'react-router-dom'

import { ScreenCenterContainer } from '../components/ScreenCenterContainer/ScreenCenterContainer'

import iconBus from '../assets/images/icon-bus.svg'

export function LoginLoadingPage({
  imageContainer = LoginLoadingPage.ImageContainer({}),
  spacer = <Space h={30} />,
  text = LoginLoadingPage.TextContainer({}),
  loader = LoginLoadingPage.Loader(),
}: {
  imageContainer?: React.ReactNode
  spacer?: React.ReactNode
  text?: React.ReactNode
  loader?: React.ReactNode
}) {
  return (
    <ScreenCenterContainer>
      {imageContainer}
      {spacer}
      {text}
      {loader}
    </ScreenCenterContainer>
  )
}

/*

██╗      ██████╗  █████╗ ██████╗ ███████╗██████╗ 
██║     ██╔═══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗
██║     ██║   ██║███████║██║  ██║█████╗  ██████╔╝
██║     ██║   ██║██╔══██║██║  ██║██╔══╝  ██╔══██╗
███████╗╚██████╔╝██║  ██║██████╔╝███████╗██║  ██║
╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚══════╝╚═╝  ╚═╝
                                                                                                                                                                                                                                                            
*/

LoginLoadingPage.Loader = function () {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    setTimeout(() => {
      navigate('/admin/create-quote')
    }, 2000)
  }, [navigate])

  return null
}

/*

██╗███╗   ███╗ █████╗  ██████╗ ███████╗     ██████╗ ██████╗ ███╗   ██╗████████╗ █████╗ ██╗███╗   ██╗███████╗██████╗ 
██║████╗ ████║██╔══██╗██╔════╝ ██╔════╝    ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔══██╗██║████╗  ██║██╔════╝██╔══██╗
██║██╔████╔██║███████║██║  ███╗█████╗      ██║     ██║   ██║██╔██╗ ██║   ██║   ███████║██║██╔██╗ ██║█████╗  ██████╔╝
██║██║╚██╔╝██║██╔══██║██║   ██║██╔══╝      ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══██║██║██║╚██╗██║██╔══╝  ██╔══██╗
██║██║ ╚═╝ ██║██║  ██║╚██████╔╝███████╗    ╚██████╗╚██████╔╝██║ ╚████║   ██║   ██║  ██║██║██║ ╚████║███████╗██║  ██║
╚═╝╚═╝     ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝     ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝
                                                                                                                                                                                                              
*/

LoginLoadingPage.ImageContainer = function ({
  image = <img src={iconBus} alt="Ratpack Travel" style={{ maxWidth: 74 }} />,
}: {
  image?: React.ReactNode
}) {
  return <div className="animation-shake">{image}</div>
}

/*

████████╗███████╗██╗  ██╗████████╗     ██████╗ ██████╗ ███╗   ██╗████████╗ █████╗ ██╗███╗   ██╗███████╗██████╗ 
╚══██╔══╝██╔════╝╚██╗██╔╝╚══██╔══╝    ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔══██╗██║████╗  ██║██╔════╝██╔══██╗
   ██║   █████╗   ╚███╔╝    ██║       ██║     ██║   ██║██╔██╗ ██║   ██║   ███████║██║██╔██╗ ██║█████╗  ██████╔╝
   ██║   ██╔══╝   ██╔██╗    ██║       ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══██║██║██║╚██╗██║██╔══╝  ██╔══██╗
   ██║   ███████╗██╔╝ ██╗   ██║       ╚██████╗╚██████╔╝██║ ╚████║   ██║   ██║  ██║██║██║ ╚████║███████╗██║  ██║
   ╚═╝   ╚══════╝╚═╝  ╚═╝   ╚═╝        ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝
                                                                                                               
*/

LoginLoadingPage.TextContainer = function ({
  text = 'Hold tight',
}: {
  text?: React.ReactNode
}) {
  return <div className="animation-ellipsis">{text}</div>
}
