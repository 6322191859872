import React from 'react'
import { Image, Group, Space } from '@mantine/core'
import { Outlet } from 'react-router-dom'

import { Layout } from '../layouts/Layout'
import { Header } from '../components/Header/Header'
import { Footer } from '../components/Footer/Footer'
import { Anchor } from '../components/Anchor/Anchor'
import { Text } from '../components/Text/Text'

import logoRatpackTravel from '../assets/images/logo-ratpack-travel.svg'
import iconFbMessenger from '../assets/images/icon-fb-messenger.svg'
import iconWhatsApp from '../assets/images/icon-whatsapp.svg'

export function PublicPageLayout({
  header = PublicPageLayout.Header({}),
  footer = PublicPageLayout.Footer({}),
  page = <Outlet />,
}: {
  header?: React.ReactNode
  footer?: React.ReactNode
  page?: React.ReactNode
}) {
  return (
    <Layout header={header} footer={footer}>
      {page}
    </Layout>
  )
}

/*

██╗  ██╗███████╗ █████╗ ██████╗ ███████╗██████╗ 
██║  ██║██╔════╝██╔══██╗██╔══██╗██╔════╝██╔══██╗
███████║█████╗  ███████║██║  ██║█████╗  ██████╔╝
██╔══██║██╔══╝  ██╔══██║██║  ██║██╔══╝  ██╔══██╗
██║  ██║███████╗██║  ██║██████╔╝███████╗██║  ██║
╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚═╝  ╚═╝                                              

*/

PublicPageLayout.Header = function ({
  leftContent = <Image src={logoRatpackTravel} />,
  rightContent = (
    <Group>
      <a href="https://www.messenger.com/" target="_blank" rel="noreferrer">
        <Image src={iconFbMessenger} style={{ fill: '#000000' }} />
      </a>
      <a href="https://www.whatsapp.com/" target="_blank" rel="noreferrer">
        <Image src={iconWhatsApp} />
      </a>
    </Group>
  ),
}: {
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
}) {
  return <Header leftContent={leftContent} rightContent={rightContent} />
}

/*

███████╗ ██████╗  ██████╗ ████████╗███████╗██████╗ 
██╔════╝██╔═══██╗██╔═══██╗╚══██╔══╝██╔════╝██╔══██╗
█████╗  ██║   ██║██║   ██║   ██║   █████╗  ██████╔╝
██╔══╝  ██║   ██║██║   ██║   ██║   ██╔══╝  ██╔══██╗
██║     ╚██████╔╝╚██████╔╝   ██║   ███████╗██║  ██║
╚═╝      ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝╚═╝  ╚═╝                                                 

*/

PublicPageLayout.Footer = function ({
  topContent = PublicPageLayout.FooterTopContentWrapper({}),
  spacer = <Space h="md" />,
  bottomContent = PublicPageLayout.FooterBottomContentWrapper({}),
}: {
  topContent?: React.ReactNode
  spacer?: React.ReactNode
  bottomContent?: React.ReactNode
}) {
  return (
    <Footer>
      {topContent}
      {spacer}
      {bottomContent}
    </Footer>
  )
}

PublicPageLayout.FooterTopContentWrapper = function ({
  content = (
    <>
      <Anchor styleType="light" align="center" href="tel:+61 490149348">
        +61 490149348
      </Anchor>
      <Anchor
        styleType="light"
        align="center"
        href="mailto:contactus@ratpacktravel.com"
      >
        contactus@ratpacktravel.com
      </Anchor>
    </>
  ),
}: {
  content?: React.ReactNode
}) {
  return (
    <Group direction="column" align="center" spacing={0}>
      {content}
    </Group>
  )
}

PublicPageLayout.FooterBottomContentWrapper = function ({
  content = <Text>Changing UP the Travel Game</Text>,
}: {
  content?: React.ReactNode
}) {
  return (
    <Group direction="column" align="center">
      {content}
    </Group>
  )
}
