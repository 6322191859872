import React from 'react'
import {
  Modal as MModal,
  ModalProps as MModalProps,
  createStyles,
} from '@mantine/core'

const useModalStyles = createStyles((theme, _params) => {
  return {
    basic: {
      width: 334,
      padding: 42,
    },
  }
})

const useCloseStyles = createStyles((theme, _params) => {
  return {
    basic: {
      width: 38,
      height: 38,
      position: 'absolute',
      top: 0,
      right: 0,
      backgroundColor: theme.colors.brandBlack[6],
      color: theme.white,
      borderRadius: 0,
      '&:hover, &:focus': {
        backgroundColor: theme.colors.brandBlack[5],
      },
      '&:not(:disabled):active': {
        transform: 'none',
      },
      [`& svg`]: {
        width: 32,
        height: 32,
      },
    },
  }
})

export type ModalProps = MModalProps & {
  styleType?: 'basic'
}

export function Modal({ styleType = 'basic', children, ...props }: ModalProps) {
  const { classes: modalClasses } = useModalStyles()
  const { classes: closeClasses } = useCloseStyles()

  return (
    <MModal
      classNames={{
        modal: modalClasses[styleType],
        close: closeClasses[styleType],
      }}
      centered
      {...props}
    >
      {children}
    </MModal>
  )
}
